<template>
  <v-container fluid class="pa-0">
    <v-row
      dense
      align="center"
      :class="belowSecondThresholdWidth ? 'pb-1' : ''"
    >
      <v-col :cols="belowSecondThresholdWidth ? '12': '6'">
        <v-list class="py-0">
          <v-list-item class="py-0">
            <v-list-item-avatar>
              <v-avatar
              >
                <v-img
                  :src="itemImage"
                  :alt="itemText"
                  contain
                ></v-img>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-wrap">
                {{ itemText }}
              </v-list-item-title>
              <v-list-item-subtitle v-if="!hideItemPrice || showAdditionalItemsPrice">
                {{ itemPrice }} {{ $t('per') }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col
        :cols="belowSecondThresholdWidth ? '12': '6'"
        :class="belowSecondThresholdWidth ? 'px-3' : ''"
      >
        <SimplifiedQuantitySelection
          :grow="belowSecondThresholdWidth"
          :no-cols-top-padding="belowSecondThresholdWidth"
          :selected-quantity="quantity"
          :disabled="disabled"
          :can-increment="canIncrement"
          :can-decrement="canDecrement"
          @decreaseQuantity="decreaseQuantity"
          @increaseQuantity="increaseQuantity"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="px-7 pt-0" v-html="description"></v-col>
    </v-row>
  </v-container>
</template>

<script>
import SimplifiedQuantitySelection from "@/components/common/SimplifiedQuantitySelection";
export default {
  name: "ParticipantItem",
  components: {
    SimplifiedQuantitySelection
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    selectedItems: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showAdditionalItemsPrice: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      thresholdWidth: 440,
      secondThresholdWidth: 390
    }
  },
  computed: {
    itemImage() {
			if (this.item && this.item.images && this.item.images.length) {
				return this.item.images[0].file.sources.large;
			} else {
				return "";
			}
		},
    description() {
			if (typeof this.item !== "undefined" && this.item !== null) {
				// Check if key exists in locale  and if so, return its value
				const key = "item:description:value:" + this.item.uuid;
				if (this.$te(key)) {
					return this.$t(key);
				}

				// If key does not exist we at least want to return the description value of the item
				const { description } = this.item;
				if (
					typeof description !== "undefined" &&
					description !== null &&
					typeof description.value !== "undefined" &&
					description.value !== null
				) {
					return description.value;
				}
			}

			return null;
		},
    belowWidthThreshold() {
      return this.$vuetify.breakpoint.width < this.thresholdWidth
    },
    belowSecondThresholdWidth() {
      return this.$vuetify.breakpoint.width < this.secondThresholdWidth
    },
    hideItemPrice() {
      return this.$store.state.hideItemPrice
    },
    canDecrement() {
      return this.quantity > 0
    },
    canIncrement() {
      if(typeof this.item !== 'undefined' && this.item !== null) {
        if(typeof this.item.maximumOrderQuantity !== 'undefined' && this.item.maximumOrderQuantity > 0) {
          return this.quantity < this.item.maximumOrderQuantity
        }
      }
      return true
    },
    itemText() {
      if(typeof this.item !== 'undefined' && this.item !== null) {
        const translationExists = this.$te('item:title:' + this.item.uuid)
        if(translationExists) {
          return this.$t('item:title:' + this.item.uuid)
        }
        return this.item.title
      }
      return ''
    },
    itemPrice() {
      if(typeof this.item !== 'undefined' && this.item !== null) {
        if(typeof this.item.sale_price !== 'undefined' && this.item.sale_price !== null && typeof this.item.sale_price.amount !== 'undefined' && this.item.sale_price.amount > 0) {
          return this.$store.getters.getItemCurrencyDisplay(this.item.sale_price)
        }
        if(typeof this.item.price !== 'undefined' && this.item.price !== null) {
          return this.$store.getters.getItemCurrencyDisplay(this.item.price)
        }
      }
      return null
    },
    quantity() {
      if(Array.isArray(this.selectedItems) && this.selectedItems.length > 0 && typeof this.item !== 'undefined' && this.item !== null) {
        const index = this.selectedItems.findIndex(i => i && i.uuid === this.item.uuid)
        if(index >= 0) {
          return this.selectedItems[index].quantity
        }
      }
      return 0
    }
  },
  methods: {
    decreaseQuantity() {
      this.$emit('decreaseQuantity', this.item)
    },
    increaseQuantity() {
      this.$emit('increaseQuantity', this.item)
    }
  }
}
</script>

<style scoped>

</style>
