<template>
    <v-card two-line>
      <v-card-title>{{ item.title }}</v-card-title>
      <v-card-text v-html="item.description.value"></v-card-text>
      <v-card-actions>
        <template v-for="slot in slots">
            <v-btn :key="slot.key">{{ slot.label }}</v-btn>
        </template>
      </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: 'BookingFlowItem',
    props: ['item', 'slots']
}
</script>