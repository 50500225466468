<template>
  <v-container
    fluid
    :class="containerPadding"
  >
    <v-row
      align="center"
      justify="end"
      :dense="dense"
    >
      <v-col
        cols="auto"
        :class="noColsTopPadding ? 'pt-0' : ''"
      >
        <v-btn
          fab
          small
          tile
          depressed
          :disabled="disabled || !canDecrement"
          @click="decreaseQuantity"
        >
          <v-icon>
            mdi-minus
          </v-icon>
        </v-btn>
      </v-col>
      <v-col
        cols="auto"
        class="text-center"
        :class="(grow ? 'grow' : '') + ((grow && noColsTopPadding) ? ' ' : '') + (noColsTopPadding ? 'pt-0' : '')"
      >
        <span style="font-weight: 500; font-size: 20px;">
          {{ selectedQuantity }}
        </span>
      </v-col>
      <v-col
        cols="auto"
        :class="noColsTopPadding ? 'pt-0' : ''"
      >
        <v-btn
          fab
          small
          tile
          depressed
          :disabled="disabled || !canIncrement"
          @click="increaseQuantity"
        >
          <v-icon>
            mdi-plus
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "SimplifiedQuantitySelection",
  props: {
    selectedQuantity: {
      type: Number,
      default: 0
    },
    canIncrement: {
      type: Boolean,
      default: true
    },
    canDecrement: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    noPadding: {
      type: Boolean,
      default: false
    },
    grow: {
      type: Boolean,
      default: false
    },
    noPaddingY: {
      type: Boolean,
      default: false
    },
    noColsTopPadding: {
      type: Boolean,
      default: false
    },
    noColsPaddingY: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    containerPadding() {
      if(this.noPadding) {
        return 'pa-0'
      }
      if(this.noPaddingY) {
        return 'py-0'
      }
      return ''
    },
    classString() {
      const classes = []
      if(this.grow) {
        classes.push('grow')
      }
      if(this.noColsTopPadding) {
        classes.push('pt-0')
      }
      if(this.noColsPaddingY) {
        classes.push('py-0')
      }

      if(classes.length <= 0) {
        return ''
      }

      return classes.join(' ')
    }
  },
  methods: {
    decreaseQuantity() {
      this.$emit('decreaseQuantity')
    },
    increaseQuantity() {
      this.$emit('increaseQuantity')
    }
  }
}
</script>

<style scoped>

</style>
