<template>
  <v-card v-if="!Array.isArray(activities) || activities.length > 0">
    <v-container fluid>
      <v-row>
        <v-col
          cols="12"
          style="font-weight: 300;"
        >
          <span v-if="selectedDate">
            {{ $t('activitiesSelectedDate') }}
            <span style="font-weight: 700">
              {{ readableDate }}
            </span>
            <v-icon
              x-small
              right
              @click="deselectDate"
            >
              mdi-close
            </v-icon>
          </span>
          <span v-else>
            {{ $t('activitiesHotSpring') }}
          </span>
        </v-col>
        <v-col
          v-if="!Array.isArray(activities) || activities.length < 1"
          cols="12"
          class="grey lighten-3 text-center"
          style="font-weight: 300;"
        >
          {{ $t('noActivitiesAvailable') }}
        </v-col>
        <v-col
          v-else
          cols="12"
        >
          <template v-for="activity in activities">
            <ActivityItem
              :key="activity.uuid"
              :activity="activity"
              :selected-date="selectedDate"
              :selected-activities="selectedActivities"
              @increaseActivityDateQuantity="increaseActivityDateQuantity"
              @decreaseActivityDateQuantity="decreaseActivityDateQuantity"
              class="pb-3"
            />
          </template>
        </v-col>
      </v-row>
      <v-row v-if="selectedDate">
        <v-col
          class="pt-0 px-4"
          cols="12"
        >
          <span
            style="font-size: 12px; font-weight: 500; cursor: pointer; text-decoration: underline;"
            @click="deselectDate"
          >
            {{ $t('seeAllDates') }}
          </span>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import ActivityItem from "@/components/common/ActivityItem"

export default {
  name: "Activities",
  components: {
    ActivityItem
  },
  props: {
    activities: {
      type: Array,
      default: () => []
    },
    selectedDate: {
      type: Number,
      default: null
    },
    selectedActivities: {
      type: Array,
      default: null
    }
  },
  computed: {
    readableDate() {
      if(typeof this.selectedDate !== 'undefined' && this.selectedDate !== null) {
        const date = new Date(this.selectedDate)
        return date.toDateString()
      }
      return ''
    },
    filteredActivities() {
      const filteredActivities = []

      const activities = this.$store.state.activities
      if(Array.isArray(activities) && activities.length > 0) {
        for(let activityIndex = 0; activityIndex < activities.length; activityIndex++) {
          const activity = activities[activityIndex]
          if(typeof activity !== 'undefined' && activity !== null && Array.isArray(activity.dates)) {
            if (Array.isArray(activity.dates)) {
              const dates = []
              const today = new Date().getTime()
              for(let dateIndex = 0; dateIndex < activity.dates.length; dateIndex++) {
                const activityDate = activity.dates[dateIndex]
                if(typeof activityDate !== 'undefined' && activityDate !== null) {
                  // Assuming activityDate is in seconds
                  const activityDateInMillis = activityDate * 1000

                  // If user has selected a date we want to make sure only to get dates within that time period
                  if(this.selectedDate) {
                    const start = new Date(this.selectedDate).setUTCHours(0,0,0,0)
                    const end = new Date(this.selectedDate).setUTCHours(23,59,59,999)

                    // No dates before selected date
                    if(start > activityDateInMillis) {
                      continue
                    }

                    // No dates after selected date
                    if(end < activityDateInMillis) {
                      continue
                    }
                  }

                  // Checking if the date is in the past
                  if(today > activityDateInMillis) {
                    continue
                  }

                  dates.push(activityDate)
                }
              }

              // If we have dates we replace the previous dates (because they could contain dates in the past)
              // and push the activity as something we want to display
              if(dates.length > 0) {
                activity.dates = dates
                filteredActivities.push(activity)
              }
            }
          }
        }
      }

      return filteredActivities
    }
  },
  methods: {
    increaseActivityDateQuantity(payload) {
      this.$emit('increaseActivityDateQuantity', payload)
    },
    decreaseActivityDateQuantity(payload) {
      this.$emit('decreaseActivityDateQuantity', payload)
    },
    deselectDate() {
      this.$emit('deselectDate')
    }
  }
}
</script>

<style scoped>

</style>
