<template>
	<v-list class="py-0">
		<v-list-item>
			<v-list-item-content>
				<v-list-item-title style="font-weight: 900; font-size: 18px;">
					{{ $t('upsell.offerText') }}
				</v-list-item-title>
			</v-list-item-content>
		</v-list-item>
		<template v-for="(item, index) in items">
			<UpsellItem
					v-if="item !== null && item !== undefined"
					:key="item.uuid"
					:item="item"
					:class="index > 0 ? 'pt-2' : ''"
					:event-quantity="eventQuantity"
					:event-item="eventItem"
					@updateAdditionalItem="updateSelectedAdditionalItems"
			/>
		</template>
	</v-list>
</template>

<script>
import UpsellItem from "@/components/UpsellItem";
export default {
	name: "Upsell",
	components: {
		UpsellItem
	},
	props: {
		items: {
			type: Array,
			default: () => []
		},
		eventQuantity: {
			type: Number,
			default: 0
		},
		eventItem: {
			type: Object,
			default: null
		}
	},
	data() {
		return {
			selectedItems: []
		}
	},
	computed: {
	},
	methods: {
		updateSelectedAdditionalItems(value) {
			let itemIndex = this.selectedItems.findIndex(i => i.uuid === value.uuid)
			if(itemIndex >= 0) {
				if(value.quantity < 1) {
					this.selectedItems.splice(itemIndex, 1)
				} else {
					this.selectedItems[itemIndex].quantity = value.quantity
				}
			} else {
				this.selectedItems.push(value)
			}
			this.$emit('updateSelectedAdditionalItems', this.selectedItems)
		}
	},
	mounted() {
		//
	}
}
</script>

<style scoped>

</style>
