<template>
	<v-navigation-drawer width="500px" v-model="showDrawer" temporary app right floating :hide-overlay="false" mobile-breakpoint="800">
		<iframe height="100%" width="100%" seamless style="border: 0;" :src="checkoutUrl" />
	</v-navigation-drawer>
</template>

<script>
export default {
	name: 'CheckoutDrawer',
	data() {
		return {
			showDrawer: false
		}
	},
	computed: {
		checkoutUrl() {
			return this.$store.state.checkoutUrl
		}
	},
	watch: {
		checkoutUrl(newValue) {
			if(newValue !== null) {
				this.showDrawer = true
			} else {
				this.showDrawer = false
			}
		}
	}
}
</script>
