<template>
	<v-row align="center">
		<v-col cols="12" class="text-center">
			<div class="subtitle-2">
				{{ $t('productName') }} <a target="_blank" href="https://www.salescloud.is" style="text-decoration: none;">SalesCloud</a>
			</div>
			<div class="caption">
				{{organizationName}} {{ $t('partnersWith') }}
			</div>
		</v-col>
	</v-row>
</template>

<script>
	export default {
		name: "Footer",
		computed: {
			organizationUUID() {
				return this.$store.state.organizationUUID
			},
			organizationName: function() {
				const organization = this.$store.state.organization
				if(organization && organization.label) {
					return organization.label
				} else {
					return this.$i18n.t('defaultOrganization')
				}
			}
		}
	}
</script>

<style scoped>

</style>
