<template>
  <div>
    <div
      v-if="showAttributeName"
      class="d-flex flex-row align-center justify-space-between"
    >
      <div :class="largeAttributeName ? 'text-h6 font-weight-bold' : ''">
        {{ attributeName }}
      </div>
      <div v-if="attributeRequired">
        <v-chip x-small label outlined :color="positivePrimaryColor">
          {{ $t('Required') }}
        </v-chip>
      </div>
    </div>
    <div
      v-if="attributeDescription"
      v-html="attributeDescription"
      class="attribute-description"
    />
    <div>
      <template v-for="variation in variationsInAttribute">
        <ItemVariation
          :key="variation.uuid"
          :variation="variation"
          :attribute="attribute"
          :selected-variations="selectedVariations"
          :item="item"
        />
        <v-divider
          v-if="itemDivider"
          :key="variation.uuid + '-d'"
        />
      </template>
    </div>
  </div>
</template>

<script>
import ItemVariation from "@/components/item/ItemVariation";
export default {
  name: "ItemVariationAttribute",
  components: {
    ItemVariation
  },
  props: {
    attribute: {
      type: Object,
      default: () => null
    },
    variations: {
      type: Array,
      default: () => []
    },
    showAttributeName: {
      type: Boolean,
      default: true
    },
    selectedVariations: {
      type: Array,
      default: () => []
    },
    item: {
      type: Object,
      default: () => null
    },
    itemDivider: {
      type: Boolean,
      default: false
    },
    largeAttributeName: {
      type: Boolean,
      default: false
    },
    showZeroValueVariationPrices: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    variationsInAttribute() {
      return this.$store.getters.getVariationsInAttribute(this.attribute, this.variations)
    },
    attributeName() {
      if(typeof this.attribute === 'undefined' || this.attribute === null) {
        return ''
      }

      const key = 'attribute:name:' + this.attribute.uuid
      if(!this.$te(key)) {
        return this.attribute.name
      }

      return this.$t(key)
    },
    attributeDescription() {
      if(typeof this.attribute === 'undefined' || this.attribute === null) {
        return ''
      }

      const key = 'attribute:description:' + this.attribute.uuid
      if(!this.$te(key)) {
        return this.attribute.description
      }

      return this.$t(key)
    },
    attributeRequired() {
      if(typeof this.attribute === 'undefined' || this.attribute === null) {
        return false
      }
      if(typeof this.attribute.required !== 'boolean') {
        return false
      }

      return this.attribute.required
    },
    primaryColor() {
      return this.$store.getters.primaryColor;
    },
    positivePrimaryColor() {
      if (this.$store.getters.colorIsCompleteGrey(this.primaryColor)) {
        return "success";
      }
      return this.primaryColor;
    },
  }
}
</script>

<style scoped>
.attribute-description >>> p {
  margin-bottom: 0;
}
</style>