<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-select
          outlined
          hide-details
          :placeholder="$t('noTimeSelectedYet')"
          v-model="selectTimeslot"
          :items="selectableTimeslots"
          item-value="timestampInMillis"
          item-text="label"
          return-object
        >
          <template v-slot:selection="{ item }">
            <v-container fluid class="pa-0">
              <v-row
                align="center"
                dense
              >
                <v-col
                  v-if="!belowSecondScreenThresholdWidth"
                  cols="4"
                >
                  <v-chip
                    v-if="item.availableCount > 1 && item.availableCount <= item.paxAvailableThreshold"
                    color="orange"
                    outlined
                    small
                    label
                  >
                    {{ item.availableCount }} {{ $t('amountLeft') }}
                  </v-chip>
                </v-col>
                <v-col
                  :cols="belowSecondScreenThresholdWidth ? '12' : '4'"
                  class="text-center"
                >
                  <span style="width: 100%; font-size: 20px; font-weight: 500;">
                    {{ item.label }}
                  </span>
                </v-col>
                <v-col
                  v-if="!belowSecondScreenThresholdWidth"
                  cols="4"
                  class="text-end"
                >
                  <v-chip
                    small
                    label
                  >
                    <span style="font-weight: 600;">
                      {{ slotPrice(item) | formatPrice }}
                    </span>
                  </v-chip>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:item="{ item, on, attrs }">
            <v-list-item
              :disabled="item.disabled"
              v-on="on"
              v-bind="attrs"
            >
              <v-container fluid class="pa-0">
                <v-row
                  align="center"
                  dense
                >
                  <v-col
                    cols="4"
                  >
                    <v-chip
                      v-if="!item.isBlocked && item.availableCount > 1 && item.availableCount <= item.paxAvailableThreshold"
                      color="orange"
                      outlined
                      class="mr-2"
                      small
                      label
                    >
                      {{ item.availableCount }} {{ $t('amountLeft') }}
                    </v-chip>
                  </v-col>
                  <v-col
                    cols="4"
                    class="text-center"
                  >
                  <span
                    style="width: 100%; font-size: 20px; font-weight: 500;"
                  >
                    {{ item.label }}
                  </span>
                  </v-col>
                  <v-col
                    cols="4"
                    class="text-end"
                  >
                    <v-chip
                      v-if="item.availableCount < item.quantity || item.isBlocked === true"
                      class="white--text"
                      small
                      label
                      style="font-weight: 600;"
                    >
                      {{ $t('soldOut') }}
                    </v-chip>
                    <v-chip
                      v-else
                      small
                      label
                      :class="item.disabled ? 'white--text' : ''"
                    >
                    <span style="font-weight: 600;">
                      {{ slotPrice(item) | formatPrice }}
                    </span>
                    </v-chip>
                  </v-col>
                </v-row>
              </v-container>
            </v-list-item>
          </template>
        </v-select>
        <div
          v-if="belowSecondScreenThresholdWidth && selectedTimeslot"
          class="pt-1 text-end"
        >
          <v-chip
            small
            label
            class="mr-2"
          >
            <span style="font-weight: 600;">
              {{ slotPrice(selectedTimeslot) | formatPrice }}
            </span>
          </v-chip>
          <v-chip
            v-if="selectedTimeslot.availableCount > 1 && selectedTimeslot.availableCount <= selectedTimeslot.paxAvailableThreshold"
            color="orange"
            outlined
            small
            label
          >
            {{ selectedTimeslot.availableCount }} {{ $t('amountLeft') }}
          </v-chip>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "SingleTimeslotSelection",
  props: {
    timeslots: {
      type: Array,
      default: () => []
    },
    selectedTimeslot: {
      type: Object,
      default: null
    }
  },
  computed: {
    hintText() {
      console.log(this.selectedTimeslot)
      if(typeof this.selectedTimeslot !== 'undefined' && this.selectedTimeslot !== null) {
        return this.$options.filters.formatPrice(this.slotPrice(this.selectedTimeslot))
      }
      return null
    },
    belowFirstScreenThresholdWidth() {
      return this.$vuetify.breakpoint.width < this.$store.state.firstScreenThresholdWidth
    },
    belowSecondScreenThresholdWidth() {
      return this.$vuetify.breakpoint.width < this.$store.state.secondScreenThresholdWidth
    },
    selectTimeslot: {
      get() {
        if(typeof this.selectedTimeslot !== 'undefined' && this.selectedTimeslot !== null) {
          return this.selectedTimeslot.timestampInMillis
        }
        return null
      },
      set(value) {
        this.$emit('selectSlot', value)
      }
    },
    selectableTimeslots() {
      if(Array.isArray(this.timeslots)) {
        return this.timeslots
      }
      return []
    },
    useSlotPrices() {
      return this.$store.state.useSlotPrices
    }
  },
  methods: {
    slotPrice(item) {
      const price = {
        amount: 0
      }
      if(typeof item !== 'undefined' && item !== null && Array.isArray(item.slots)) {
        for(let slotIndex = 0; slotIndex < item.slots.length; slotIndex++) {
          const slot = item.slots[slotIndex]
          if(typeof slot !== 'undefined' && slot !== null) {
            if(typeof slot.totalPrice !== 'undefined' && slot.totalPrice !== null) {
              if(typeof slot.totalPrice.amount !== 'undefined' && slot.totalPrice.amount !== null) {
                price.amount += slot.totalPrice.amount
              }
              if(typeof slot.price.currency_code !== 'undefined' && slot.price.currency_code !== null) {
                if(typeof price.currency_code === 'undefined' || price.currency_code === null || price.currency_code === '') {
                  price.currency_code = slot.price.currency_code
                }
              }
            }
          }
        }
      }
      return price
    }
  }
}
</script>

<style scoped>

</style>