<template>
  <div>
    <v-select
      outlined
      hide-details
      :label="cartText"
      :items="quantityOptions"
      v-model="currentlySelectedQuantity"
      :no-data-text="$t('errors.fullyBooked')"
    />
  </div>
</template>

<script>
export default {
  name: "QuantitySelection",
  props: {
    selectedQuantity: {
      type: Number,
      default: 0
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    currentlySelectedQuantity: {
      get() {
        return this.selectedQuantity
      },
      set(value) {
        this.$emit('selectQuantity', value)
      }
    },
    unitOfMeasureText() {
      return this.$t(`amountsUnitOfMeasure.${this.unitOfMeasure}`)
    },
    cartText() {
      return this.$t('amount') + ' ' + this.unitOfMeasureText
    },
    unitOfMeasure() {
      let unitOfMeasure = 'people'

      if(Array.isArray(this.items) && this.items.length > 0) {
        const item = this.items[0]
        if(typeof item.unitOfMeasure !== 'undefined' && item.unitOfMeasure !== null) {
          unitOfMeasure = item.unitOfMeasure
        }
      }

      return unitOfMeasure
    },
    quantityOptions() {

      const options = []

      for(let i = this.minimumOrderQuantity; i <= this.maximumOrderQuantity; i++) {

        options.push({
          key: i,
          text: i
        })
      }


      return options
    },
    maximumOrderQuantity() {
      let maximum = 20

      if(Array.isArray(this.items) && this.items.length > 0) {
        const maxes = []
        for(let itemIndex = 0; itemIndex < this.items.length; itemIndex++) {
          const item = this.items[itemIndex]
          if(typeof item.maximumOrderQuantity !== 'undefined' && item.maximumOrderQuantity !== null) {
            maxes.push(item.maximumOrderQuantity)
          }
        }
        if(maxes.length > 0) {
          maximum = Math.max(...maxes)
        }
      }

      return maximum
    },
    minimumOrderQuantity() {
      let minimum = 2

      if(Array.isArray(this.items) && this.items.length > 0) {
        const minimums = []
        for(let itemIndex = 0; itemIndex < this.items.length; itemIndex++) {
          const item = this.items[itemIndex]
          if(typeof item.minimumOrderQuantity !== 'undefined' && item.minimumOrderQuantity !== null) {
            minimums.push(item.minimumOrderQuantity)
          }
        }
        if(minimums.length > 0) {
          minimum = Math.min(...minimums)
        }
      }

      return minimum
    }
  }
}
</script>

<style scoped>

</style>