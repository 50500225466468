<template>
  <v-card>
    <v-container>
      <LocaleChanger style="position: absolute; right: 0; top: 4px;" />
      <v-row>
        <v-col cols="12">
          <span style="font-weight: 300;">
            {{ $t('Purchase tickets') }}
          </span>
        </v-col>
        <template v-for="item in items">
          <v-col
            cols="12"
            class="pa-0"
            :key="item.uuid"
          >
            <ParticipantItem
              :item="item"
              :selected-items="selectedItems"
              v-on="$listeners"
              :disabled="disabled"
            />
          </v-col>
        </template>
      </v-row>
      <v-row v-if="organizationShouldShowAgeRestrictions">
        <v-col cols="12" class="pb-0">
          <v-divider />
        </v-col>
        <v-col cols="12" class="px-6">
          <div style="font-weight: 500; font-size: 14px; padding-bottom: 4px;">
            {{ $t('ageLimit') }}
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import ParticipantItem from "@/components/flow/participant/ParticipantItem"
import LocaleChanger from "@/components/LocaleChanger";

export default {
  name: "ParticipantItems",
  components: {
    LocaleChanger,
    ParticipantItem
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    selectedItems: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    organizationShouldShowAgeRestrictions() {
      return this.$store.getters.organizationShouldShowAgeRestrictions
    }
  }
}
</script>

<style scoped>

</style>
