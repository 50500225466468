<template>
	<v-container>
		<v-row justify="center">
			<v-col cols="12">
				<v-list class="py-0" style="background: transparent;">
					<v-row justify="center" align="start">
						<template v-for="(item, itemIndex) in items">
							<v-col :key="itemIndex" lg="4" md="6" sm="6" cols="12">
								<ItemListItem :key="item.key" :item="item" />
								<v-divider :key="itemIndex" v-if="isMobile && itemIndex < items.length - 1" />
							</v-col>
						</template>
					</v-row>
				</v-list>
			</v-col>
			<v-col v-if="canShowCancelButton" :class="canShowCancelButton && canShowChangeButton ? 'col-12 col-sm-6 col-lg-4' : 'col-12 col-sm-8'">
				<v-btn to="/cancel" :class="colorIsLight(accentColor) ? 'black--text' : 'white--text'" large block depressed dark :color="accentColor">{{$t('button.cancel')}}</v-btn>
			</v-col>
			<v-col v-if="canShowChangeButton" :class="canShowChangeButton && canShowCancelButton ? 'col-12 col-sm-6 col-lg-4' : 'col-12 col-sm-8'">
				<v-btn to="/change" :class="colorIsLight(accentColor) ? 'black--text' : 'white--text'" large block depressed dark :color="accentColor">{{$t('button.change')}}</v-btn>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>

import ItemListItem from './ItemListItem'

export default {
	name: 'ItemsList',
	components: {
		ItemListItem
	},
	data: () => ({

	}),
	methods: {
		colorIsLight(color) {
			return this.$store.getters.colorIsLight(color)
		}
	},
	computed: {
		canShowCancelButton() {
			return false
			// return this.$store.getters.canCancelBooking
		},
		canShowChangeButton() {
			return false
			// return this.$store.getters.canChangeBooking
		},
		isMobile() {
			return false
			//return this.$vuetify.breakpoint.width < 700
		},
		itemCategories() {
			const categories = {}

			const itemsLength = Array.isArray(this.items) ? this.items.length : 0

			for(let i = 0; i < itemsLength; i++) {

				if(this.items[i].categories === null || this.items[i].categories === undefined || this.items[i].categories.length === 0) {
					continue
				}

				const uuid = this.items[i].categories[0].uuid

				categories[uuid] = this.items[i].categories[0]
			}

			const keys = Object.keys(categories)

			const arr = []

			for(let i in keys) {
				arr.push(categories[keys[i]])
			}

			return arr
		},
		items() {
			return this.$store.getters.items
		},
		itemsByCategory() {
			const categoryIndex = {}

			const itemsLength = Array.isArray(this.items) ? this.items.length : 0

			for(let i = 0; i < itemsLength; i++) {

				if(this.items[i].categories === null || this.items[i].categories === undefined || this.items[i].categories.length === 0) {
					continue
				}

				const uuid = this.items[i].categories[0].uuid

				if(categoryIndex[uuid] === undefined) {
					categoryIndex[uuid] = []
				}

				categoryIndex[uuid].push(this.items[i])
			}

			return categoryIndex
		},
		itemsLength() {
			if(this.items && this.items.length) {
				return this.items.length
			} else {
				return 0
			}
		},
		primaryColor() {
			return this.$store.getters.primaryColor
		},
		accentColor() {
			return this.$store.getters.accentColor
		}
	},
	mounted() {
		if(window.parent) {
			window.parent.postMessage('routeChangeEvent', '*');
		} else {
			window.scrollTo(0,0)
		}
	},
	updated() {
		if(window.parent) {
			window.parent.postMessage('routeChangeEvent', '*');
		} else {
			window.scrollTo(0,0)
		}
	}
}
</script>
