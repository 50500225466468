import Vue from 'vue'
import VueRouter from 'vue-router'

import store from "@/store"

import Home from '../views/Home.vue'
import BookingFlow from "@/views/BookingFlow";
import ParticipantFlow from "@/views/ParticipantFlow";

// const routeNamesNotAffectingMode = ['Change', 'Cancel']

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: (to, from, next) => {
      if(store.state.mode === '1' || store.state.mode === '2') {
        if(from.name === 'Book' || from.name === 'Participant Flow') {
          next({ name: from.name })
        }
      } else {
        next()
      }
    }
  },
  {
    path: '/cancel',
    name: 'Cancel',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CancelBooking.vue')
  },
  {
    path: '/change',
    name: 'Change',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ChangeBooking.vue')
  },
  {
    path: '/book',
    name: 'Book',
    component: BookingFlow
  },
  {
    path: '/p',
    name: 'Participant Flow',
    component: ParticipantFlow
  }
]

const router = new VueRouter({
  routes
})

export default router
