<template>
  <div>
    <v-list-item class="pa-0">
      <v-list-item-content>
        <v-list-item-title>
          {{ variationLabel }}
        </v-list-item-title>
        <v-list-item-subtitle v-if="variationPrice && variationPrice.amount > 0">
          <span>
            <span
              v-if="formattedVariationTotalPrice.charAt(0) !== '0'"
              style="font-weight: 700;"
            >
              {{ formattedVariationTotalPrice }}
            </span>
            <span :class="variationQuantity > 0 ? 'text--disabled' : ''">
              <span v-if="variationQuantity > 0">
                {{ variationQuantity }} x
              </span>
              <span>
                {{ formattedVariationPrice }}
              </span>
            </span>
          </span>
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <SimplifiedQuantitySelection
          v-if="showQuantitySelection"
          no-padding
          :selected-quantity="variationQuantity"
          @decreaseQuantity="decreaseVariationQuantity"
          @increaseQuantity="increaseVariationQuantity"
        />
        <div v-else-if="showRadioButton">
          <v-icon
            v-if="variationQuantity > 0"
            :color="positivePrimaryColor"
            @click="selectSingleVariation"
          >
            mdi-radiobox-marked
          </v-icon>
          <v-icon
            v-else
            @click="selectSingleVariation"
          >
            mdi-radiobox-blank
          </v-icon>
        </div>
        <v-checkbox
          v-else
          v-model="selectVariation"
          :color="positivePrimaryColor"
        />
      </v-list-item-action>
    </v-list-item>
  </div>
</template>

<script>
import SimplifiedQuantitySelection from "@/components/common/SimplifiedQuantitySelection";
export default {
  name: "ItemVariation",
  components: {
    SimplifiedQuantitySelection
  },
  props: {
    variation: {
      type: Object,
      default: () => null
    },
    attribute: {
      type: Object,
      default: () => null
    },
    item: {
      type: Object,
      default: () => null
    },
    selectedVariations: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    variationLabel() {
      if(typeof this.variation === 'undefined' || this.variation === null) {
        return ''
      }

      const key = 'variation:label:' + this.variation.uuid
      if(!this.$te(key)) {
        return this.variation.label
      }

      return this.$t(key)
    },
    variationPrice() {
      if(typeof this.variation === 'undefined' || this.variation === null) {
        return null
      }
      if(typeof this.variation.price === 'undefined' || this.variation.price === null) {
        return null
      }
      return this.variation.price
    },
    formattedVariationPrice() {
      return this.$store.getters.getItemCurrencyDisplay(this.variationPrice)
    },
    formattedVariationTotalPrice() {
      if(typeof this.variationPrice === 'undefined' || this.variationPrice === null) {
        return ''
      }
      const amount = this.variationPrice.amount * this.variationQuantity
      const currency_code = this.variationPrice.currency_code
      return this.$store.getters.getItemCurrencyDisplay({ amount, currency_code })
    },
    showQuantitySelection() {
      if(typeof this.attribute === 'undefined' || this.attribute === null) {
        return false
      }
      if(typeof this.attribute.multiple !== 'boolean') {
        return false
      }

      return this.attribute.multiple
    },
    showRadioButton() {
      if(typeof this.attribute === 'undefined' || this.attribute === null) {
        return false
      }
      if(typeof this.attribute.single !== 'boolean') {
        return false
      }

      return this.attribute.single
    },
    variationQuantity() {
      if(typeof this.variation === 'undefined' || this.variation === null) {
        return 0
      }
      if(!Array.isArray(this.selectedAdditionalItems)) {
        return 0
      }

      const itemIndex = this.selectedAdditionalItems.findIndex(item => item && item.uuid === this.item.uuid)
      if(itemIndex < 0) {
        return 0
      }
      if(!Array.isArray(this.selectedAdditionalItems[itemIndex].selectedVariations)) {
        return 0
      }

      return this.selectedAdditionalItems[itemIndex].selectedVariations.filter(v => v && v.uuid === this.variation.uuid).length

      // const currentVariationsInSelectedVariations = this.selectedVariations.filter(variation => variation && variation.uuid === this.variation.uuid)
      // return currentVariationsInSelectedVariations.length
    },
    selectedAdditionalItems: {
      get() {
        return this.$store.state.selectedAdditionalItems
      },
      set(items) {
        this.$store.commit('updateSelectedAdditionalItems', items)
      }
    },
    selectVariation: {
      get() {
        return this.variationQuantity > 0
      },
      set(value) {
        if(value) {
          this.increaseVariationQuantity()
        } else {
          this.decreaseVariationQuantity()
        }
      }
    },
    primaryColor() {
      return this.$store.getters.primaryColor;
    },
    positivePrimaryColor() {
      if (this.$store.getters.colorIsCompleteGrey(this.primaryColor)) {
        return "success";
      }
      return this.primaryColor;
    },
  },
  methods: {
    increaseVariationQuantity(quantity = 1) {
      if(typeof this.item === 'undefined' || this.item === null) {
        return
      }
      if(typeof this.variation === 'undefined' || this.variation === null) {
        return
      }
      if(!Array.isArray(this.selectedAdditionalItems)) {
        this.selectedAdditionalItems = []
      }

      const itemIndex = this.selectedAdditionalItems.findIndex(item => item && item.uuid === this.item.uuid)
      if(itemIndex < 0) {
        this.selectedAdditionalItems.push({ ...this.item, quantity: quantity, selectedVariations: [ this.variation ]})
      } else {
        this.selectedAdditionalItems[itemIndex].selectedVariations.push(this.variation)
      }
    },
    decreaseVariationQuantity(quantity = 1) {
      if(typeof this.item === 'undefined' || this.item === null) {
        return
      }
      if(typeof this.variation === 'undefined' || this.variation === null) {
        return
      }
      if(!Array.isArray(this.selectedAdditionalItems)) {
        return
      }

      const itemIndex = this.selectedAdditionalItems.findIndex(item => item && item.uuid === this.item.uuid)
      if(itemIndex < 0) {
        return
      }
      const variationIndex = this.selectedAdditionalItems[itemIndex].selectedVariations.findIndex(variation => variation && variation.uuid === this.variation.uuid)
      if(variationIndex < 0) {
        return
      }
      this.selectedAdditionalItems[itemIndex].selectedVariations.splice(variationIndex, 1)
      if(this.selectedAdditionalItems[itemIndex].selectedVariations.length < quantity) {
        this.selectedAdditionalItems.splice(itemIndex, 1)
      }
    },
    // Handling single select variation (only one variation in the attribute can be selected)
    selectSingleVariation() {
      if(typeof this.item === 'undefined' || this.item === null) {
        return
      }
      if(typeof this.variation === 'undefined' || this.variation === null) {
        return
      }

      if(this.selectedVariations.some(v => v && v.uuid === this.variation.uuid)) {
        return
      }

      // No additional items have been set yet, safe to add without removing same attribute variations
      if(!Array.isArray(this.selectedAdditionalItems)) {
        this.selectedAdditionalItems = []
      }

      const itemIndex = this.selectedAdditionalItems.findIndex(item => item && item.uuid === this.item.uuid)
      if(itemIndex < 0) {
        // Item has not yet been added to additional items, safe to add without removing same attribute variations
        this.increaseVariationQuantity()
      } else {
        if(typeof this.attribute === 'undefined' || this.attribute === null) {
          return
        }
        // Find and remove all the variations that are in the current attribute
        const allVariationsNotInAttribute = []
        for(let variationIndex = 0; variationIndex < this.selectedAdditionalItems[itemIndex].selectedVariations.length; variationIndex++) {
          const variation = this.selectedAdditionalItems[itemIndex].selectedVariations[variationIndex]
          if(!variation) {
            continue
          }
          if(!variation.attribute) {
            continue
          }
          if(variation.attribute.uuid === this.attribute.uuid) {
            continue
          }

          allVariationsNotInAttribute.push(variation)
        }

        this.selectedAdditionalItems[itemIndex].selectedVariations = allVariationsNotInAttribute
        // Add new variation to selected variations
        this.increaseVariationQuantity()
      }
    },
  }
}
</script>

<style scoped>

</style>