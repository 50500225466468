<template>
	<v-app
		dark
		app
		data-iframe-height
		:style="
			backgroundColor
				? 'background-color: ' + backgroundColor + ' !important;'
				: ''
		"
	>
		<v-main class="transparent">
			<router-view />
			<div id="version" :version="version" />
		</v-main>
		<CheckoutDrawer />
		<v-footer
			v-if="!inApp && hasOrganization && !minimalFooter"
			:class="dark ? 'pt-5 pb-5' : 'grey lighten-3 pt-5 pb-5'"
		>
			<Footer />
		</v-footer>
		<v-footer
			v-else-if="
				!inApp && hasOrganization && minimalFooter && isInitialized
			"
			class="transparent pb-5"
		>
			<MinimalFooter />
		</v-footer>
	</v-app>
</template>

<script>
import { setOptions, bootstrap } from "vue-gtag";
import Footer from "./components/Footer";
import { version } from "../package.json";
import MinimalFooter from "@/components/common/MinimalFooter";
import CheckoutDrawer from "@/components/CheckoutDrawer";

function AppSettings(storageKey) {
	this.windowStorage = "salescloud-" + storageKey;
	this.params = new URLSearchParams(window.location.search);
}

AppSettings.prototype.get = function(value) {
	if (
		window &&
		window[this.windowStorage] &&
		window[this.windowStorage][value] !== null &&
		window[this.windowStorage][value] !== undefined &&
		window[this.windowStorage][value].length > 0 &&
		window[this.windowStorage][value][0] !== null &&
		window[this.windowStorage][value][0] !== undefined
	) {
		return window[this.windowStorage][value][0];
	}

	return this.params.get(value);
};

AppSettings.prototype.getAll = function(value) {
	if (
		window &&
		window[this.windowStorage] &&
		window[this.windowStorage][value] !== null &&
		window[this.windowStorage][value] !== undefined
	) {
		return window[this.windowStorage][value];
	}

	return this.params.getAll(value);
};

export default {
	name: "App",
	components: {
		MinimalFooter,
		Footer,
		CheckoutDrawer
	},
	data: () => ({
		routeNamesNotAffectingMode: ["Change", "Cancel"]
	}),
	computed: {
		googleTracker: {
			set(value) {
				this.$store.commit("updateGoogleTracker", value);
			},
			get() {
				return this.$store.state.googleTracker;
			}
		},
		isInitialized() {
			return this.$store.getters.isInitialized;
		},
		widgetPrimaryColor() {
			return this.$store.state.primaryColor;
		},
		darkTheme() {
			return this.$store.state.darkTheme;
		},
		backgroundColor() {
			return this.$store.state.backgroundColor;
		},
		minimalFooter() {
			return this.$store.state.minimalFooter;
		},
		mode() {
			return this.$store.state.mode;
		},
		version() {
			return version;
		},
		inApp() {
			return this.$store.getters.inApp;
		},
		dark() {
			return this.$vuetify.theme.dark;
		},
		hasOrganization() {
			if (
				this.$store.state.organization !== null &&
				this.$store.state.organization !== undefined &&
				this.$store.state.organization.uuid ===
					"0612810a-43d8-4adf-a8df-f2098286ca26"
			) {
				return false;
			}

			if (
				this.$store.state.organization !== null &&
				this.$store.state.organization !== undefined &&
				this.$store.state.organization.uuid ===
					"eae77cfa-23ae-40c5-95d5-632d37541204"
			) {
				return false;
			}

			return (
				this.$store.state.organization !== null &&
				this.$store.state.organization !== undefined &&
				this.$store.state.organization.label !== null &&
				this.$store.state.organization.label !== undefined
			);
		},
    showAllItemAvailability() {
      return this.$store.state.showAllItemAvailability
    },
    metaPixelCode() {
      return this.$store.getters.metaPixelCode
    }
	},
  methods: {
    colorToHex(color) {
      const hexadecimal = color.toString(16)
      return hexadecimal.padStart(2, '0')
    },
    rgbToHex(r, g, b) {
      return (
        '#' +
        this.colorToHex(r) +
        this.colorToHex(g) +
        this.colorToHex(b)
      )
    },
    setVuetifyPrimaryColor(hexColor) {
      this.$vuetify.theme.themes.light.primary = hexColor
      this.$vuetify.theme.themes.dark.primary = hexColor
    },
    initializeMetaPixel() {
      try {
        let mainCode =
          "!function(f,b,e,v,n,t,s)\n" +
          "{if(f.fbq)return;n=f.fbq=function(){n.callMethod?\n" +
          "n.callMethod.apply(n,arguments):n.queue.push(arguments)};\n" +
          "if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';\n" +
          "n.queue=[];t=b.createElement(e);t.async=!0;\n" +
          "t.src=v;s=b.getElementsByTagName(e)[0];\n" +
          "s.parentNode.insertBefore(t,s)}(window, document,'script',\n" +
          "'https://connect.facebook.net/en_US/fbevents.js');\n";
        let init = 'fbq("init", "' + this.metaPixelCode + '");\n';
        let track = 'fbq("track", "PageView");\n';

        let script = document.createElement("script");
        script.innerHTML = mainCode + init + track;

        document.head.appendChild(script);
      } catch(e) {
        //Do nothing if fails
      }
    }
  },
	watch: {
		$route: function() {
			window.parent.postMessage(
				JSON.stringify({
					event: "page_view",
					page: {
						path: window.location.href,
						title: document.title
					}
				}),
				"*"
			);
		},
		mode(value) {
			if (
				value &&
				!this.routeNamesNotAffectingMode.includes(
					this.$router.currentRoute.name
				)
			) {
				if (
					value === "1" &&
					this.$router.currentRoute &&
					this.$router.currentRoute.name !== "Book"
				) {
					this.$router.push("/book");
				}
				if (
					value === "2" &&
					this.$router.currentRoute &&
					this.$router.currentRoute.name !== "Participant Flow"
				) {
					this.$router.push("/p");
				}
			}
		},
		darkTheme(value) {
			this.$vuetify.theme.dark = value;
		},
		widgetPrimaryColor(color) {
			if (color) {
				if (color.includes("rgb(" || "rgba(")) {
					const rgb = color.replace(/rgb|a|\(|\)/gi, "").split(",");
					const r = parseInt(rgb[0]);
					const g = parseInt(rgb[1]);
					const b = parseInt(rgb[2]);
					this.setVuetifyPrimaryColor(this.rgbToHex(r, g, b));
				} else {
					let hexColor = color;
					if (!color.includes("#")) {
						hexColor = "#" + color;
					}
					this.setVuetifyPrimaryColor(hexColor);
				}
			}
    },
    isInitialized(value) {
      if(value) {
        if(this.metaPixelCode) {
            this.initializeMetaPixel()
        }
      }
		}
	},
	beforeMount() {
		const params = new AppSettings("salescloud-schedule");

		try {
			const html = document.getElementsByTagName("html")[0]
			console.log(html)
			if(this.$store.getters.isInIframe) {
				html.classList.add("in-iframe");
			}
			else {
				html.classList.add("not-in-iframe");
			}
		}
		catch(e) {
			// Do nothing
		}

		let widgetUUID = params.get("uuid");
		const preview = params.get("previewDataObject");

		if (widgetUUID) {
			this.$store.commit("setWidgetUUID", widgetUUID);
			this.$store.dispatch("getWidgetData");
		} else if (preview) {
			try {
				const previewValues = JSON.parse(decodeURIComponent(preview));
				if (previewValues) {
					this.$store.dispatch("setPreviewValues", previewValues);
				}
			} catch (e) {
				//
			}
		} else {
			let organization = params.get("organization");
			if (organization === null || typeof organization === "undefined") {
				organization = params.get("o");
			}

			let channel = params.get("channel");
			if (channel === null || typeof channel === "undefined") {
				channel = params.get("ch");
			}

			let location = params.get("l");

			let items = params.getAll("item");
			if (items === null || typeof items === "undefined") {
				items = params.get("i");
			}

			let categories = params.getAll("category");
			if (!Array.isArray(categories) || categories.length === 0) {
				categories = params.getAll("c");
			}

			let showAsCategories = params.get("showAsCategories");
			if (
				showAsCategories === null ||
				typeof showAsCategories === "undefined"
			) {
				showAsCategories = params.get("sac");
			}

			let dark = params.get("dark");
			let showImages = params.get("showImages");
			let bookingUUID = params.get("booking");

			let defaultLanguage = params.get("defaultLanguage");
			if (
				defaultLanguage === null ||
				typeof defaultLanguage === "undefined"
			) {
				defaultLanguage = params.get("lang");
			}

			let hideExtraInfo = params.get("hideExtraInfo");
			const showUpsellItemsAsSwitch = params.get(
				"showUpsellItemsAsSwitch"
			);

			const showAllItemAvailability = params.get("showAllAvail");
			this.$store.commit(
				"setShowAllItemAvailability",
				showAllItemAvailability === "true"
			);

			const mode = params.get("mode");
			this.$store.commit("setMode", mode);

			const mainButtonsRounded = params.get("mbtnrnd");
			this.$store.commit(
				"setMainButtonsRounded",
				mainButtonsRounded === "true"
			);

			const mainButtonsCapitalized = params.get("mbtncap");
			this.$store.commit(
				"setMainButtonsCapitalized",
				mainButtonsCapitalized === "true"
			);

			const mainButtonsColor = params.get("mcolor");
			if (mainButtonsColor) {
				if (mainButtonsColor.includes("rgb(" || "rgba(")) {
					this.$store.commit("setMainButtonsColor", mainButtonsColor);
				} else if (
					(mainButtonsColor.length === 3 ||
						mainButtonsColor.length === 6 ||
						mainButtonsColor.length === 8) &&
					!mainButtonsColor.includes("#")
				) {
					this.$store.commit(
						"setMainButtonsColor",
						"#" + mainButtonsColor
					);
				}
			}

			const backgroundColor = params.get("bcolor");
			if (backgroundColor) {
				if (backgroundColor.includes("rgb(" || "rgba(")) {
					this.$store.commit("setBackgroundColor", backgroundColor);
				} else if (
					(backgroundColor.length === 3 ||
						backgroundColor.length === 6 ||
						backgroundColor.length === 8) &&
					!backgroundColor.includes("#")
				) {
					this.$store.commit(
						"setBackgroundColor",
						"#" + backgroundColor
					);
				}
			}

			const textColor = params.get("tcolor");
			if (textColor) {
				if (textColor.includes("rgb(" || "rgba(")) {
					this.$store.commit("setTextColor", textColor);
				} else if (
					(textColor.length === 3 ||
						textColor.length === 6 ||
						textColor.length === 8) &&
					!textColor.includes("#")
				) {
					this.$store.commit("setTextColor", "#" + textColor);
				}
			}

			const primaryColor = params.get("pcolor");
			if (primaryColor) {
				if (primaryColor.includes("rgb(" || "rgba(")) {
					this.$store.commit("setPrimaryColor", primaryColor);
					const rgb = primaryColor
						.replace(/rgb|a|\(|\)/gi, "")
						.split(",");
					const r = parseInt(rgb[0]);
					const g = parseInt(rgb[1]);
					const b = parseInt(rgb[2]);
					this.setVuetifyPrimaryColor(this.rgbToHex(r, g, b));
				} else if (
					(primaryColor.length === 3 ||
						primaryColor.length === 6 ||
						primaryColor.length === 8) &&
					!primaryColor.includes("#")
				) {
					const hexColor = "#" + primaryColor;
					this.$store.commit("setPrimaryColor", hexColor);
					this.setVuetifyPrimaryColor(hexColor);
				}
			}

      const metaPixelCode = params.get('metaPixel')
			const minimalFooter = params.get("mf");
			this.$store.commit("setMinimalFooter", minimalFooter === "true");

			const hideItemPrice = params.get("hip");
			this.$store.commit("setHideItemPrice", hideItemPrice === "true");

			this.$store.commit("updateOrganizationUUID", organization);
			this.$store.commit("updateChannelUUID", channel);
			this.$store.commit("updateLocationUUID", location);
			this.$store.commit("updateItemUUIDsFilter", items);
			this.$store.commit(
				"setShowScheduleAsCategories",
				showAsCategories === "true"
			);
			this.$store.commit("setCategoryFilter", categories);
			this.$store.commit("updateBookingUUID", bookingUUID);
			this.$store.commit("setDefaultLanguage", defaultLanguage);
			this.$store.commit("setHideExtraInfo", hideExtraInfo === "true");
			this.$store.commit(
				"setUpsellItemsAsSwitch",
				showUpsellItemsAsSwitch === "true"
			);
      this.$store.commit('setMetaPixelCode', metaPixelCode)

			if (showImages === "false" || showImages === false) {
				this.$store.commit("setShowImages", false);
			}

			if (dark === "true") {
				this.$vuetify.theme.dark = true;
			}

			if (!location) {
				this.$store.commit(
					"updateLocationUUID",
					"5d6e55b5-98dd-461f-b761-f05e317e5513"
				);
			}

			const coupon = params.get("coupon");
			this.$store.dispatch("setCoupon", coupon);

			if (organization) {
				this.$store.dispatch("getInitialData");
			}
		}
	},
	mounted() {
		if (
			this.showAllItemAvailability === true &&
			this.$router.currentRoute &&
			this.$router.currentRoute.name !== "Book"
		) {
			this.$router.push("/book");
		}

		if (
			this.mode &&
			!this.routeNamesNotAffectingMode.includes(
				this.$router.currentRoute.name
			)
		) {
			if (
				this.mode === "1" &&
				this.$router.currentRoute &&
				this.$router.currentRoute.name !== "Book"
			) {
				this.$router.push("/book");
			}
			if (
				this.mode === "2" &&
				this.$router.currentRoute &&
				this.$router.currentRoute.name !== "Participant Flow"
			) {
				this.$router.push("/p");
			}
		}

		window.parent.postMessage(
			JSON.stringify({
				event: "page_view",
				page: {
					path: window.location.href,
					title: document.title
				}
			}),
			"*"
		);

		window.addEventListener("message", event => {
			try {
				if (
					event &&
					event.data === "object" &&
					event.data !== null &&
					!Array.isArray(event.data)
				) {
					const trackingData = JSON.parse(event.data);

					if (
						trackingData.trackerType === "GoogleAnalytics" &&
						this.googleTracker === null
					) {
						this.googleTracker = trackingData;
						console.log(this.googleTracker);

						if (
							trackingData.linkerParam !== null &&
							trackingData.linkerParam !== undefined
						) {
							const urlParams = new URLSearchParams(
								window.location.search
							);
							urlParams.set(
								"_ga",
								trackingData.linkerParam.substr(4)
							);
							window.location.search = urlParams;
							document.cookie =
								"ga-linker=" +
								"?" +
								trackingData.linkerParam +
								";path=/";
						}

						if (trackingData.trackingId === "UA-82207529-5") {
							trackingData.trackingId = "G-C6F79BZYTW";
						}

						setOptions({
							config: {
								id: trackingData.trackingId,
								params: {
									clientId: trackingData.clientId,
									send_page_view: true
								}
							},
							appName: "SalesCloud Schedule",
							pageTrackerScreenviewEnabled: true
						});

						bootstrap().then(() => {
							console.log("gtag", this.$gtag.query);

							this.$gtag.config({
								user_id: trackingData.clientId
							});

							this.$gtag.linker({
								domains: [
									trackingData.cookieDomain,
									"checkout.salescloud.is",
									"schedule.salescloud.is"
								],
								accept_incoming: true
							});

							this.$gtag.pageview(this.$route);
							console.log("gtagPageViewFired");
						});
					}
				}
			} catch (e) {
				// console.log(e)
			}
		});
	}
};
</script>

<style>
.theme--light.v-list-item .v-list-item__action-text,
.theme--light.v-list-item .v-list-item__subtitle {
	white-space: normal;
}
</style>
