<template>
	<v-list-item :disabled="disabled" @click="selectItem" class="pl-2" :style="isSelected ? 'background-color: ' + primaryColor + '; border-radius: 5px;' : ''">
		<v-list-item-avatar v-if="showImages" class="mr-4" size="72" rounded>
			<v-img class="grey" :src="itemImage">
				<v-overlay absolute v-if="isSelected" opacity="0.7">
					<v-icon size="48">mdi-check</v-icon>
				</v-overlay>
			</v-img>
		</v-list-item-avatar>
		<v-list-item-content>
			<v-list-item-title
					class="text-subtitle-2"
					style="white-space: normal;"
					:style="isSelected && (colorIsLight(primaryColor) ? 'white-space: normal !important; color: black;' : 'white-space: normal !important; color: white;')"
			>
				{{ itemTitle }}
			</v-list-item-title>
			<v-list-item-subtitle
					v-if="item.price && showPricePreview"
					:style="isSelected && (colorIsLight(primaryColor) ? 'color: black' : 'color: white')"
			>
				<span>
					<span>
						{{ getItemCurrencyDisplay }}
					</span>
					<span v-if="organizationUUID === 'c9632662-ce96-4261-91ee-eef98f5e9223'">
						<span class="text-lowercase">
							{{ $t('confirmationFee') }}
						</span>
					</span>
					<span v-if="itemHasUnitOfMeasure">
						{{ $t('per') }} {{ getUnitOfMeasureText }}
					</span>
				</span>
			</v-list-item-subtitle>
			<v-list-item-subtitle v-if="false" :style="isSelected && (colorIsLight(primaryColor) ? 'color: black' : 'color: white')" v-html="item.description.value"></v-list-item-subtitle>
			<v-list-item-subtitle style="white-space: normal;">
				<v-chip
					class="mr-2"
					:style="(colorIsLight(primaryColor) ? 'color: black' : 'color: white')"
					x-small
					v-if="isSingleDateEvent"
				>
					{{ $moment.utc(eventDate).format('DD/MM/YYYY') }}
				</v-chip>
				<v-chip
					color="warning"
					:style="(colorIsLight(primaryColor) ? 'color: white' : 'color: white')"
					x-small
					v-if="hasPredefinedDates && item.availability.current < 20 && item.availability.current > 1"
				>
					{{ item.availability.current }} {{ $t('amountLeft') }}
				</v-chip>
				<v-chip
					color="red"
					:style="(colorIsLight(primaryColor) ? 'color: white' : 'color: white')"
					x-small
					v-else-if="hasPredefinedDates && item.availability.current < 1"
				>
					{{ $t('soldOut') }}
				</v-chip>
			</v-list-item-subtitle>
		</v-list-item-content>
    <v-list-item-icon v-if="!showImages"><v-icon :style="isSelected && (colorIsLight(primaryColor) ? 'color: black;' : 'color: white;')">mdi-calendar</v-icon></v-list-item-icon>
	</v-list-item>
</template>

<script>
/**
 * @property availability
 */
export default {
	name: "CategoryListItemProduct",
	props: {
		item: Object,
		value: Object,
		selectedItems: Array
	},
	computed: {
		itemTitle() {
			if(typeof this.item === 'undefined' || this.item === null) {
				return ''
			}
			const key = 'item:title:' + this.item.uuid
			if(this.$te(key)) {
				return this.$t(key)
			}
			return this.item.title
		},
    showImages() {
      return this.$store.state.showImages
    },
    showPricePreview() {
      //Harðkóðun fyrir Skautahöllina í Laugardal
      if(this.$store.state.organizationUUID === '93ead57e-4f21-44fe-8d44-bcfd4cfcb5d4') {
        return false
      }
      if(typeof this.item !== 'undefined' && this.item !== null) {
        if(typeof this.item.price !== 'undefined' && this.item.price !== null) {
          if(typeof this.item.price.amount !== 'undefined' && this.item.price.amount !== null) {
            return this.item.price.amount > 0
          }
        }
      }
      return true
    },
		hasPredefinedDates() {
      // Returns true if item exists and has an array of dates with at least one date
      return typeof this.item !== 'undefined' && this.item !== null && Array.isArray(this.item.dates) && this.item.dates.length > 0
		},
		disabled() {

			if(this.item.availability !== null && this.item.availability !== undefined && this.item.availability.current < 1) {
				return this.item.availability.current < 1
			}

			return false
		},
		eventDate() {

			if(this.item.dates !== null && this.item.dates !== undefined && this.item.dates.length === 1) {
				return  this.item.dates[0] * 1000
			}

			return this.item.startDate
		},
		isSingleDateEvent() {

			if(this.item.dates !== null && this.item.dates !== undefined && this.item.dates.length === 1) {
				return true
			}

			if(this.item.startDate < 0 && this.item.endDate < 0) {
				const diffInMillis = this.item.endDate - this.item.startDate

				if(diffInMillis < 86400) {
					return true
				}
			}

			return false
		},
		itemImage() {
			if(this.item && this.item.images && this.item.images.length) {
				return this.item.images[0].file.sources.large
			} else {
				return ''
			}
		},
		isSelected() {
			if(Array.isArray(this.selectedItems) && this.selectedItems.length > 0) {
				return this.selectedItems.includes(this.item)
			} else if(this.value) {
				return this.value.uuid === this.item.uuid
			} else {
				return false
			}
		},
		getItemCurrencyDisplay() {
			return this.$store.getters.getItemCurrencyDisplay(this.item.price)
		},
		getUnitOfMeasureText() {
			if(this.organizationUUID === '32036a02-fd37-4044-bbb1-e55970e4531f') {
				return this.$store.getters.unitOfMeasureText('people')
			}
			return this.$store.getters.unitOfMeasureText(this.item.unitOfMeasure)
		},
		itemHasUnitOfMeasure() {
			if(this.organizationUUID === '32036a02-fd37-4044-bbb1-e55970e4531f') {
				return true
			}
			return !!this.item.unitOfMeasure
		},
		organizationUUID() {
			return this.$store.state.organizationUUID
		},
		primaryColor() {
			return this.$store.getters.primaryColor
		}
	},
	methods: {
		selectItem() {
			this.$emit('click', this.item)
		},
		colorIsLight(color) {
			return this.$store.getters.colorIsLight(color)
		}
	}
}
</script>

<style scoped>

</style>
