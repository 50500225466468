<template>
  <v-row class="transparent">
    <v-col cols="12" class="text-center">
      <span style="font-weight: 400; font-size: 14px;">
        {{ $t('poweredBy') }}
      </span>
      <a
        target="_blank"
        href="https://www.salescloud.is"
        style="text-decoration: none; font-weight: 700; font-size: 14px;"
        :class="backgroundColorIsLight ? 'black--text' : 'white--text'"
      >
        SalesCloud
      </a>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "MinimalFooter",
  computed: {
    backgroundColor() {
      return this.$store.state.backgroundColor
    },
    backgroundColorIsLight() {
      if(this.backgroundColor) {
        return this.$store.getters.colorIsLight(this.backgroundColor)
      }
      return true
    }
  }
}
</script>

<style scoped>

</style>