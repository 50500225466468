<template>
  <v-btn
    x-large
    block
    depressed
    :color="slotIsSelected ? 'success' : ''"
    @click="selectSlot"
  >
    {{ slotTime }}
  </v-btn>
</template>

<script>
export default {
  name: "AvailableSlot",
  props: {
    availableSlot: {
      type: Object,
      default: null
    },
    selectedSlots: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    slotTime() {
      if(typeof this.availableSlot !== 'undefined' && this.availableSlot !== null) {
        return this.availableSlot.label
      }
      return ''
    },
    slotPrice() {
      if(typeof this.availableSlot !== 'undefined' && this.availableSlot !== null) {
        if(typeof this.availableSlot.price !== 'undefined' && this.availableSlot.price !== null) {
          return this.$store.getters.getItemCurrencyDisplay(this.availableSlot.price)
        }
      }
      return null
    },
    slotIsSelected() {
      if(typeof this.availableSlot !== 'undefined' && this.availableSlot !== null) {
        if(Array.isArray(this.selectedSlots) && this.selectedSlots.length > 0) {
          return this.selectedSlots.some(s => s && s.timestampInMillis === this.availableSlot.timestampInMillis)
        }
      }
      return false
    }
  },
  methods: {
    selectSlot() {
      this.$emit('selectSlot', this.availableSlot)
    }
  }
}
</script>

<style scoped>

</style>