<template>
	<v-card flat>
		<v-card-title class="py-2">{{ $t("selected") }}</v-card-title>
		<v-card-text class="px-0">
			<v-list class="py-0">
				<v-divider></v-divider>
				<template v-for="item in selectedItems">
					<ItemListMultipleSelectedListItem
						:key="item.uuid"
						:item="item"
					></ItemListMultipleSelectedListItem>
				</template>
			</v-list>
		</v-card-text>
		<v-spacer class="fill-height"></v-spacer>
		<v-card-text>
			<v-select
				class="mt-0 mb-0"
				hide-details
				dense
				outlined
				:label="cartText"
				:items="quantityOptions"
				item-text="text"
				item-value="key"
				v-model="quantity"
			></v-select>
		</v-card-text>
		<v-card-actions>
			<v-btn
				large
				block
				:class="textButtonColor"
				:disabled="processing || cartQuantity < 1"
				:loading="processing"
				@click="bookItems"
				:color="primaryColor"
				>{{ $t("button.book") }}</v-btn
			>
		</v-card-actions>
	</v-card>
</template>

<script>
import axios from "axios";
import jsesc from "jsesc";
import ItemListMultipleSelectedListItem from "@/components/ItemListMultipleSelectedListItem";

/**
 * @property defaultQuantity
 */
export default {
	name: "ItemListMultipleSelectedList",
	props: ["item", "inCard", "inCategory", "selectedItems"],
	components: {
		ItemListMultipleSelectedListItem
	},
	data: () => ({
		quantity: 1,
		addingToCart: false,
		processing: false,
		selectedVariation: null,
		showAvailability: false,
		selectedTimeSlots: [],
		selectedPredefinedDate: null,
		hasError: false,
		errorMessage: null,
		showUpsell: false
	}),
	watch: {
		quantity(currentAmount, previousAmount) {
			if (currentAmount !== previousAmount) {
				this.hasError = false;
				this.errorMessage = null;
			}
		}
	},
	mounted() {},
	computed: {
		isInIframe() {
			return this.$store.getters.isInIframe
		},
		inApp() {
			return this.$store.getters.inApp;
		},
		totalPrice() {
			const totalPrice = JSON.parse(JSON.stringify(this.item.price));

			for (let i = 0; i < this.item.variations.length; i++) {
				const variation = this.item.variations[i];
				const quantity =
					variation.value !== undefined ? variation.value : 0;
				totalPrice.amount =
					totalPrice.amount + variation.price.amount * quantity;
			}

			return totalPrice;
		},
		cartQuantity() {
			return this.quantity;
		},
		variationQuantity() {
			let quantity = 0;

			if (this.hasVariationsAffectingMeasure) {
				for (let i in this.item.variations) {
					const variation = this.item.variations[i];

					if (
						variation.value !== null &&
						variation.value !== undefined
					) {
						quantity += variation.value;
					}
				}
			}

			return quantity;
		},
		hasVariationsAffectingMeasure() {
			return (
				this.item &&
				this.item.variations !== null &&
				this.item.variations.length > 0
			);
		},
		unitOfMeasure() {
			let unitOfMeasure = "people";

			if (
				this.item &&
				this.item.unitOfMeasure !== null &&
				this.item.unitOfMeasure !== undefined
			) {
				unitOfMeasure = this.item.unitOfMeasure;
			}

			return unitOfMeasure;
		},
		unitOfMeasureText() {
			return this.$t(`amountsUnitOfMeasure.${this.unitOfMeasure}`);
		},
		cartText() {
			return (
				this.$t("amount") +
				" " +
				(this.categoryIsSmarabioNamskeid
					? this.$t("barna")
					: this.unitOfMeasureText)
			);
		},
		sessionToken: {
			get() {
				return this.$store.state.sessionToken;
			},
			set(value) {
				this.$store.state.sessionToken = value;
			}
		},
		channelUUID() {
			return this.$store.state.channelUUID;
		},
		requiredSlots() {
			if (!this.cartQuantity) {
				return 0;
			}
			if (!this.maximumGroupedQuantity) {
				return this.cartQuantity;
			}
			return Math.ceil(this.cartQuantity / this.maximumGroupedQuantity);
		},
		missingSlots() {
			return this.requiredSlots - this.selectedTimeSlots.length;
		},
		organizationUUID() {
			return this.$store.state.organizationUUID;
		},
		primaryColor() {
			return this.$store.getters.primaryColor;
		},
		textButtonColor() {
			if (this.colorIsLight(this.primaryColor)) {
				return "black--text";
			} else {
				return "white--text";
			}
		},
		organizationIsSmarabio() {
			return this.$store.getters.organizationIsSmarabio;
		},
		categoryIsSmarabioNamskeid() {
			return this.$store.getters.categoryIsNamskeid;
		},
		quantityOptions() {
			const options = [];

			let min = Math.min(
				...this.selectedItems.map(i => i.minimumOrderQuantity)
			);
			let max = Math.min(
				...this.selectedItems.map(i => i.maximumOrderQuantity)
			);
			let maxAvailability = Math.min(
				...this.selectedItems.map(i =>
					i.availability ? i.availability.current : null
				)
			);
			if (min <= 0) {
				min = 1;
			}
			if (max <= 0) {
				max = 10;
			}
			if (max <= min) {
				max = min;
			}

			if (
				maxAvailability !== null &&
				maxAvailability !== undefined &&
				maxAvailability < min
			) {
				min = 0;
				max = 0;
			}

			if (
				maxAvailability !== null &&
				maxAvailability !== undefined &&
				maxAvailability < max
			) {
				max = maxAvailability;
			}

			for (let i = min; i <= max; i++) {
				options.push({
					key: i,
					text: i
				});
			}

			return options;
		},
		hasSlots() {
			if (
				Array.isArray(this.selectedItems) &&
				this.selectedItems.length > 0
			) {
				return this.selectedItems.every(
					item => item.hasSlots && item.hasSlots === true
				);
			}
			return false;
		},
		bookingItemsHaveAdditionalItems() {
			return false;
		}
	},
	methods: {
		getBookingCountByItem() {
			if (
				Array.isArray(this.selectedTimeSlots) &&
				this.selectedTimeSlots.length > 0
			) {
				if (this.selectedTimeSlots.length === 1) {
					const firstSlot = this.selectedTimeSlots[0];
					this.$store
						.dispatch("bookingCountsByItem", {
							item: this.item,
							startTimeInMillis: firstSlot.startTimeInMillis,
							endTimeInMillis: firstSlot.endTimeInMillis
						})
						.then(result => {
							this.bookingCount = result;
						});
				}
			}
		},
		itemIsSmarabioNamskeid(item) {
			return this.$store.getters.itemIsNamskeid(item);
		},
		variationQuantityOptions(variation) {
			const options = [];

			const remainingQuantity =
				this.maximumOrderQuantity - this.cartQuantity;

			for (let i = 0; i <= this.maximumOrderQuantity; i++) {
				let isBlocked = false;

				if (remainingQuantity < i && variation.value < i) {
					isBlocked = true;
				}

				options.push({
					key: i,
					text: i,
					disabled: isBlocked
				});
			}

			return options;
		},
		timeSlotsSelected(timeSlots) {
			if (this.hasError) {
				this.hasError = false;
				this.errorMessage = null;
			}
			this.selectedTimeSlots = timeSlots;
		},
		checkAvailability() {
			this.processing = true;
			this.fetchSessionToken().then(() => {
				this.showAvailability = true;
				this.processing = false;
			});
		},
		colorIsLight(color) {
			return this.$store.getters.colorIsLight(color);
		},
		bookItems() {
			this.processing = true;

			if (!this.hasSlots) {
				this.hasError = true;
				this.errorMessage = this.$t("errors.fullyBooked");
				this.processing = false;
				return;
			}

			if (this.bookingItemsHaveAdditionalItems) {
				this.showUpsell = true;
				return;
			}

			this.addToCart();
		},
		getSessionToken() {
			let sessionToken = null;

			try {
				sessionToken = JSON.parse(localStorage.getItem("sessionToken"));
			} catch (e) {
				localStorage.removeItem("sessionToken");
			}

			this.sessionToken = sessionToken;
		},
		saveSessionToken(sessionToken) {
			localStorage.setItem("sessionToken", JSON.stringify(sessionToken));
		},
		fetchSessionToken() {
			const graphOptions = {
				headers: {
					"Content-Type": "application/json"
				}
			};

			let url = "https://graph.salescloud.is?";

			const graphBody = {
				query: "mutation { newAnonymousSession { session_token, ttl } }"
			};

			return axios
				.post(url, graphBody, graphOptions)
				.then(response => {
					this.sessionToken = response.data.data.newAnonymousSession;
					this.saveSessionToken(this.sessionToken);
					return response.data.data.newAnonymousSession;
				})
				.catch(() => {
					return null;
				});
		},
		addToCart() {
			this.addingToCart = true;

			this.fetchSessionToken().then(sessionToken => {
				const cartItems = [];
				const dataLayerItems = [];

				let languageOption = "is";
				if (this.$i18n.locale === "is" || this.$i18n.locale === "en") {
					languageOption = this.$i18n.locale;
				}

				let values = {};

				if (
					!Array.isArray(this.selectedItems) ||
					this.selectedItems.length < 1
				) {
					return;
				}

				let selectedItems = [...this.selectedItems];

				if (
					this.organizationIsSmarabio &&
					this.categoryIsSmarabioNamskeid
				) {
					let selectedMoreThanOneNamskeid =
						Array.isArray(selectedItems) &&
						selectedItems.length > 1;

					// Start by checking if more than 1 child has been selected and add discount if appropriate
					if (this.quantity > 1) {
						// Only one child gets discounted
						let discountedQuantity = this.quantity - 1;
						let quantityLeft = 1;
						let item = selectedItems[0];

						let itemPrice = {
							amount: 0,
							currency_code: "ISK"
						};

						if (
							typeof item.price !== "undefined" &&
							item.price !== null
						) {
							if (
								typeof item.price.amount !== "undefined" &&
								item.price.amount > 0
							) {
								itemPrice = item.price;
							}
						}
						if (
							typeof item.sale_price !== "undefined" &&
							item.sale_price !== null
						) {
							if (
								typeof item.sale_price.amount !== "undefined" &&
								item.sale_price.amount > 0
							) {
								itemPrice = item.sale_price;
							}
						}

						if (Array.isArray(item.selectedTimeSlots)) {
							values.timeSlots = item.selectedTimeSlots.map(
								timeSlot => {
									return {
										startsAtTime:
											timeSlot.startTimeInMillis / 1000,
										endsAtTime:
											timeSlot.endTimeInMillis / 1000
									};
								}
							);
						}

						let normalValues = JSON.parse(JSON.stringify(values));
						let discountedValues = JSON.parse(
							JSON.stringify(values)
						);

						discountedValues.amount = this.$store.getters.smarabioDiscountedPrice(
							item
						);

						// normal price
						cartItems.push({
							item: item.uuid,
							quantity: quantityLeft,
							uiValues: jsesc(normalValues, { json: true })
						});

						dataLayerItems.push({
							item_name: item.title,
							item_id: item.sku,
							price: itemPrice.amount,
							currency: itemPrice.currency_code,
							quantity: quantityLeft
						});

						// discounted
						cartItems.push({
							item: item.uuid,
							quantity: discountedQuantity,
							uiValues: jsesc(discountedValues, { json: true })
						});

						dataLayerItems.push({
							item_name: item.title,
							item_id: item.sku,
							price: discountedValues.amount,
							discount:
								itemPrice.amount - discountedValues.amount,
							currency: itemPrice.currency_code,
							quantity: discountedQuantity
						});
					}

					// then we check if more than one namskeid has been selected
					if (selectedMoreThanOneNamskeid) {
						// Only one namskeid gets discounted
						let discountedNamskeid = selectedItems.splice(1, 1)[0];

						for (let i = 0; i < selectedItems.length; i++) {
							let selectedItem = selectedItems[i];
							let normalValues = JSON.parse(
								JSON.stringify(values)
							);

							let selectedItemPrice = {
								amount: 0,
								currency_code: "ISK"
							};

							if (
								typeof selectedItem.price !== "undefined" &&
								selectedItem.price !== null
							) {
								if (
									typeof selectedItem.price.amount !==
										"undefined" &&
									selectedItem.price.amount > 0
								) {
									selectedItemPrice = selectedItem.price;
								}
							}
							if (
								typeof selectedItem.sale_price !==
									"undefined" &&
								selectedItem.sale_price !== null
							) {
								if (
									typeof selectedItem.sale_price.amount !==
										"undefined" &&
									selectedItem.sale_price.amount > 0
								) {
									selectedItemPrice = selectedItem.sale_price;
								}
							}

							if (Array.isArray(selectedItem.selectedTimeSlots)) {
								normalValues.timeSlots = selectedItem.selectedTimeSlots.map(
									timeSlot => {
										return {
											startsAtTime:
												timeSlot.startTimeInMillis /
												1000,
											endsAtTime:
												timeSlot.endTimeInMillis / 1000
										};
									}
								);
							}

							if (cartItems.length > 0 && i === 0) {
								continue;
							}

							cartItems.push({
								item: selectedItem.uuid,
								quantity: this.quantity,
								uiValues: jsesc(normalValues, { json: true })
							});

							dataLayerItems.push({
								item_name: selectedItem.title,
								item_id: selectedItem.sku,
								price: selectedItemPrice.amount,
								currency: selectedItemPrice.currency_code,
								quantity: this.quantity
							});
						}

						let discountedValues = JSON.parse(
							JSON.stringify(values)
						);
						discountedValues.amount = this.$store.getters.smarabioDiscountedPrice(
							discountedNamskeid
						);
						if (
							Array.isArray(discountedNamskeid.selectedTimeSlots)
						) {
							discountedValues.timeSlots = discountedNamskeid.selectedTimeSlots.map(
								timeSlot => {
									return {
										startsAtTime:
											timeSlot.startTimeInMillis / 1000,
										endsAtTime:
											timeSlot.endTimeInMillis / 1000
									};
								}
							);
						}

						let discountedNamskeidPrice = {
							amount: 0,
							currency_code: "ISK"
						};

						if (
							typeof discountedNamskeid.price !== "undefined" &&
							discountedNamskeid.price !== null
						) {
							if (
								typeof discountedNamskeid.price.amount !==
									"undefined" &&
								discountedNamskeid.price.amount > 0
							) {
								discountedNamskeidPrice =
									discountedNamskeid.price;
							}
						}
						if (
							typeof discountedNamskeid.sale_price !==
								"undefined" &&
							discountedNamskeid.sale_price !== null
						) {
							if (
								typeof discountedNamskeid.sale_price.amount !==
									"undefined" &&
								discountedNamskeid.sale_price.amount > 0
							) {
								discountedNamskeidPrice =
									discountedNamskeid.sale_price;
							}
						}

						cartItems.push({
							item: discountedNamskeid.uuid,
							quantity: this.quantity,
							uiValues: jsesc(discountedValues, { json: true })
						});

						dataLayerItems.push({
							item_name: discountedNamskeid.title,
							item_id: discountedNamskeid.sku,
							price: discountedValues.amount,
							discount:
								discountedNamskeidPrice.amount -
								discountedValues.amount,
							currency: discountedNamskeidPrice.currency_code,
							quantity: this.quantity
						});
					}

					if (cartItems.length < 1) {
						let item = selectedItems[0];

						let itemPrice = {
							amount: 0,
							currency_code: "ISK"
						};

						if (
							typeof item.price !== "undefined" &&
							item.price !== null
						) {
							if (
								typeof item.price.amount !== "undefined" &&
								item.price.amount > 0
							) {
								itemPrice = item.price;
							}
						}
						if (
							typeof item.sale_price !== "undefined" &&
							item.sale_price !== null
						) {
							if (
								typeof item.sale_price.amount !== "undefined" &&
								item.sale_price.amount > 0
							) {
								itemPrice = item.sale_price;
							}
						}

						if (Array.isArray(item.selectedTimeSlots)) {
							values.timeSlots = item.selectedTimeSlots.map(
								timeSlot => {
									return {
										startsAtTime:
											timeSlot.startTimeInMillis / 1000,
										endsAtTime:
											timeSlot.endTimeInMillis / 1000
									};
								}
							);
						}

						cartItems.push({
							item: item.uuid,
							quantity: this.quantity,
							uiValues: jsesc(values, { json: true })
						});

						dataLayerItems.push({
							item_name: item.title,
							item_id: item.sku,
							price: itemPrice.amount,
							currency: itemPrice.currency_code,
							quantity: this.quantity
						});
					}
				}

				// Sending cart items to top level window for tracking
				try {
					// Clear the previous ecommerce object
					window.parent.postMessage(
						JSON.stringify({ ecommerce: null }),
						"*"
					);

					// Send the Ecommerce items
					window.parent.postMessage(
						JSON.stringify({
							event: "add_to_cart",
							ecommerce: {
								items: dataLayerItems
							}
						}),
						"*"
					);
				} catch (e) {
					// Do nothing if failed
				}

				if (!this.inApp) {
					this.$store
						.dispatch("checkoutItems", {
							languageOption,
							values,
							cartItems,
							sessionToken
						})
						.then(order => {
							if (
								typeof order !== "undefined" &&
								order !== null &&
								typeof order.uuid !== "undefined" &&
								order.uuid !== null
							) {
								const channel = this.channelUUID;
								const orderUUID = order.uuid;

								try {
									// eslint-disable-next-line no-undef
									window.parent.postMessage(
										"closeResizer",
										"*"
									);
									window.parent.postMessage(
										JSON.stringify({
											event: "eventTracking",
											category: "schedule",
											action: "Continue to Checkout",
											label: "schedule"
										}),
										"*"
									);
								} catch (e) {
									// Do nothing
									console.log(e);
								}

								let checkoutUrl = "https://checkout.salescloud.is/?time=" +
									Date.now() +
									"&dark=" +
									this.$vuetify.theme.dark +
									"&l=" +
									this.$store.state.locationUUID +
									"#" +
									this.organizationUUID +
									"/" +
									channel +
									"/" +
									orderUUID +
									"/checkout" +
									"?sessionToken=" +
									this.sessionToken.session_token +
									"&language=" +
									languageOption;

								if(this.isInIframe) {
									// eslint-disable-next-line no-unused-vars
								document.location =
									checkoutUrl
								}
								else {
									this.$store.commit('setCheckoutUrl', checkoutUrl)
								}


							} else {
								this.errorMessage =
									this.$t("errors.couldNotAddToCart") +
									" - " +
									this.$t("errors.fullyBooked");
								alert(this.errorMessage);
								this.processing = false;
							}
						})
						.catch(() => {
							this.errorMessage = this.$t(
								"errors.couldNotAddToCart"
							);
							alert(this.errorMessage);
							this.processing = false;
						})
						.finally(() => {});
				} else {
					/*eslint-disable no-undef*/
					// SalesCloud.addLineItemToCurrentOrder(SalesCloud.getCurrentOrderUUID(), JSON.stringify({
					// 	label: this.item.title,
					// 	itemUUID: this.item.uuid,
					// 	quantity: this.cartQuantity,
					// 	amount: this.item.price.amount,
					// 	currencyCode: this.item.price.currencyCode,
					// 	data: values
					// }))
					// FIXME: make this work for in app
					this.addingToCart = false;
					return null;
				}
			});
		}
	}
};
</script>
