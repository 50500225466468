<template>
  <v-container>
    <v-row
      v-if="!isInitialized"
      justify="center"
      class="transparent"
    >
      <v-col cols="auto">
        <v-progress-circular indeterminate />
      </v-col>
    </v-row>
    <v-row
      v-else
      justify="center"
    >
      <v-col cols="12">
        <v-container fluid>
          <v-row justify="center">
            <v-col
              cols="12"
              md="6"
            >
              <ParticipantItems
                :items="items"
                :selected-items="selectedItems"
                @decreaseQuantity="decreaseQuantity"
                @increaseQuantity="increaseQuantity"
                :disabled="gettingAvailableTimeSlots || addingToCart"
              />
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col
              cols="12"
              md="6"
            >
              <v-card>
                <v-container fluid>
                  <v-row justify="center">
                    <v-col cols="12">
                      <span style="font-weight: 300;">
                        {{ $t('selectDate') }}
                      </span>
                    </v-col>
                    <v-col cols="12">
                      <DateSelection
                        :selected-date="selectedDate"
                        :items="items"
                        @selectDate="selectDate"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
          <v-row
            v-if="(gettingAvailableTimeSlots || (Array.isArray(selectedItems) && selectedItems.length > 0 && selectedDate)) && !selectedItems.every(item => item && item.isAllDayEvent)"
            justify="center">
            <v-col
              cols="12"
              md="6"
            >
              <v-card>
                <v-container fluid>
                  <v-row v-if="gettingAvailableTimeSlots">
                    <v-col
                      cols="12"
                      class="text-center"
                    >
                      <v-progress-circular indeterminate />
                    </v-col>
                  </v-row>
                  <v-row
                    v-else-if="Array.isArray(combinedAvailableSlots) && combinedAvailableSlots.length > 0"
                    no-gutters
                  >
                    <v-col cols="12">
                      <span style="font-weight: 300;">
                        {{ $t('selectTime') }}
                      </span>
                    </v-col>
                    <v-col cols="12">
                      <SingleTimeslotSelection
                        :timeslots="combinedAvailableSlots"
                        :selected-timeslot="Array.isArray(selectedSlots) && selectedSlots.length > 0 ? selectedSlots[0] : null"
                        @selectSlot="selectSlot"
                      />
                    </v-col>
                  </v-row>
                  <v-row v-else-if="suggestedTimeSlot && false">
                    <v-col cols="12">
                      <span style="font-weight: 300;">
                          <span v-if="Array.isArray(selectedSlots) && selectedSlots.length > 0">
                              {{ $tc('selectedTime', selectedSlots.length) }}
                          </span>
                          <span v-else>
                              {{ $tc('suggestedTime') }}
                          </span>
                      </span>
                    </v-col>
                    <v-col cols="12">
                      <AvailableSlot
                        :available-slot="suggestedTimeSlot"
                        :selected-slots="selectedSlots"
                        @selectSlot="selectSlot"
                      />
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col
                      cols="12"
                      class="text-center grey lighten-3"
                    >
                      <span style="font-weight: 300;">
                        {{ $t('errors.fullyBooked') }}
                      </span>
                    </v-col>
                  </v-row>
                </v-container>
                <v-btn
                  v-if="Array.isArray(combinedAvailableSlots) && combinedAvailableSlots.length > 0 && false"
                  depressed
                  block
                  class="transparent"
                  @click="showMoreSlotOptions = true"
                  :disabled="gettingAvailableTimeSlots || addingToCart"
                >
                  {{ $t('otherTimeOptions') }}
                </v-btn>
              </v-card>
            </v-col>
          </v-row>
          <v-row
            v-if="Array.isArray(filteredActivities) && filteredActivities.length > 0"
            justify="center"
          >
            <v-col
              cols="12"
              md="6"
            >
              <Activities
                :activities="filteredActivities"
                :selected-date="selectedDate"
                :selected-activities="selectedActivities"
                @deselectDate="selectedDate = null"
                @decreaseActivityDateQuantity="decreaseActivityDateQuantity"
                @increaseActivityDateQuantity="increaseActivityDateQuantity"
              />
            </v-col>
          </v-row>
          <v-row
              v-if="Array.isArray(selectedSlots) && selectedSlots.length > 0 && Array.isArray(allAdditionalItems) && allAdditionalItems.length > 0"
              justify="center"
          >
            <v-col
                cols="12"
                md="6"
            >
              <ParticipantAdditionalItems
                  :items="allAdditionalItems"
                  :selected-items="selectedAdditionalItems"
                  @decreaseQuantity="decreaseAdditionalItemQuantity"
                  @increaseQuantity="increaseAdditionalItemQuantity"
                  :disabled="gettingAvailableTimeSlots || addingToCart"
              />
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col
              cols="12"
              md="6"
            >
              <ParticipantTotals
                :selected-items="selectedItems"
                :selected-additional-items="selectedAdditionalItems"
                :selected-slots="selectedSlots"
                :selected-date="selectedDate"
                :selected-activities="selectedActivities"
              />
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col
              cols="12"
              md="6"
            >
              <v-btn
                x-large
                block
                :dark="hasSelectedItems && hasSelectedSlots && !addingToCart && !colorIsLight(buttonColor)"
                :color="buttonColor"
                :disabled="!hasSelectedItems || !hasSelectedSlots || addingToCart"
                :loading="addingToCart"
                :class="buttonTextCaptialized ? 'text-capitalize' : ''"
                @click="addToCart"
                :rounded="mainButtonsRounded"
              >
                {{ $t('button.book') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-dialog
      v-if="showMoreSlotOptions"
      v-model="showMoreSlotOptions"
      max-width="500"
    >
      <OtherAvailableSlots
        :available-slots="combinedAvailableSlots"
        :selected-slots="selectedSlots"
        @selectSlot="selectSlot"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import ParticipantItems from "@/components/flow/participant/ParticipantItems"
import DateSelection from "@/components/DateSelection"
import ParticipantTotals from "@/components/flow/participant/ParticipantTotals"
import axios from "axios";
import AvailableSlot from "@/components/common/AvailableSlot";
import OtherAvailableSlots from "@/components/common/OtherAvailableSlots";
import jsesc from "jsesc";
import ParticipantAdditionalItems from "../components/flow/participant/ParticipantAdditionalItems";
import SingleTimeslotSelection from "@/components/common/SingleTimeslotSelection";
import Activities from "@/components/common/Activities";

export default {
  name: "ParticipantFlow",
  components: {
    Activities,
    SingleTimeslotSelection,
    ParticipantAdditionalItems,
    OtherAvailableSlots,
    AvailableSlot,
    ParticipantTotals,
    DateSelection,
    ParticipantItems
  },
  data() {
    return {
      addingToCart: false,
      selectedItems: [],
      selectedAdditionalItems: [],
      selectedSlots: [],
      selectedDate: null,
      gettingAvailableTimeSlots: false,
      allAvailableTimeSlots: null,
      showMoreSlotOptions: false,
      errorMessage: null,
      paxAvailableThreshold: 2,
      selectedActivities: []
    }
  },
  computed: {
    filteredActivities() {
      const filteredActivities = []

      const activities = this.$store.state.activities
      if(Array.isArray(activities) && activities.length > 0) {
        for(let activityIndex = 0; activityIndex < activities.length; activityIndex++) {
          const activity = activities[activityIndex]
          if(typeof activity !== 'undefined' && activity !== null && Array.isArray(activity.dates)) {
            if (Array.isArray(activity.dates)) {
              const dates = []
              const today = new Date().getTime()
              for(let dateIndex = 0; dateIndex < activity.dates.length; dateIndex++) {
                const activityDate = activity.dates[dateIndex]
                if(typeof activityDate !== 'undefined' && activityDate !== null) {
                  // Assuming activityDate is in seconds
                  const activityDateInMillis = activityDate * 1000

                  // If user has selected a date we want to make sure only to get dates within that time period
                  if(this.selectedDate) {
                    const start = new Date(this.selectedDate).setUTCHours(0,0,0,0)
                    const end = new Date(this.selectedDate).setUTCHours(23,59,59,999)

                    // No dates before selected date
                    if(start > activityDateInMillis) {
                      continue
                    }

                    // No dates after selected date
                    if(end < activityDateInMillis) {
                      continue
                    }
                  }

                  // Checking if the date is in the past
                  if(today > activityDateInMillis) {
                    continue
                  }

                  dates.push(activityDate)
                }
              }

              // If we have dates we replace the previous dates (because they could contain dates in the past)
              // and push the activity as something we want to display
              if(dates.length > 0) {
                activity.dates = dates
                filteredActivities.push(activity)
              }
            }
          }
        }
      }

      return filteredActivities
    },
    showSoldOutSlots() {
      return this.$store.state.showSoldOutSlots
    },
    showBlockedSlots() {
      return this.$store.state.showBlockedSlots
    },
    activities() {
      const activities = this.$store.state.activities
      if(Array.isArray(activities) && activities.length > 0) {
        return activities.slice().sort((a,b) => a.sorting_weight - b.sorting_weight)
      }
      return activities
    },
    buttonTextCaptialized() {
      return this.$store.state.mainButtonsCapitalized
    },
    buttonColor() {
      const storedColor = this.$store.state.mainButtonsColor
      if(storedColor) {
        return storedColor
      }
      return 'success'
    },
    items() {
      const items = this.$store.getters.items
      if(Array.isArray(items) && items.length > 0) {
        return items.slice().sort((a,b) => a.sorting_weight - b.sorting_weight)
      }
      return items
    },
    isInitialized() {
      return this.$store.getters.isInitialized
    },
    hasSelectedItems() {
      return Array.isArray(this.selectedItems) && this.selectedItems.length > 0
    },
    hasSelectedSlots() {
      return Array.isArray(this.selectedSlots) && this.selectedSlots.length > 0
    },
    sessionToken: {
      get() {
        return this.$store.state.sessionToken
      },
      set(value) {
        this.$store.state.sessionToken = value
      }
    },
    location() {
      return this.$store.state.location
    },
    combinedAvailableSlots() {
      let displaySlots = []
      if(typeof this.allAvailableTimeSlots !== 'undefined' && this.allAvailableTimeSlots !== null) {
        // Keep track of the keys
        const keys = Object.keys(this.allAvailableTimeSlots)

        // Add all the slots
        for(const key in this.allAvailableTimeSlots) {
          const slots = this.allAvailableTimeSlots[key]
          const item = this.selectedItems.find(i => i && i.sku === key)
          for(let slotIndex = 0; slotIndex < slots.length; slotIndex++) {
            const slot = slots[slotIndex]

            // We don't want to use the slot if the quantity isn't available
            if(!slot || !item) {
              continue
            }

            if(this.showSoldOutSlots && !slot.disabled) {
              slot.disabled = slot.availableCount < item.quantity
            }

			// We seem to be using isBlocked to determine whether to show sold out text or not, so this should at least temporarily fix related displaying issues
			if(this.showSoldOutSlots && slot.availableCount === 0) {
				slot.disabled = true
				slot.isBlocked = true
			}

            if(this.showBlockedSlots && !slot.disabled) {
              slot.disabled = slot.isBlocked === true
            }

            if(!this.showSoldOutSlots && slot.availableCount < item.quantity) {
              continue
            }

            if(!this.showBlockedSlots && slot.isBlocked === true) {
              continue
            }


            const index = displaySlots.findIndex(s => s && s.timestampInMillis === slot.timestampInMillis)
            slot.item = item.uuid
            slot.paxAvailableThreshold = this.paxAvailableThreshold

            if(index < 0) {
              const newSlot = JSON.parse(JSON.stringify(slot))
              slot.quantity = item.quantity
              if(slot.price) {
                slot.unitPrice = JSON.parse(JSON.stringify(slot.price))
                slot.price.amount = slot.price.amount * slot.quantity
                slot.totalPrice = JSON.parse(JSON.stringify(slot.price))
              }
              displaySlots.push({ ...newSlot, slotItems: [ key ], slots: [ slot ] })
            } else {
              displaySlots[index].slotItems.push(key)
              slot.quantity = item.quantity
              if(slot.price) {
                slot.unitPrice = JSON.parse(JSON.stringify(slot.price))
                slot.price.amount = slot.price.amount * slot.quantity
                slot.totalPrice = JSON.parse(JSON.stringify(slot.price))
              }
              displaySlots[index].slots.push(slot)

              // Handle if next slot is marked disabled and/or blocked
              if(slot.isBlocked) {
                displaySlots[index].isBlocked = slot.isBlocked
              }
              if(slot.disabled) {
                displaySlots[index].disabled = slot.disabled
              }


              if(slot.availableCount > 1 && slot.availableCount <= this.paxAvailableThreshold && slot.availableCount < displaySlots[index].availableCount) {
                displaySlots[index].availableCount = slot.availableCount
              }

              // Disable selection of slot if one item selection goes over availability selection of another
              // Might be the incorrect method of doing this
              // e.g. item #1 has been selected for 3 and has 4 available, item #2 has been selected 1 but has 2 available
              // we should allow booking of this, but currently we do not
              if(slot.availableCount < this.selectedItemsHighestQuantity) {
                displaySlots[index].disabled = true
              }
            }
          }
        }

        // Should make sure that we only get slots that selected items have
        displaySlots = displaySlots.filter(s => s && s.slotItems.length === keys.length)
      }

      return displaySlots
    },
    suggestedTimeSlot() {
      if(Array.isArray(this.selectedSlots) && this.selectedSlots.length > 0) {
        return this.selectedSlots[0]
      }

      // Here we can eventually add some better algorithm
      if(Array.isArray(this.combinedAvailableSlots) && this.combinedAvailableSlots.length > 0) {
        return this.combinedAvailableSlots[0]
      }

      return null
    },
    allAdditionalItems() {
      let additionalItems = []
      if(Array.isArray(this.items) && this.items.length > 0) {
        for(let itemIndex = 0; itemIndex < this.items.length; itemIndex++) {
          const item = this.items[itemIndex]
          if(item && Array.isArray(item.additionalItems)) {
            for(let addIndex = 0; addIndex < item.additionalItems.length; addIndex++) {
              const additionalItem = item.additionalItems[addIndex]
              if(additionalItems.some(i => i && i.uuid === additionalItem.uuid)) {
                continue
              }
              additionalItems.push(additionalItem)
            }
          }
        }
      }
      if(additionalItems.length > 0) {
        return additionalItems.slice().sort((a,b) => a.sorting_weight - b.sorting_weight)
      }
      return additionalItems
    },
    mainButtonsRounded() {
      return this.$store.state.mainButtonsRounded
    },
    useSlotPrices() {
      return this.$store.state.useSlotPrices
    },
    selectedItemsHighestQuantity() {
      if(Array.isArray(this.selectedItems)) {
        return this.selectedItems.reduce((sum, item) => {
          if(typeof item !== 'undefined' && item !== null) {
            if(typeof item.quantity !== 'undefined' && item.quantity !== null && item.quantity > sum) {
              return item.quantity
            }
          }
          return sum
        }, 0)
      }
      return 0
    },
    metaPixelCode() {
      return this.$store.getters.metaPixelCode
    }
  },
  methods: {
    colorIsLight(color) {
      return this.$store.getters.colorIsLight(color)
    },
    selectDate(date) {
      this.selectedDate = date
    },
    selectSlot(slot) {
      this.selectedSlots = [slot]
      this.showMoreSlotOptions = false
    },
    async addToCart() {

      if(!this.hasSelectedSlots) {
        return
      }

      this.addingToCart = true

      if(!this.sessionToken) {
        await this.fetchSessionToken()
      }

      const cartItems = []
      const dataLayerItems = []

      let languageOption = 'is'
      if(this.$i18n.locale === 'is' || this.$i18n.locale === 'en') {
        languageOption = this.$i18n.locale
      }

      /**
       * For every selected item we can be sure that the selected slots are available
       * If able to select multiple slots we must assume that the item quantity applies to all selected slots
       */
      for(let itemIndex = 0; itemIndex < this.selectedItems.length; itemIndex++) {
        const item = this.selectedItems[itemIndex]

        let itemPrice = {
          amount: 0,
          currency_code: 'ISK'
        }

        if(typeof item.price !== 'undefined' && item.price !== null) {
          if (typeof item.price.amount !== 'undefined' && item.price.amount > 0) {
            itemPrice = item.price
          }
        }
        if(typeof item.sale_price !== 'undefined' && item.sale_price !== null) {
          if(typeof item.sale_price.amount !== 'undefined' && item.sale_price.amount > 0) {
            itemPrice = item.sale_price
          }
        }

        if(item.type === 'event') {
          for(let slotIndex = 0; slotIndex < this.selectedSlots.length; slotIndex++) {
            const slot = this.selectedSlots[slotIndex]

            let values = {}
            values.timeSlots = []

            if(slot) {
              values.timeSlots.push({
                startsAtTime: Math.floor(slot.timestampInMillis / 1000),
                endsAtTime: Math.floor((slot.timestampInMillis + slot.paddingInMillis) / 1000),
                quantity: item.quantity
              })
            }

            if(this.useSlotPrices === true && Array.isArray(slot.slots) && slot.slots.length > 0) {
              const itemSlot = slot.slots.find(s => s && s.item === item.uuid)
              let price = null
              if(itemSlot) {
                price = itemSlot.unitPrice
                itemPrice = itemSlot.unitPrice
              }

              if(price && typeof price.amount !== 'undefined' && price.amount !== null) {
                // values.price = price
                // values.amount = price.amount
                // values.custom = price.amount
                values.custom_amount = price.amount
              }
            }

            cartItems.push({
              item: item.uuid,
              quantity: item.quantity,
              uiValues: jsesc(values, { 'json': true })
            })
          }
        } else {
          cartItems.push({
            item: item.uuid,
            quantity: item.quantity
          })
        }

        const dataLayerItem = {}
        dataLayerItem.item_name = item.title
        dataLayerItem.item_id = item.sku
        dataLayerItem.quantity = item.quantity

        if(itemPrice) {
          dataLayerItem.price = itemPrice.amount
          dataLayerItem.currency = itemPrice.currency_code
        }
        dataLayerItems.push(dataLayerItem)
      }

      for(let activityIndex = 0; activityIndex < this.selectedActivities.length; activityIndex++) {
        const activity = this.selectedActivities[activityIndex]

        if(activity) {
          let activityPrice = {
            amount: 0,
            currency_code: 'ISK'
          }

          if(typeof activity.price !== 'undefined' && activity.price !== null) {
            if (typeof activity.price.amount !== 'undefined' && activity.price.amount > 0) {
              activityPrice = activity.price
            }
          }
          if(typeof activity.sale_price !== 'undefined' && activity.sale_price !== null) {
            if(typeof activity.sale_price.amount !== 'undefined' && activity.sale_price.amount > 0) {
              activityPrice = activity.sale_price
            }
          }

          let values = {}
          values.timeSlots = []

          if(activity.type === 'event') {
            if(activity.selectedDate) {
              const startTime = activity.selectedDate.toString().length === 13 ? Math.floor(activity.selectedDate / 1000) : activity.selectedDate
              const durationInSeconds = activity.durationInMinutes * 60
              const endTime = activity.selectedDate.toString().length === 13 ? Math.floor((activity.selectedDate / 1000) + durationInSeconds) : (activity.selectedDate + durationInSeconds)
              values.timeSlots.push({
                startsAtTime: startTime,
                endsAtTime: endTime,
                quantity: activity.quantity
              })

              cartItems.push({
                item: activity.uuid,
                quantity: activity.quantity,
                uiValues: jsesc(values, { 'json': true })
              })
            }
          } else {
            cartItems.push({
              item: activity.uuid,
              quantity: activity.quantity
            })
          }

          dataLayerItems.push({
            item_name: activity.title,
            item_id: activity.sku,
            price: activityPrice.amount,
            currency: activityPrice.currency_code,
            quantity: activity.quantity
          })
        }
      }

      for(let additionalItemIndex = 0; additionalItemIndex < this.selectedAdditionalItems.length; additionalItemIndex++) {
        const item = this.selectedAdditionalItems[additionalItemIndex]

        let itemPrice = {
          amount: 0,
          currency_code: 'ISK'
        }

        if(typeof item.price !== 'undefined' && item.price !== null) {
          if (typeof item.price.amount !== 'undefined' && item.price.amount > 0) {
            itemPrice = item.price
          }
        }
        if(typeof item.sale_price !== 'undefined' && item.sale_price !== null) {
          if(typeof item.sale_price.amount !== 'undefined' && item.sale_price.amount > 0) {
            itemPrice = item.sale_price
          }
        }

        if(item.type === 'event') {
          // Additional items should not really handle event items but just in case we should leave this in
          for(let slotIndex = 0; slotIndex < this.selectedSlots.length; slotIndex++) {
            const slot = this.selectedSlots[slotIndex]

            let values = {}

            if(slot) {
              values.timeSlots = {
                startsAtTime: slot.timestampInMillis / 1000,
                endsAtTime: (slot.timestampInMillis + slot.paddingInMillis) / 1000,
                quantity: item.quantity
              }
            }

            cartItems.push({
              item: item.uuid,
              quantity: item.quantity,
              uiValues: jsesc(values, { 'json': true })
            })
          }
        } else {
          cartItems.push({
            item: item.uuid,
            quantity: item.quantity
          })
        }

        dataLayerItems.push({
          item_name: item.title,
          item_id: item.sku,
          price: itemPrice.amount,
          currency: itemPrice.currency_code,
          quantity: item.quantity
        })
      }

      // Sending cart items to top level window for tracking
      try {
        // Clear the previous ecommerce object
        window.parent.postMessage(JSON.stringify({ ecommerce: null }), '*')

        // Send the Ecommerce items
        window.parent.postMessage(JSON.stringify({
          event: 'add_to_cart',
          ecommerce: {
            items: dataLayerItems
          }
        }), '*')
      } catch (e) {
        // Do nothing if failed
      }
      if(this.metaPixelCode) {
        try{
          window.fbq('track', 'AddToCart', {
            content_type: 'event',
            num_items: dataLayerItems.reduce((acc, currItem) => { return acc + currItem.quantity }, 0),
            content_ids: dataLayerItems.map(item => item.item_id),
            contents: dataLayerItems.map(item => {
              return {
                id: item.item_id,
                name: item.item_name,
                quantity: item.quantity,
                price: item.price,
                currency: item.currency
              }
            }),
            currency: dataLayerItems[0].currency,
            value: dataLayerItems.reduce((acc, currItem) => { return acc + (currItem.price * currItem.quantity) }, 0)
          })
        } catch(e)  {
        //Do nothing if failed
        }
      }
      if(!this.inApp) {
        this.$store.dispatch('checkoutItems', {
          languageOption,
          cartItems,
          sessionToken: this.sessionToken
        }).then(order => {
          if(typeof order !== 'undefined' && order !== null && typeof order.uuid !== 'undefined' && order.uuid !== null) {
            // Only run if there is an actual response
            const channel = this.$store.state.channelUUID
            const organization = this.$store.state.organizationUUID
            const orderUUID = order.uuid

            try {
              // eslint-disable-next-line no-undef
              window.parent.postMessage('closeResizer', '*')
              window.parent.postMessage(JSON.stringify({ event: 'eventTracking', 'category': 'schedule', 'action': 'Continue to Checkout', 'label': 'schedule' }), '*')
            } catch (e) {
              // Do nothing
            }

            // Constructing the referrer url
            const referrerUrl = new URL(window.location.href)
            let referrer = ''
            if(this.$store.state.widgetUUID) {
              referrer = '&referrer=' + referrerUrl.origin + '/?uuid=' + this.$store.state.widgetUUID
            }

            // eslint-disable-next-line no-unused-vars
            let checkoutURL = 'https://checkout.salescloud.is/?time=' + Date.now() + '&dark=' + this.$vuetify.theme.dark + referrer + '#' + organization + '/' + channel + '/' + orderUUID + '/checkout' + '?sessionToken=' + this.sessionToken.session_token + '&language=' + languageOption
            document.location = checkoutURL
          } else {
            this.errorMessage = this.$t('errors.couldNotAddToCart') + ' - ' + this.$t('errors.fullyBooked')
            alert(this.errorMessage)
            this.addingToCart = false
          }
        }).catch(() => {
          this.errorMessage = this.$t('errors.couldNotAddToCart')
          alert(this.errorMessage)
          this.addingToCart = false
        }).finally(() => {})
      }
    },
    increaseQuantity(item) {
      const index = this.selectedItems.findIndex(i => i && item && i.uuid === item.uuid)
      if(index < 0) {
        this.selectedItems.push({ ...item, quantity: 1 })
      } else {
        if(typeof item.maximumOrderQuantity !== 'undefined' && item.maximumOrderQuantity > 0) {
          if(this.selectedItems[index].quantity + 1 <= item.maximumOrderQuantity) {
            this.selectedItems[index].quantity++
          }
        }
      }
    },
    decreaseQuantity(item) {
      const index = this.selectedItems.findIndex(i => i && item && i.uuid === item.uuid)
      if(index >= 0) {
        if(this.selectedItems[index].quantity - 1 <= 0) {
          this.selectedItems.splice(index, 1)
        } else {
          this.selectedItems[index].quantity--
        }
      }
    },
    increaseAdditionalItemQuantity(item) {
      const index = this.selectedAdditionalItems.findIndex(i => i && item && i.uuid === item.uuid)
      if(index < 0) {
        this.selectedAdditionalItems.push({ ...item, quantity: 1 })
      } else {
        if(typeof item.maximumOrderQuantity !== 'undefined' && item.maximumOrderQuantity > 0) {
          if(this.selectedAdditionalItems[index].quantity + 1 <= item.maximumOrderQuantity) {
            this.selectedAdditionalItems[index].quantity++
          }
        }
      }
    },
    decreaseAdditionalItemQuantity(item) {
      const index = this.selectedAdditionalItems.findIndex(i => i && item && i.uuid === item.uuid)
      if(index >= 0) {
        if(this.selectedAdditionalItems[index].quantity - 1 <= 0) {
          this.selectedAdditionalItems.splice(index, 1)
        } else {
          this.selectedAdditionalItems[index].quantity--
        }
      }
    },
    getAllAvailableTimeSlots() {
      this.gettingAvailableTimeSlots = true

      const payload = {}
      payload.items = this.selectedItems.filter(i => i && i.type === 'event')
      payload.startTimeInMillis = new Date(this.selectedDate).setUTCHours(0, 0, 0, 0)
      payload.endTimeInMillis = new Date(this.selectedDate).setUTCHours(23, 59, 59, 999)

      if(this.location && typeof this.location.intervalBetweenSlotsInMillis !== 'undefined' && this.location.intervalBetweenSlotsInMillis > 0) {
        const interval = Math.round(this.location.intervalBetweenSlotsInMillis / 60000)
        if(interval > 0) {
          payload.interval = interval
        }
      }
      this.$store.dispatch('getAvailableTimeSlotsForMultipleItems', payload).then(result => {
        this.allAvailableTimeSlots = result
      }).finally(() => {
        this.gettingAvailableTimeSlots = false
      })
    },
    fetchSessionToken() {
      const graphOptions = {
        headers: {
          'Content-Type': 'application/json'
        }
      };

      let url = 'https://graph.salescloud.is?';

      const graphBody = {
        query: 'mutation { newAnonymousSession { session_token, ttl } }'
      };

      return axios.post(url, graphBody, graphOptions).then((response) => {
        this.sessionToken = response.data.data.newAnonymousSession
        this.saveSessionToken(this.sessionToken)
        return response.data.data.newAnonymousSession
      }).catch(() => {
        return null
      })
    },
    saveSessionToken(sessionToken) {
      localStorage.setItem('sessionToken', JSON.stringify(sessionToken));
    },
    getSessionToken() {

      let sessionToken = null;

      try {
        sessionToken = JSON.parse(localStorage.getItem('sessionToken'));
      } catch (e) {
        localStorage.removeItem('sessionToken');
      }

      this.sessionToken = sessionToken
    },
    increaseActivityDateQuantity({ item, dateTimeInMillis }) {
      const index = this.selectedActivities.findIndex(activityItem => activityItem && activityItem.uuid === item.uuid && activityItem.selectedDate === dateTimeInMillis)
      if(index < 0) {
        this.selectedActivities.push({
          ...item,
          selectedDate: dateTimeInMillis,
          quantity: 1
        })
      } else {
        if(typeof item.maximumOrderQuantity !== 'undefined' && item.maximumOrderQuantity > 0) {
          // We do not want to increase quantity if quantity is already at maximumOrderQuantity
          if(this.selectedActivities[index].quantity >= item.maximumOrderQuantity) {
            return
          }
        }
        this.selectedActivities[index].quantity++
      }
    },
    decreaseActivityDateQuantity({ item, dateTimeInMillis }) {
      const index = this.selectedActivities.findIndex(activityItem => activityItem && activityItem.uuid === item.uuid && activityItem.selectedDate === dateTimeInMillis)
      if(index >= 0) {
        if(this.selectedActivities[index].quantity - 1 <= 0) {
          this.selectedActivities.splice(index, 1)
        } else {
          this.selectedActivities[index].quantity--
        }
      }
    }
  },
  watch: {
    selectedSlots(slots) {
		if(Array.isArray(slots) && slots.some(slot => slot && (slot.isBlocked || slot.disabled))) {
			this.selectedSlots = []
		}
    },
    selectedDate(value) {
      if(typeof value !== 'undefined' && value !== null) {
        if(Array.isArray(this.selectedItems) && this.selectedItems.length > 0) {
			// Handling all day event selection
            // NOTE: This will only handle all day event selection, not a pure dates selection, which is something we have to handle at some point
			if(this.selectedItems.every(item => item && item.isAllDayEvent)) {
				const startOfDay = new Date(value).setUTCHours(0, 0, 0, 0);
				const endOfDay = new Date(value).setUTCHours(23, 59, 59, 59);

				const dates = this.selectedItems.reduce((dates, item) => {
					if(Array.isArray(item.dates)) {
						for(let dateIndex = 0; dateIndex < item.dates.length; dateIndex++) {
							const date = item.dates[dateIndex];
							if(!date) {
								continue;
							}

							const start = Math.floor(startOfDay / 1000);
							const end = Math.ceil(endOfDay / 1000);
							if(date < start) {
								continue;
							}
							if(date > end) {
								continue;
							}
							if(dates.includes(date)) {
								continue;
							}

							dates.push(date)
						}
					}
					return dates
				}, [])

				for(let dIndex = 0; dIndex < dates.length; dIndex++) {
					const availableDate = dates[dIndex];
					if(!availableDate) {
						continue;
					}
					const startTimeInMillis = new Date(availableDate * 1000).getTime();
					const padding = startTimeInMillis - startOfDay;

					if(!Array.isArray(this.selectedSlots)) {
						this.selectedSlots = [];
					}

					this.selectedSlots.push({ timestampInMillis: startTimeInMillis, paddingInMillis: padding });
				}
				return
			}
          this.getAllAvailableTimeSlots()
          this.selectedSlots = []
        }

        this.selectedActivities = this.selectedActivities.filter(activity => {
          if(activity) {
            if(activity.selectedDate) {
              const tempDate = new Date(activity.selectedDate * 1000).setUTCHours(0, 0, 0, 0)
              return tempDate === value
            }
          }
          return false
        })
      } else {
        this.allAvailableTimeSlots = []
      }
    },
    selectedItems: {
      deep: true,
      handler(currentValue) {
        if(Array.isArray(currentValue) && currentValue.length > 0 && typeof this.selectedDate !== 'undefined' && this.selectedDate !== null) {
          this.getAllAvailableTimeSlots()
        } else {
          this.selectedDate = null
          this.allAvailableTimeSlots = []
        }
      }
    },
    combinedAvailableSlots(value) {
      if(!Array.isArray(value) || value.length < 1) {
        this.selectedSlots = []
      } else {
        if(Array.isArray(this.selectedSlots)) {
          const slots = []
          for(let slotIndex = 0; slotIndex < this.selectedSlots.length; slotIndex++) {
            const slot = this.selectedSlots[slotIndex]
            const foundSlot = value.find(s => s && s.timestampInMillis === slot.timestampInMillis)
            if(foundSlot) {
              slots.push(foundSlot)
            }
          }
          this.selectedSlots = slots
        }
      }
    }
  },
  mounted() {
    this.fetchSessionToken()
  }
}
</script>

<style scoped>

</style>
