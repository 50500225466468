/* eslint-disable */
<template>
	<div>
		<v-list-item>
			<v-list-item-avatar tile v-if="!inApp && showImages" size="72" style="border-radius: 4px !important;">
				<v-img :src="itemImage" class="grey" style="border-radius: inherit !important;"></v-img>
			</v-list-item-avatar>
			<v-list-item-content>
				<v-list-item-title class="text-break">
					{{ item.title }}
				</v-list-item-title>
				<v-list-item-subtitle v-if="description">{{ description }}</v-list-item-subtitle>
				<v-list-item-subtitle class="pt-3">
					<v-select v-if="hasPredefinedDates" hide-details dense :label="$t('selectDate')" outlined :items="predefinedDates" item-value="key" item-text="label" v-model="selectedPredefinedDate" />
				</v-list-item-subtitle>
			</v-list-item-content>
		</v-list-item>
		<v-divider></v-divider>
	</div>
</template>

<script>
/**
 * @property defaultQuantity
 */
export default {
	name: 'ItemListMultipleSelectedListItem',
	props: ['item', 'inCard', 'inCategory'],
	components: {
	},
	data: () => ({
		quantity: 1,
		addingToCart: false,
		processing: false,
		selectedVariation: null,
		showAvailability: false,
		selectedTimeSlots: [],
		selectedPredefinedDate: null,
		hasError: false,
		errorMessage: null,
		hasSlots: false,
	}),
	watch: {
		quantity(currentAmount, previousAmount) {
			if(currentAmount !== previousAmount) {
				this.hasError = false
				this.errorMessage = null
			}
		},
		selectedPredefinedDate(newDateInMillis) {
			this.selectedTimeSlots = [{
				startTimeInMillis: newDateInMillis,
				endTimeInMillis: newDateInMillis
			}]
			this.hasSlots = true

			// eslint-disable-next-line
			this.item.selectedTimeSlots = this.selectedTimeSlots
			// eslint-disable-next-line
			this.item.hasSlots = true
		}
	},
	mounted() {
		if(this.hasVariationsAffectingMeasure) {
			this.quantity = 0
		}

		if(this.selectedPredefinedDate === null && this.predefinedDates.length > 0) {
			this.selectedPredefinedDate = this.predefinedDates[0].key
		}
	},
	computed: {
    showImages() {
      return this.$store.state.showImages
    },
		disabled() {

			if(this.item.availability !== null && this.item.availability !== undefined && this.item.availability.current < 1) {
				return this.item.availability.current < 1
			}

			return false
		},
		inApp() {
			return this.$store.getters.inApp
		},
		predefinedDates() {
			const parsedDates = []
			const now = Date.now()

			if(this.item.dates !== null && this.item.dates !== undefined && this.item.dates.length > 0) {

				for(let i in this.item.dates) {
					const dateInMillis = this.item.dates[i] * 1000

					if(dateInMillis < now) {
						continue
					}

					parsedDates.push({
						key: dateInMillis,
						label: this.$moment.utc(dateInMillis).format('DD/MM/YYYY HH:mm')
					})

				}

			}

			return parsedDates
		},
		hasPredefinedDates() {

			if(this.item.dates !== null && this.item.dates !== undefined && this.item.dates.length > 0) {
				return true
			}

			return false
		},
		totalPrice() {

			const totalPrice = JSON.parse(JSON.stringify(this.item.price))

      if(Array.isArray(this.item.variations)) {
        for(let i = 0; i < this.item.variations.length; i++) {
          const variation = this.item.variations[i]
          const quantity = variation.value !== undefined ? variation.value : 0
          totalPrice.amount = totalPrice.amount + (variation.price.amount * quantity)
        }
      }

			return totalPrice
		},
		variationsByAttributes() {
			const attributes = {}

      if(typeof this.item !== 'undefined' && this.item !== null && Array.isArray(this.item.variations)) {
        for(let i = 0; i < this.item.variations.length; i++) {
          const attribute = this.item.variations[i].attribute
            if(typeof attribute === 'undefined' || attribute === null) {
              continue
            }
          attributes[attribute.uuid] = attribute
        }

        const keys = Object.keys(attributes)

        const attributesArray = []

        for(let i in keys) {
          const key = keys[i]
          attributesArray.push(attributes[key])
        }
      }

			return attributes
		},
		cartQuantity() {

			if(this.hasVariationsAffectingMeasure) {
				return this.variationQuantity
			}

			return this.quantity
		},
		variationQuantity() {

			let quantity = 0

			if(this.hasVariationsAffectingMeasure) {
				for(let i in this.item.variations) {
					const variation = this.item.variations[i]

					if(variation.value !== null && variation.value !== undefined) {
						quantity += variation.value
					}
				}
			}

			return quantity
		},
		hasVariationsAffectingMeasure() {
			return this.item.variations !== null && this.item.variations.length > 0
		},
		unitOfMeasure() {
			let unitOfMeasure = 'people'

			if(this.item.unitOfMeasure !== null && this.item.unitOfMeasure !== undefined) {
				unitOfMeasure = this.item.unitOfMeasure
			}

			return unitOfMeasure
		},
		unitOfMeasureText() {
			return this.$t(`amountsUnitOfMeasure.${this.unitOfMeasure}`)
		},
		cartText() {
			return this.$t('amount') + ' ' + this.unitOfMeasureText
		},
		maximumOrderQuantity() {
			let maximum = 20

			if(this.item.maximumOrderQuantity !== null && this.item.maximumOrderQuantity !== undefined) {
				maximum = this.item.maximumOrderQuantity
			}

			return maximum
		},
		minimumOrderQuantity() {
			let minimum = 2

			if(this.item.minimumOrderQuantity !== null && this.item.minimumOrderQuantity !== undefined) {
				minimum = this.item.minimumOrderQuantity
			}

			return minimum
		},
		maximumGroupedQuantity() {
			let maximumGroupedQuantity = 4

			if(this.item.maximumGroupedQuantity !== null && this.item.maximumGroupedQuantity !== undefined) {
				maximumGroupedQuantity = this.item.maximumGroupedQuantity
			}

			return maximumGroupedQuantity
		},
		itemImage() {
			if(this.item && this.item.images && this.item.images.length) {
				return this.item.images[0].file.sources.large
			} else {
				return ''
			}
		},
		sessionToken: {
			get() {
				return this.$store.state.sessionToken
			},
			set(value) {
				this.$store.state.sessionToken = value
			}
		},
		quantityOptions() {

			const options = []

			for(let i = this.minimumOrderQuantity; i <= this.maximumOrderQuantity; i++) {

				if(this.hasPredefinedDates && this.item.availability.current < i) {
					continue
				}

				options.push({
					key: i,
					text: i
				})
			}


			return options
		},
		description() {

			if(this.item.description === undefined || this.item.description === null || this.item.description.value === null || this.item.description.value === undefined) {
				return null
			}

			return this.item.description.value
		},
		channelUUID() {
			return this.$store.state.channelUUID
		},
		requiredSlots() {
			if(!this.cartQuantity) {
				return 0
			}
			if(!this.maximumGroupedQuantity) {
				return this.cartQuantity
			}
			return Math.ceil(this.cartQuantity / this.maximumGroupedQuantity)
		},
		missingSlots() {
			return this.requiredSlots - this.selectedTimeSlots.length
		},
		organizationUUID() {
			return this.$store.state.organizationUUID
		},
		primaryColor() {
			return this.$store.getters.primaryColor
		},
		textButtonColor() {
			if(this.colorIsLight(this.primaryColor)) {
				return 'black--text'
			} else {
				return 'white--text'
			}
		},
		organizationIsSmarabio() {
			return this.$store.getters.organizationIsSmarabio
		},
		categoryIsSmarabioNamskeid() {
			return this.$store.getters.categoryIsNamskeid
		},
		itemIsSmarabioNamskeid() {
			return this.$store.getters.itemIsNamskeid(this.item)
		}
	},
	methods: {
		canIncrementVariation(variation) {
			return (this.cartQuantity < this.item.maximumOrderQuantity && variation.value < this.item.maximumOrderQuantity)
		},
		canDecrementVariation(variation) {
			return variation.value > 0
		},
		updateHasSlots(value) {
			this.hasSlots = value
		},
		indexOfVariation(variation) {

			for(let i = 0; i < this.item.variations.length; i++) {
				const variationInList = this.item.variations[i]
				if(variationInList.uuid === variation.uuid) {
					return i
				}
			}

			return 0
		},
		attributesByVariation(attribute) {
			if(this.item.variations === null || this.item.variations === undefined) {
				return []
			}

			return this.item.variations.filter(variation => variation.attribute.uuid === attribute.uuid)
		},
		decrementVariationQuantity(index) {
			// eslint-disable-next-line
			this.item.variations[index].value--;
		},
		incrementVariationQuantity(index) {
			// eslint-disable-next-line
			this.item.variations[index].value++;
		},
		variationQuantityOptions(variation) {
			const options = []

			const remainingQuantity = this.maximumOrderQuantity - this.cartQuantity

			for(let i = 0; i <= this.maximumOrderQuantity; i++) {
				let isBlocked = false

				if(remainingQuantity < i && variation.value < i) {
					isBlocked = true
				}

				options.push({
					key: i,
					text: i,
					disabled: isBlocked
				})
			}

			return options
		},
		timeSlotsSelected(timeSlots) {
			if(this.hasError) {
				this.hasError = false
				this.errorMessage = null
			}
			this.selectedTimeSlots = timeSlots
		},
		checkAvailability() {

			this.processing = true
			this.fetchSessionToken().then(() => {
				this.showAvailability = true
				this.processing = false
			})

		},
		colorIsLight(color) {
			return this.$store.getters.colorIsLight(color)
		},
	}
}
</script>
