<template>
  <div>
    <v-list-item class="pa-0">
      <v-list-item-content class="py-0">
        <v-list-item-title style="font-weight: 500; font-size: 20px;">
          {{ itemTitle }}
        </v-list-item-title>
        <v-list-item-subtitle v-if="itemHasPrice">
          <span>
            <span
              v-if="formattedItemTotalPrice.charAt(0) !== '0'"
              style="font-weight: 700;"
            >
              {{ formattedItemTotalPrice }}
            </span>
            <span :class="itemQuantity > 0 ? 'text--disabled' : ''">
              <span v-if="itemQuantity > 0">
                {{ itemQuantity }} x
              </span>
              <span>
                {{ formattedItemUnitPrice }}
              </span>
            </span>
          </span>
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action v-if="showCheckbox || showQuantitySelection">
        <v-checkbox
          v-if="showCheckbox"
          v-model="selectAdditionalItem"
          :color="positivePrimaryColor"
        />
        <SimplifiedQuantitySelection
          v-else-if="showQuantitySelection"
          :selected-quantity="itemQuantity"
          @decreaseQuantity="decreaseItemQuantity"
          @increaseQuantity="increaseItemQuantity"
          no-padding
        />
      </v-list-item-action>
    </v-list-item>
    <div v-if="itemVariationAttributes.length > 0">
      <template v-for="attribute in itemVariationAttributes">
        <ItemVariationAttribute
          :key="attribute.uuid"
          :attribute="attribute"
          :variations="itemVariations"
          :show-attribute-name="itemVariationAttributes.length > 1"
          :selected-variations="selectedItemVariations"
          :item="item"
        />
      </template>
    </div>
  </div>
</template>

<script>
import SimplifiedQuantitySelection from "@/components/common/SimplifiedQuantitySelection"
import ItemVariationAttribute from "@/components/item/ItemVariationAttribute";

export default {
  name: "AdditionalItem",
  components: {
    ItemVariationAttribute,
    SimplifiedQuantitySelection
  },
  props: {
    item: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      selectedVariations: []
    }
  },
  computed: {
    itemHasPrice() {
      if(typeof this.item === 'undefined' || this.item === null) {
        return false
      }
      if(typeof this.item.price === 'undefined' || this.item.price === null) {
        return false
      }
      if(isNaN(Number(this.item.price.amount))) {
        return false
      }
      return Number(this.item.price.amount) > 0
    },
    formattedItemTotalPrice() {
      return this.$store.getters.getItemCurrencyDisplay(this.itemTotalPrice)
    },
    itemTotalPrice() {
      return this.$store.getters.getItemCurrentPrice(this.item, this.itemQuantity, this.selectedVariations)
    },
    formattedItemUnitPrice() {
      return this.$store.getters.getItemCurrencyDisplay(this.itemUnitPrice)
    },
    itemUnitPrice() {
      return this.$store.getters.getItemCurrentPrice(this.item, 1, this.item.default_variations)
    },
    itemTitle() {
      return this.item.title
    },
    itemDefaultQuantity() {
      if(typeof this.item === 'undefined' || this.item === null) {
        return 0
      }
      return this.item.defaultQuantity
    },
    itemMaximumOrderQuantity() {
      if(typeof this.item === 'undefined' || this.item === null) {
        return 100
      }
      return this.item.maximumOrderQuantity
    },
    itemMinimumOrderQuantity() {
      if(typeof this.item === 'undefined' || this.item === null) {
        return 0
      }
      return this.item.minimumOrderQuantity
    },
    showCheckbox() {
      return (this.itemMaximumOrderQuantity === 1 && this.itemMaximumOrderQuantity > this.itemMinimumOrderQuantity) || (this.itemMinimumOrderQuantity === this.itemMaximumOrderQuantity && this.itemVariationAttributes.length <= 0)
    },
    showQuantitySelection() {
      return (this.itemMinimumOrderQuantity < this.itemMaximumOrderQuantity)
    },
    itemVariations() {
      if(typeof this.item === 'undefined' || this.item === null) {
        return []
      }
      if(!Array.isArray(this.item.variations)) {
        return []
      }
      return this.item.variations
    },
    selectedAdditionalItems: {
      get() {
        return this.$store.state.selectedAdditionalItems
      },
      set(items) {
        this.$store.commit('updateSelectedAdditionalItems', items)
      }
    },
    itemQuantity() {
      if(typeof this.item === 'undefined' || this.item === null) {
        return 0
      }

      const index = this.selectedAdditionalItems.findIndex(i => i && i.uuid === this.item.uuid)
      if(index < 0) {
        return 0
      }

      return this.selectedAdditionalItems[index].quantity
    },
    itemVariationAttributes() {
      return this.$store.getters.getVariationAttributes(this.itemVariations)
    },
    itemDefaultVariations() {
      if(typeof this.item === 'undefined' || this.item === null) {
        return []
      }
      if(!Array.isArray(this.item.default_variations)) {
        return []
      }
      return this.item.default_variations
    },
    selectedItemVariations() {
      if(typeof this.item === 'undefined' || this.item === null) {
        return []
      }
      if(!Array.isArray(this.selectedAdditionalItems)) {
        return []
      }

      const itemIndex = this.selectedAdditionalItems.findIndex(item => item && item.uuid === this.item.uuid)
      if(itemIndex < 0) {
        return []
      }
      if(!Array.isArray(this.selectedAdditionalItems[itemIndex].selectedVariations)) {
        return []
      }

      return this.selectedAdditionalItems[itemIndex].selectedVariations
    },
    selectAdditionalItem: {
      get() {
        return this.itemQuantity > 0
      },
      set(value) {
        let quantityToUse = 1
        if(this.itemMinimumOrderQuantity === this.itemMaximumOrderQuantity) {
          quantityToUse = this.itemMaximumOrderQuantity
        }

        if(value) {
          this.increaseItemQuantity(quantityToUse)
        } else {
          this.decreaseItemQuantity(quantityToUse)
        }
      }
    },
    primaryColor() {
      return this.$store.getters.primaryColor;
    },
    positivePrimaryColor() {
      if (this.$store.getters.colorIsCompleteGrey(this.primaryColor)) {
        return "success";
      }
      return this.primaryColor;
    },
  },
  methods: {
    increaseItemQuantity(quantity = 1) {
      if(typeof this.item === 'undefined' || this.item === null) {
        return
      }

      const index = this.selectedAdditionalItems.findIndex(i => i && i.uuid === this.item.uuid)
      if(index < 0) {
        this.selectedAdditionalItems.push({ ...this.item, quantity: quantity })
      } else {
        if(this.itemMaximumOrderQuantity > 0) {
          if(this.selectedAdditionalItems[index].quantity + 1 <= this.itemMaximumOrderQuantity) {
            this.selectedAdditionalItems[index].quantity++
          }
        }
      }
    },
    decreaseItemQuantity(quantity = 1) {
      if(typeof this.item === 'undefined' || this.item === null) {
        return
      }

      const index = this.selectedAdditionalItems.findIndex(i => i && i.uuid === this.item.uuid)
      if(index >= 0) {
        if(this.selectedAdditionalItems[index].quantity - quantity <= 0) {
          this.selectedAdditionalItems.splice(index, 1)
        } else {
          this.selectedAdditionalItems[index].quantity--
        }
      }
    },
    setAdditionalItemWithDefaultVariations() {
      if(typeof this.item === 'undefined' || this.item === null) {
        return
      }
      if(!Array.isArray(this.selectedAdditionalItems)) {
        return
      }

      this.selectAdditionalItem = true
      const itemIndex = this.selectedAdditionalItems.findIndex(item => item && item.uuid === this.item.uuid)
      if(itemIndex < 0) {
        return
      }

      this.selectedAdditionalItems[itemIndex].selectedVariations = this.itemDefaultVariations
    }
  },
  mounted() {
    if(this.selectedItemVariations.length === 0 && this.itemDefaultVariations.length > 0) {
      this.setAdditionalItemWithDefaultVariations()
    }
  }
}
</script>

<style scoped>

</style>