<template>
	<v-container>
		<v-img class="mb-5" style="margin: 0 auto;" max-width="100px" :src="logoSrc" v-if="!isInIframe && logoSrc !== null" />
		<v-row justify="center">
			<v-col cols="12" sm="6" lg="4" v-for="category in itemCategories" :key="category.uuid">
				<CategoryListItem :category="category" />
			</v-col>
		</v-row>
		<v-row justify="center" align="center">
			<v-col v-if="canShowCancelButton" :class="canShowCancelButton && canShowChangeButton ? 'col-12 col-sm-6 col-lg-4' : 'col-12 col-sm-8'">
				<v-btn to="/cancel" :class="colorIsLight(accentColor) ? 'black--text' : 'white--text'" large block depressed dark :color="accentColor">{{$t('button.cancel')}}</v-btn>
			</v-col>
			<v-col v-if="canShowChangeButton" :class="canShowChangeButton && canShowCancelButton ? 'col-12 col-sm-6 col-lg-4' : 'col-12 col-sm-8'">
				<v-btn to="/change" :class="colorIsLight(accentColor) ? 'black--text' : 'white--text'" large block depressed dark :color="accentColor">{{$t('button.change')}}</v-btn>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>

import CategoryListItem from "@/components/CategoryListItem";
export default {
	name: "CategoriesList",
	components: {
		CategoryListItem
	},
	computed: {
		logoSrc() {
			return this.$store.getters.logoSrc
		},
		isInIframe() {
			return this.$store.getters.isInIframe
		},
		itemCategories() {
			return this.$store.getters.itemCategories
		},
		canShowCancelButton() {
			return this.$store.getters.canCancelBooking
		},
		canShowChangeButton() {
			return this.$store.getters.canChangeBooking
		},
		accentColor() {
			return this.$store.getters.accentColor
		}
	},
	methods: {
		colorIsLight(color) {
			return this.$store.getters.colorIsLight(color)
		}
	}
}
</script>

<style scoped>

</style>
