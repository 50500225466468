<template>
  <v-expansion-panels v-model="expanded">
    <v-expansion-panel>
      <v-expansion-panel-header
        color="grey lighten-4"
        disable-icon-rotate
      >
        <v-container fluid class="pa-0">
          <v-row
            dense
            align="center"
            justify="space-between"
            class="pr-2"
          >
            <v-col
              cols="12"
              sm="auto"
            >
              {{ activityTitle }}
            </v-col>
            <v-col
              cols="12"
              sm="auto"
            >
              <span class="caption">
                  <span v-if="activity.durationInMinutes">
                    {{ activity.durationInMinutes }} {{ $t('minutes') }}
                  </span>
                  <span
                    v-if="activity.durationInMinutes && activity.price"
                    class="body-1"
                  >
                    |
                  </span>
                  <span v-if="activity.price">
                    <span v-if="activity.price.amount > 0">
                      {{ activity.price | formatPrice }}
                    </span>
                    <span v-else>
                      {{ $t('price.free') }}
                    </span>
                  </span>
                </span>
            </v-col>
          </v-row>
        </v-container>
        <span style="font-size: 16px;" v-if="false">
          {{ activityTitle }}
        </span>
        <template v-slot:actions>
          <v-icon
            :class="expanded === 0 ? 'rotate-icon' : ''"
            size="30"
            style="margin-right: -12px;"
          >
            mdi-chevron-right
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="no-content-x-padding">
        <v-img
          v-if="activityImage"
          :lazy-src="lazyActivityImage"
          :src="activityImage"
          height="100"
        />
        <v-container
          fluid
          class="px-5"
        >
          <v-row>
            <v-col cols="12">
              <div
                style="font-size: 12px;"
                class="py-2"
                v-html="activityDescription"
              />
            </v-col>
            <v-col
              cols="12"
              class="py-0"
            >
              <div style="font-size: 12px; font-weight: 700;">
                {{ $t('pickYourTime') }}
              </div>
            </v-col>
            <v-col
              class="pt-0"
              cols="12"
            >
              <template v-for="(itemHours, itemDate) in dates">
                <div
                  v-if="itemHours.length > 0"
                  :key="itemDate"
                >
                  <div class="py-2">
                    <v-divider />
                  </div>
                  <template v-for="(hour, index) in itemHours">
                    <ActivityDate
                      :key="hour"
                      :index="index"
                      :item-date="hour"
                      :selected-activities="selectedActivities"
                      :item="activity"
                      @decreaseQuantity="decreaseActivityDateQuantity"
                      @increaseQuantity="increaseActivityDateQuantity"
                    />
                  </template>
                </div>
              </template>
            </v-col>
            <v-col
              v-if="Array.isArray(activity.dates) && length < activity.dates.length"
              cols="12"
            >
              <div
                style="font-size: 10px; font-weight: 500; cursor: pointer; text-decoration: underline;"
                @click="loadMoreActivityDates"
              >
                {{ $t('seeMoreDates') }}
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import ActivityDate from "@/components/common/ActivityDate"

export default {
  name: "ActivityItem",
  components: {
    ActivityDate
  },
  props: {
    activity: {
      type: Object,
      default: () => ({})
    },
    selectedDate: {
      type: Number,
      default: null
    },
    selectedActivities: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      expanded: null,
      length: 4,
      more: 4
    }
  },
  computed: {
    dates() {
      // Sorts item dates into an object where they key is the start of the day
      // and the value is an array of hours within the date, all stored in millis
      const dates = {}
      if(typeof this.activity !== 'undefined' && this.activity !== null) {
        if(Array.isArray(this.activity.dates) && this.activity.dates.length > 0) {
          for(let dateIndex = 0; dateIndex < this.activity.dates.length; dateIndex++) {
            if(dateIndex >= this.length) {
              continue
            }
            const date = this.activity.dates[dateIndex]
            const startOfDay = new Date(date * 1000).setUTCHours(0, 0, 0, 0)
            if(typeof this.selectedDate !== 'undefined' && this.selectedDate !== null && this.selectedDate > 0) {
              if(this.selectedDate !== startOfDay) {
                continue
              }
            }
            if(!dates[startOfDay]) {
              dates[startOfDay] = []
            }
            dates[startOfDay].push(date)
          }
        }
      }
      return dates
    },
    activityTitle() {
      if(typeof this.activity !== 'undefined' && this.activity !== null) {
        if(this.$te('item:title:' + this.activity.uuid)) {
          return this.$t('item:title:' + this.activity.uuid)
        }
        return this.activity.title
      }
      return ''
    },
    activityDescription() {
      if(typeof this.activity !== 'undefined' && this.activity !== null) {
        if(this.$te('item:description:' + this.activity.uuid)) {
          return this.$t('item:description:' + this.activity.uuid)
        }
        if(typeof this.activity.description !== 'undefined' && this.activity.description !== null) {
          return this.activity.description.value
        }
      }
      return null
    },
    activityImage() {
      if(typeof this.activity !== 'undefined' && this.activity !== null) {
        if(Array.isArray(this.activity.images) && this.activity.images.length > 0) {
          const firstImage = this.activity.images[0]
          if(typeof firstImage.file !== 'undefined' && firstImage.file !== null) {
            if(typeof firstImage.file.sources !== 'undefined' && firstImage.file.sources !== null) {
              return firstImage.file.sources.large
            }
          }
        }
      }
      return null
    },
    lazyActivityImage() {
      if(typeof this.activity !== 'undefined' && this.activity !== null) {
        if(Array.isArray(this.activity.images) && this.activity.images.length > 0) {
          const firstImage = this.activity.images[0]
          if(typeof firstImage.file !== 'undefined' && firstImage.file !== null) {
            if(typeof firstImage.file.sources !== 'undefined' && firstImage.file.sources !== null) {
              return firstImage.file.sources.thumbnail
            }
          }
        }
      }
      return null
    }
  },
  methods: {
    increaseActivityDateQuantity(dateTimeInMillis) {
      this.$emit('increaseActivityDateQuantity', { item: this.activity, dateTimeInMillis })
    },
    decreaseActivityDateQuantity(dateTimeInMillis) {
      this.$emit('decreaseActivityDateQuantity', { item: this.activity, dateTimeInMillis })
    },
    loadMoreActivityDates() {
      this.length = this.length + this.more
    }
  }
}
</script>

<style scoped>
.no-content-x-padding >>> .v-expansion-panel-content__wrap {
  padding-left: 0;
  padding-right: 0;
}
.rotate-icon {
  transform: rotate(-90deg);
}
</style>