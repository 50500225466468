<template>
  <v-card>
    <v-container fluid>
      <v-row justify="center">
        <template v-for="slot in availableSlots">
          <v-col
            cols="auto"
            class="grow"
            :key="slot.label"
          >
            <AvailableSlot
              :available-slot="slot"
              :selected-slots="selectedSlots"
              v-on="$listeners"
            />
          </v-col>
        </template>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import AvailableSlot from "@/components/common/AvailableSlot"

export default {
  name: "OtherAvailableSlots",
  components: {
    AvailableSlot
  },
  props: {
    availableSlots: {
      type: Array,
      default: () => []
    },
    selectedSlots: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style scoped>

</style>