var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.organizationIsBullseye)?_c('h3',[_vm._v(_vm._s(_vm.$t("whenDoYouWantToCome")))]):_vm._e(),(_vm.organizationIsBullseye)?_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("whichDayDoYouWantToCome"))+" ")]):_vm._e(),_c('vc-calendar',{staticClass:"mt-3",attrs:{"title-position":"left","attributes":_vm.attributes,"min-date":_vm.minDate,"max-date":_vm.maxDate,"disabled-dates":_vm.disabledDates,"is-expanded":"","timezone":"UTC","value":_vm.selectedDate},on:{"dayclick":_vm.dayClick}}),(_vm.maxAheadBookingInDays > 0 && !_vm.organizationIsBullseye)?_c('div',{staticClass:"caption mt-5"},[_vm._v(" "+_vm._s(_vm.$t("maxAheadDays", { maxAheadBookingInDays: _vm.maxAheadBookingInDays }))+" ")]):(_vm.maxAheadBookingInDays > 0 && _vm.organizationIsBullseye)?_c('div',{staticClass:"caption mt-5"},[_vm._v(" "+_vm._s(_vm.$t("maxAheadDaysBullseye", { maxAheadBookingInDays: _vm.maxAheadBookingInDays }))+" ")]):_c('div',{staticClass:"caption mt-5"},[_vm._v(" "+_vm._s(_vm.$t("maxBookableDate", { maxDateFormatted: _vm.maxDateFormatted }))+" ")]),(_vm.cartQuantity && !_vm.hideExtraInfo && !_vm.organizationIsBullseye)?_c('div',{staticClass:"caption mt-3"},[_vm._v(" "+_vm._s(_vm.cartQuantity)+" "+_vm._s(_vm.$tc(_vm.unitOfMeasure, this.maximumGroupedQuantity))+" - "+_vm._s(_vm.selectedQuantity)+" "+_vm._s(_vm.$t("slotsSelected"))+" ")]):_vm._e(),(_vm.cartQuantity && !_vm.hideExtraInfo && _vm.organizationIsBullseye)?_c('div',{staticClass:"caption mt-3"},[_vm._v(" "+_vm._s(_vm.$t("possibleToChangeBookingWith24HoursNotice"))+" ")]):_vm._e(),_c('v-divider',{staticClass:"mt-5 mb-5"}),(false)?_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.errorAlert),expression:"errorAlert"}],attrs:{"type":"error"}},[_vm._v(_vm._s(_vm.errorAlert))]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
			typeof _vm.selectedDate !== 'undefined' && _vm.selectedDate !== null
		),expression:"\n\t\t\ttypeof selectedDate !== 'undefined' && selectedDate !== null\n\t\t"}]},[(!_vm.organizationIsBullseye)?_c('h3',[_vm._v(" "+_vm._s(_vm.$t("selectAvailableSlot"))+" ")]):_vm._e(),(_vm.organizationIsBullseye)?_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("whenDoYouWantToStart"))+" ")]):_vm._e(),(_vm.showTicketsPerSlot && !_vm.hideExtraInfo)?_c('div',{staticClass:"subTitle-1"},[_vm._v(" "+_vm._s(_vm.$t("eachSlotIsForUpTo"))+" "+_vm._s(_vm.maximumGroupedQuantity)+" "+_vm._s(_vm.$tc(_vm.unitOfMeasure, this.maximumGroupedQuantity))+" ")]):_vm._e(),(_vm.slotGroups !== null && _vm.slotGroups.length > 0)?_c('div',[_vm._l((_vm.slotGroups),function(group,index){return [(
						_vm.slots[group].length > 0 && !_vm.organizationIsBullseye
					)?_c('v-subheader',{key:index + '-div',staticClass:"text-uppercase px-0"},[_vm._v(" "+_vm._s(_vm.$t("kl"))+" "+_vm._s(group)+" ")]):_vm._e(),_c('v-row',{key:'row-' + index,attrs:{"dense":""}},[_vm._l((_vm.slots[group]),function(slot,index2){return [_c('v-col',{key:'slot-' + index2,staticClass:"pa-0",attrs:{"cols":"auto"}},[(slot.availableCount < 1)?_c('v-btn',{staticClass:"mr-4 mb-4",attrs:{"disabled":""}},[_vm._v(_vm._s(slot.text)+" ("+_vm._s(_vm.$t("soldOut"))+")")]):(slot.reservedForCurrentSession)?_c('v-btn',{key:index2 + slot.text,staticClass:"mr-4 mb-4",attrs:{"loading":slot.processing,"disabled":slot.processing,"color":"green"},on:{"click":function($event){return _vm.deselectSlot(slot)}}},[_vm._v(_vm._s(slot.text))]):(_vm.slotIsDisabled(slot))?_c('v-btn',{key:slot.text +
										'-' +
										index2 +
										'-' +
										'disabled',staticClass:"mr-4 mb-4",attrs:{"disabled":""}},[_vm._v(_vm._s(slot.text)+" ("+_vm._s(slot.availableCount)+" "+_vm._s(_vm.$t("remaining"))+")")]):_c('v-btn',{key:index2 + slot.text,staticClass:"mr-4 mb-4",attrs:{"loading":slot.processing,"disabled":slot.processing},on:{"click":function($event){return _vm.selectSlot(slot)}}},[_vm._v(_vm._s(slot.text))])],1)]})],2),_c('div',{key:group + '-err',staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.errorMessage[group])+" ")])]})],2):_vm._e(),(_vm.slotGroups !== null && _vm.slotGroups.length === 0)?_c('div',{staticClass:"mt-2 mb-2"},[_c('v-alert',{attrs:{"icon":"mdi-lightbulb","prominent":"","text":"","type":"info"}},[_vm._v(_vm._s(_vm.$t("errors.selectedDateFullyBooked")))])],1):_vm._e(),(
				_vm.slotGroups === null &&
					_vm.selectedDate &&
					!_vm.gettingAvailableTimeSlots
			)?_c('div',{staticClass:"my-3"},[_c('v-alert',{attrs:{"icon":"mdi-lightbulb","prominent":"","text":"","type":"info"}},[_vm._v(" "+_vm._s(_vm.$t("errors.selectedDateBookingClosed"))+" ")])],1):_vm._e(),(
				(_vm.slotGroups === null && !_vm.selectedDate) ||
					_vm.gettingAvailableTimeSlots
			)?_c('div',{staticClass:"mt-3 mb-3"},[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.selectedDate),expression:"!selectedDate"}]},[_c('h4',{staticClass:"pb-3 text-center"},[_vm._v(" "+_vm._s(_vm.$t("selectDateToSeeAvailability"))+" ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }