<template>
  <div>
    <div class="text-h5 text--primary pt-3">
      {{ categoryName }}
    </div>
    <div v-for="item in itemsInCategory" :key="item.uuid">
      <AdditionalItem :item="item" />
    </div>
  </div>
</template>

<script>
import AdditionalItem from "@/components/item/AdditionalItem";
export default {
  name: "ItemCategory",
  components: {AdditionalItem},
  props: {
    category: {
      type: Object,
      default: () => null
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    itemsInCategory() {
      return this.$store.getters.getItemsInCategory(this.category, this.items)
    },
    categoryName() {
      if(typeof this.category === 'undefined' || this.category === null) {
        return ''
      }

      const key = 'category:name:' + this.category.uuid
      if(!this.$te(key)) {
        return this.category.name
      }

      return this.$t(key)
    },
    categoryDescription() {
      return ''
    }
  }
}
</script>

<style scoped>

</style>