<template>
  <v-card>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <span style="font-weight: 300;">
            {{ $t('Order summary') }}
          </span>
        </v-col>
        <v-col
          v-if="(!Array.isArray(selectedItems) || selectedItems.length < 1) && (!Array.isArray(selectedActivities) || selectedActivities.length < 1)"
          cols="12"
          class="text-center grey lighten-3"
          style="border-bottom-left-radius: 5px; border-bottom-right-radius: 5px;"
        >
          <span style="font-weight: 300;">
            {{ $t('errors.noneSelectedYet') }}
          </span>
        </v-col>
        <v-col
          v-else
          cols="12"
        >
          <v-container
            fluid
            class="pa-0"
          >
            <v-row
              v-if="Array.isArray(selectedItems) && selectedItems.length > 0"
              dense
              no-gutters
            >
              <v-col
                cols="12"
                class="px-2"
                style="font-size: 14px; font-weight: 300;"
              >
                {{ $t('Tickets') }}
              </v-col>
              <v-col cols="12">
                <v-list class="py-0">
                  <template v-for="item in selectedItems">
                    <v-list-item :key="item.uuid">
                      <v-list-item-content>
                        <v-list-item-title class="text-wrap">
                          {{ translatedItemTitle(item) }}
                        </v-list-item-title>
                        <v-list-item-subtitle v-if="itemPrice(item) && !hideItemPrice">
                          {{ itemPrice(item) }} {{ $t('per') }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action-text
                          class="text--primary pl-2"
                          style="font-size: 18px; font-weight: 600;"
                      >
                        {{ item.quantity }}
                      </v-list-item-action-text>
                    </v-list-item>
                  </template>
                </v-list>
              </v-col>
            </v-row>
            <v-divider v-if="Array.isArray(selectedSlots) && selectedSlots.length > 0" />
            <v-row
              v-if="Array.isArray(selectedSlots) && selectedSlots.length > 0"
              class="pt-2"
              dense
              no-gutters
            >
              <v-col cols="12" class="px-2" style="font-size: 14px; font-weight: 300;">
                {{ $t('date') }}
              </v-col>
              <v-col cols="12">
                <v-list class="py-0">
                  <template v-for="slot in selectedSlots">
                    <v-list-item :key="slot.label">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ readableSelectedDate }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action-text
                          class="text--primary pl-2"
                          style="font-size: 16px; font-weight: 500;"
                      >
                        {{ slot.label }}
                      </v-list-item-action-text>
                    </v-list-item>
                  </template>
                </v-list>
              </v-col>
            </v-row>
            <v-divider v-if="Array.isArray(selectedAdditionalItems) && selectedAdditionalItems.length > 0" />
            <v-row
              v-if="Array.isArray(selectedAdditionalItems) && selectedAdditionalItems.length > 0"
              dense
              class="pt-2"
              no-gutters
            >
              <v-col
                cols="12"
                class="px-2"
                style="font-size: 14px; font-weight: 300;"
              >
                {{ $t('Add-ons') }}
              </v-col>
              <v-col cols="12">
                <v-list class="py-0">
                  <template v-for="item in selectedAdditionalItems">
                    <v-list-item :key="item.uuid">
                      <v-list-item-content>
                        <v-list-item-title class="text-wrap">
                          {{ translatedItemTitle(item) }}
                        </v-list-item-title>
                        <v-list-item-subtitle v-if="itemPrice(item) && !hideItemPrice">
                          {{ itemPrice(item) }} {{ $t('per') }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action-text
                        class="text--primary pl-2"
                        style="font-size: 18px; font-weight: 600;"
                      >
                        {{ item.quantity }}
                      </v-list-item-action-text>
                    </v-list-item>
                  </template>
                </v-list>
              </v-col>
            </v-row>
            <v-divider v-if="Array.isArray(selectedActivities) && selectedActivities.length > 0 && Array.isArray(selectedItems) && selectedItems.length > 0" />
            <v-row
              v-if="Array.isArray(selectedActivities) && selectedActivities.length > 0"
              dense
              no-gutters
            >
              <v-col
                cols="12"
                class="px-2 pt-2"
                style="font-size: 14px; font-weight: 300;"
              >
                {{ $t('activities') }}
              </v-col>
              <v-col cols="12">
                <v-list class="py-0">
                  <template v-for="activity in selectedActivities">
                    <v-list-item :key="activity.uuid">
                      <v-list-item-content>
                        <v-list-item-title class="text-wrap">
                          {{ translatedItemTitle(activity) }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ activityDateTimeText(activity) }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="itemPrice(activity) && !hideItemPrice">
                          {{ itemPrice(activity) }} {{ $t('per') }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action-text
                        class="text--primary pl-2"
                        style="font-size: 18px; font-weight: 600;"
                      >
                        {{ activity.quantity }}
                      </v-list-item-action-text>
                    </v-list-item>
                  </template>
                </v-list>
              </v-col>
            </v-row>
            <v-divider />
            <v-row
                class="px-2 pt-2"
                style="font-weight: 500;"
            >
              <v-col>
                {{ $t('Total') }}
              </v-col>
              <v-col class="text-end">
                {{ totalAmount | formatPrice }}
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "ParticipantTotals",
  props: {
    selectedDate: {
      type: Number,
      default: null
    },
    selectedItems: {
      type: Array,
      default: () => []
    },
    selectedAdditionalItems: {
      type: Array,
      default: () => []
    },
    selectedSlots: {
      type: Array,
      default: () => []
    },
    selectedActivities: {
      type: Array,
      default: null
    }
  },
  computed: {
    hideItemPrice() {
      return this.$store.state.hideItemPrice
    },
    readableSelectedDate() {
      if(typeof this.selectedDate !== 'undefined' && this.selectedDate !== null) {
        const d = new Date(this.selectedDate)
        return d.toLocaleDateString()
      }
      return ''
    },
    totalAmount() {
      let total = 0
      let currency = 'ISK'

      if(Array.isArray(this.selectedItems) && this.selectedItems.length > 0) {
        if (this.selectedItems[0] && this.selectedItems[0].item && this.selectedItems[0].item.price && this.selectedItems[0].item.price.currency_code) {
          currency = this.selectedItems[0].item.price.currency_code
        }
      }

      if(Array.isArray(this.selectedSlots) && this.selectedSlots.length > 0) {
        if(this.selectedSlots[0] && this.selectedSlots[0].price && this.selectedSlots[0].price.currency_code) {
          currency = this.selectedSlots[0].price.currency_code
        }
      }

      const mainItemsTotal = this.selectedItems.reduce((sum, selectedItem) => {
        if(selectedItem) {
          if(selectedItem.sale_price && typeof selectedItem.sale_price.amount !== 'undefined' && selectedItem.sale_price.amount > 0) {
            return sum + (selectedItem.sale_price.amount * selectedItem.quantity)
          }
          if(selectedItem.price && typeof selectedItem.price.amount !== 'undefined') {
            return sum + (selectedItem.price.amount * selectedItem.quantity)
          }
        }
        return sum
      }, 0)

      let slotsTotal = 0
      if(Array.isArray(this.selectedSlots)) {
        for(let slotIndex = 0; slotIndex < this.selectedSlots.length; slotIndex++) {
          const slot = this.selectedSlots[slotIndex]
          if(typeof slot !== 'undefined' && slot !== null) {
            if(Array.isArray(slot.slots)) {
              for(let slotSlotIndex = 0; slotSlotIndex < slot.slots.length; slotSlotIndex++) {
                const slotSlot = slot.slots[slotSlotIndex]
                if(typeof slotSlot !== 'undefined' && slotSlot !== null) {
                  if(typeof slotSlot.totalPrice !== 'undefined' && slotSlot.totalPrice !== null) {
                    if(typeof slotSlot.totalPrice.amount !== 'undefined' && slotSlot.totalPrice.amount !== null) {
                      slotsTotal += slotSlot.totalPrice.amount
                    }
                  }
                }
              }
            }
          }
        }
      }

      const additionalItemsTotal = this.selectedAdditionalItems.reduce((sum, item) => {
        if(item) {
          if(item.sale_price && typeof item.sale_price.amount !== 'undefined' && item.sale_price.amount > 0) {
            return sum + (item.sale_price.amount * item.quantity)
          }
          if(item.price && typeof item.price.amount !== 'undefined') {
            return sum + (item.price.amount * item.quantity)
          }
        }
        return sum
      }, 0)

      let activityItemsTotal = 0
      if(Array.isArray(this.selectedActivities) && this.selectedActivities.length > 0) {
        activityItemsTotal = this.selectedActivities.reduce((sum, item) => {
          if(item) {
            if(item.sale_price && typeof item.sale_price.amount !== 'undefined' && item.sale_price.amount > 0) {
              return sum + (item.sale_price.amount * item.quantity)
            }
            if(item.price && typeof item.price.amount !== 'undefined') {
              return sum + (item.price.amount * item.quantity)
            }
          }
          return sum
        }, 0)
      }

      // If we are using slots prices it gets priority on price
      if(this.useSlotPrices === true) {
        total = slotsTotal + additionalItemsTotal + activityItemsTotal
      } else {
        total = mainItemsTotal + additionalItemsTotal + activityItemsTotal
      }

      if(total >= 0) {
        return { amount: total, currency_code: currency }
      }
      return null
    },
    useSlotPrices() {
      return this.$store.state.useSlotPrices
    }
  },
  methods: {
    activityDateTimeText(activity) {
      if(typeof activity !== 'undefined' && activity !== null) {
        if(typeof activity.selectedDate !== 'undefined' && activity.selectedDate !== null) {
          const date = new Date(activity.selectedDate * 1000)
          const dateText = date.toDateString()
          const timeText = date.toISOString().substring(11, 16)
          return dateText + ' ' + (this.$i18n.locale === 'is' ? 'kl. ' : '@ ') + timeText
        }
      }
      return ''
    },
    translatedItemTitle(item) {
      if(typeof item !== 'undefined' && item !== null) {
        const translationExists = this.$te('item:title:' + item.uuid)
        if(translationExists) {
          return this.$t('item:title:' + item.uuid)
        }
        return item.title
      }
      return ''
    },
    itemPrice(item) {
      if(typeof item !== 'undefined' && item !== null) {
        if(typeof item.sale_price !== 'undefined' && item.sale_price !== null && typeof item.sale_price.amount !== 'undefined' && item.sale_price.amount > 0) {
          return this.$store.getters.getItemCurrencyDisplay(item.sale_price)
        }
        if(typeof item.price !== 'undefined' && item.price !== null) {
          return this.$store.getters.getItemCurrencyDisplay(item.price)
        }
      }
      return null
    },
  }
}
</script>

<style scoped>

</style>
