import axios from "axios";

export default {
	state: {
		gettingOrderInfo: false,
		updatingOrderData: false
	},
	mutations: {
		gettingOrderInfo(state, status) {
			state.gettingOrderInfo = status
		},
		updatingOrderData(state, status) {
			state.updatingOrderData = status
		}
	},
	actions: {
		getOrder: ({ commit, getters }, orderUUID) => {
			if(orderUUID === null || orderUUID === undefined) {
				return
			}

			commit('gettingOrderInfo', true)
			let body = {
				query: 'query {\n' +
					'   orderOption(organization: "' + getters.organizationUUID + '", order: "' + orderUUID + '") {\n' +
					'     orderId\n' +
					'     data\n' +
					'   }\n' +
					'}'
			}

			return axios.post(getters.graphUrl, body).then(response => {
				let orderOptionResponse = response.data.data.orderOption || null
				if(orderOptionResponse !== null && orderOptionResponse !== undefined) {
					if(orderOptionResponse.data !== null && orderOptionResponse.data !== undefined) {
						if(Array.isArray(orderOptionResponse.data.checkoutSectionInstancesData)) {
							for(let i = 0; i < orderOptionResponse.data.checkoutSectionInstancesData.length; i++) {
								let instanceData = orderOptionResponse.data.checkoutSectionInstancesData[i]
								if(instanceData !== null && instanceData !== undefined) {
									if(instanceData.data !== null && instanceData.data !== undefined && typeof instanceData.data === 'string') {
										try {
											instanceData.data = JSON.parse(instanceData.data)
										} catch (e) {
											//
										}
									}
								}
							}
						}
					}
				}
				return orderOptionResponse
			}).catch(() => {
				//
			}).finally(() => {
				commit('gettingOrderInfo', false)
			})
		},
		updateOrderData: ({ commit, getters }, payload) => {
			if(payload === null || payload === undefined) {
				return
			}

			commit('updatingOrderData', true)
			const body = {
				query: 'mutation UpdateOrderData($uuid: String!, $data: JSON) {\n' +
					' updateOrderData(uuid: $uuid, data: $data) \n' +
					'}',
				variables: {
					uuid: payload.uuid,
					data: payload.data
				}
			}

			return axios.post(getters.graphUrl, body).then(response => {
				return response.data.data.updateOrderData || false
			}).catch(() => {
				//
			}).finally(() => {
				commit('updatingOrderData', false)
			})
		}
	},
	getters: {
		gettingOrderInfo: (state) => {
			return state.gettingOrderInfo
		},
		updatingOrderData: (state) => {
			return state.updatingOrderData
		}

	}
}
