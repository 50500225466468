import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import store from './store'
import vuetify from './plugins/vuetify';
import router from './router'
import i18n from './i18n'
import VCalendar from 'v-calendar';
import * as Resize from './iframeResizer.contentWindow.min.js'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import Hotjar from 'vue-hotjar'
import { version } from '../package.json'
import currencyFormatter from "currency-formatter"
import VueGtag from "vue-gtag"

// eslint-disable-next-line
console.info(`SalesCloud schedule v${version}`);

Vue.config.productionTip = false
Vue.use(Resize)
Vue.use(require('vue-moment'))

Bugsnag.start({
  apiKey: process.env.VUE_APP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginVue()],
	appVersion: version
})

const bugsnagVue = Bugsnag.getPlugin('vue')
bugsnagVue.installVueErrorHandler(Vue)

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
})

Vue.use(Hotjar, {
  id: process.env.VUE_APP_HOTJAR_ID  // Hotjar Site ID
})

Vue.use(VueGtag, {
  bootstrap: false
})

Vue.filter('formatPrice', (price) => {
  if(typeof price !== 'undefined' && price !== null) {
    let amount = 0
    let currencyCode = 'ISK'
    let decimalDigits = 0

    if(typeof price.amount !== 'undefined' && price.amount !== null) {
      amount = price.amount
    }

    if(typeof price.currency_code !== 'undefined' && price.currency_code !== null) {
      currencyCode = price.currency_code
    }

    if(typeof price.decimals !== 'undefined' && price.decimals !== null) {
      decimalDigits = price.decimals
    } else {
      const foundCurrency = currencyFormatter.findCurrency(currencyCode)
      if(foundCurrency) {
        decimalDigits = foundCurrency.decimalDigits
      }
    }

    if(decimalDigits > 0) {
      amount = amount / Math.pow(10, decimalDigits)
    }

    return currencyFormatter.format(amount, { code: currencyCode })
  }
  return ''
})

Vue.filter('capitaliseFirstLetter', (string) => {
  if(typeof string === 'string' && string.length > 0) {
    return string.substring(0, 1).toUpperCase() + string.substring(1).toLowerCase()
  }
  return ''
})

new Vue({
  store,
  vuetify,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
