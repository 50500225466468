export default {
	state: {
		smarabioOrganizationUUID: '4e507c89-2141-408a-9878-a8dcdb5b0cec',
		smarabioChannelUUID: '11b2b9e4-9821-4240-9f8d-f3dcfc72c7b3',
		smarabioLocationUUID: '2aac403c-e4a0-4062-88ea-7ee00c7da105',
		namskeid: ['4eec54f6-90f2-41e4-be47-75435c3a7e85', '970ba020-5d28-4d70-9128-cb1b30949663', '45b2cf01-0f10-488a-847c-401586cbbb01', '0693a2b8-3c20-4e9d-809b-82327549428c',
			'8f84d0fa-031e-4cbf-b025-3977ac1dd740', 'a98e5c92-26a2-499b-8b52-e215ca9ae57f', 'b43be4c9-f4fe-4426-8d01-6aeb453b0c63', '080a1d39-b8b6-478f-ad71-442f0b1a785b',
			'b0455562-0dc4-4e08-b9d1-32c5bad99334', '2d8bf411-2632-4498-9371-c1b0a354b323'],
		smarabioDiscountPercent: 15,
		namskeidCategory: '2aa19e8b-13a3-4ebb-a0e0-715e3d6984a0',
		afmaelisCategory: '4b8a49a9-a96d-4c9e-9a64-426104571b3c'
	},
	mutations: {},
	actions: {},
	getters: {
		organizationIsPerlaNordursins: (state, getters) => {
			return getters.organizationUUID === '0612810a-43d8-4adf-a8df-f2098286ca26'
		},
		organizationIsBullseye: (state, getters) => {
			const organizationUUID = getters.organizationUUID
			if(organizationUUID) {
				return organizationUUID === 'bb78d411-9b7c-4a51-a134-e359cc1bc7f8'
			}
			return false
		},
		shouldShowMultipleSelect: (state, getters) => {
			const organization = getters.organizationUUID
			const channel = getters.channelUUID
			const location = getters.locationUUID

			return organization === state.smarabioOrganizationUUID && channel === state.smarabioChannelUUID && location === state.smarabioLocationUUID && getters.categoryIsNamskeid
		},
		organizationIsSmarabio: (state, getters)  => {
			const organization = getters.organization
			return organization && organization.uuid === state.smarabioOrganizationUUID
		},
		categoryIsNamskeid: (state, getters) => {
			return Array.isArray(getters.categoryFilter) && getters.categoryFilter.length === 1 && getters.categoryFilter.includes(state.namskeidCategory)
		},
		itemIsNamskeid: (state) => (item) => {
			if(!item || !item.uuid) {
				return false
			}
			return !!state.namskeid.includes(item.uuid)
		},
		smarabioDiscountAmount: (state) => (item) => {
			if(!item || !item.price || !item.price.amount) {
				return 0
			}
			return Math.floor((state.smarabioDiscountPercent/100) * item.price.amount)
		},
		smarabioDiscountedPrice: (state, getters) => (item) => {
			if(!item || !item.price || !item.price.amount) {
				return 0
			}
			if(item && item.sale_price && item.price && item.sale_price.amount < item.price.amount) {
				return item.sale_price.amount
			}

			return item.price.amount - getters.smarabioDiscountAmount(item)
		},
		isSmarabioNamskeid: (state, getters) => {
			return getters.organizationUUID === state.smarabioOrganizationUUID && getters.channelUUID === state.smarabioChannelUUID
		},
		isSmarabioAfmaelis: (state, getters) => (item) => {
      if(getters.organizationUUID === state.smarabioOrganizationUUID) {
        if(typeof item !== 'undefined' && item !== null) {
          if(Array.isArray(item.categories) && item.categories.some(c => c.uuid === state.afmaelisCategory)) {
            return true
          }
        }
      }
      return false
		},
		smarabioAfmaelisConfirmationFee: (state, getters) => (quantity, item) => {
			if(getters.isSmarabioAfmaelis(item)) {
				if(quantity >= 30) {
					return 20000
				}
				return 10000
			}
			return 0
		},
		smarabioFields() {
			let smarabioFields = []

			smarabioFields.push({
				key: 'waiver_child_nafn',
				type: 'text',
				label: 'Nafn barns',
				required: true,
				weight: 9,
				cols: 4
			})


			smarabioFields.push({
				key: 'waiver_child_age',
				type: 'text',
				label: 'Aldur barns',
				required: true,
				weight: 10,
				cols: 4
			})

			smarabioFields.push({
				key: 'waiver_child_kennitala',
				type: 'text',
				label: 'Kennitala barns',
				required: true,
				weight: 11,
				cols: 4
			})

			smarabioFields.push({
				key: 'waiver_child_ofnæmi',
				type: 'textarea',
				label: 'Hvort barn sé með ofnæmi eða greiningu',
				required: true,
				weight: 12,
				cols: 12
			})

			smarabioFields.push({
				key: 'waiver_child_name_of_parent_1',
				type: 'text',
				label: 'Nafn foreldris/forráðamanns #1',
				required: true,
				weight: 13,
				cols: 4
			})

			smarabioFields.push({
				key: 'waiver_child_simanumer_1',
				type: 'text',
				label: 'Símanúmer #1',
				required: true,
				weight: 14,
				cols: 4
			})

			smarabioFields.push({
				key: 'waiver_child_netfang_1',
				type: 'text',
				label: 'Netfang #1',
				required: true,
				weight: 15,
				cols: 4
			})

			smarabioFields.push({
				key: 'waiver_child_name_of_parent_2',
				type: 'text',
				label: 'Nafn foreldris/forráðamanns #2',
				required: true,
				weight: 16,
				cols: 4
			})

			smarabioFields.push({
				key: 'waiver_child_simanumer_2',
				type: 'text',
				label: 'Símanúmer #2',
				required: true,
				weight: 17,
				cols: 4
			})

			smarabioFields.push({
				key: 'waiver_child_netfang_2',
				type: 'text',
				label: 'Netfang #2',
				required: true,
				weight: 18,
				cols: 4
			})

			smarabioFields.push({
				key: 'waiver_child_heimilisfang',
				type: 'text',
				label: 'Heimilisfang',
				required: true,
				weight: 19,
				cols: 6
			})

			smarabioFields.push({
				key: 'waiver_child_gotuheiti',
				type: 'text',
				label: 'Götuheiti',
				required: true,
				weight: 20,
				cols: 6
			})

			smarabioFields.push({
				key: 'waiver_sveitafelag',
				type: 'text',
				label: 'Sveitafélag',
				required: true,
				weight: 21,
				cols: 6
			})

			smarabioFields.push({
				key: 'waiver_postal_code',
				type: 'text',
				label: 'Póstnúmer',
				required: true,
				weight: 22,
				cols: 6
			})

			return smarabioFields
		}
	}
}
