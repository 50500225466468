<template>
  <v-container>
    <v-row>
      <v-col
        v-if="!isInitialized"
        cols="12"
      >
        <Loading />
      </v-col>
      <v-col
        v-else
        cols="12"
      >
        <v-card outlined>
          <v-container
            fluid
            class="py-6"
          >
            <v-row justify="center">
              <v-col
                cols="12"
                md="12"
                lg="12"
              >
                <QuantitySelection
                  :selected-quantity="selectedQuantity"
                  :items="items"
                  @selectQuantity="selectQuantity"
                />
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col
                cols="12"
                md="12"
                lg="12"
              >
                <DateSelection
                  :selected-date="selectedDate"
                  :items="items"
                  @selectDate="selectDate"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
      <v-col
        id="available-slots"
        cols="12"
      >
          <v-container
            v-if="gettingAvailableTimeSlots"
            fluid
          >
            <v-row>
              <v-col
                cols="12"
                class="text-center"
              >
                <v-progress-circular indeterminate />
              </v-col>
            </v-row>
          </v-container>
          <v-container
            v-if="!gettingAvailableTimeSlots && allAvailableTimeSlots && Object.keys(allAvailableTimeSlots).length < 1"
            fluid
          >
            <v-row justify="center">
              <v-col
                class="text-center"
                cols="12"
                md="12"
                lg="12"
              >
                <v-alert
                  class="mb-0"
                  type="info"
                >
                  {{ $t('errors.fullyBooked') }}
                </v-alert>
              </v-col>
            </v-row>
          </v-container>
          <v-container
            v-if="!gettingAvailableTimeSlots && allAvailableTimeSlots && Object.keys(allAvailableTimeSlots).length > 0"
            fluid
          >
            <v-row justify="center">
              <v-col
                v-if="isMobile"
                cols="12"
              >

              </v-col>
              <v-col
                v-else
                cols="12"
              >
              <v-row>
                <template v-for="(key, index) in Object.keys(allAvailableTimeSlots)">
                  <v-col :key="key" cols="12" md="6">
                    <BookingFlowItem :item="itemByKey(key)" :key="index" :slots="allAvailableTimeSlots[key]" />
                  </v-col>
                </template>
              </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                v-if="hasSelectedItems"
                id="selected-info"
                cols="12"
              >
                <v-divider />
                <div class="py-2">
                  <div
                    v-if="selectedText"
                    class="text-center"
                    style="font-weight: 500;"
                  >
                    {{ $t('selected') }}
                  </div>
                  <div
                    v-if="selectedText"
                    class="text-center pb-2"
                    style="font-weight: 300;"
                  >
                    {{ selectedText }}
                  </div>
                  <div
                    class="text-center"
                    style="font-weight: 500;"
                  >
                    {{ $t('Total') }}
                  </div>
                  <div
                    class="text-center"
                    style="font-weight: 300;"
                  >
                    {{ totalAmount }}
                  </div>
                </div>
                <v-divider />
              </v-col>
              <v-col
                id="book-btn"
                cols="12"
              >
                <v-btn
                  x-large
                  block
                  color="success"
                  :disabled="!hasSelectedItems || addingToCart"
                  :loading="addingToCart"
                  @click="addToCart"
                >
                  {{ $t('button.book') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DateSelection from "@/components/DateSelection"
import QuantitySelection from "@/components/QuantitySelection"
import Loading from "@/components/Loading"
import axios from "axios"
import jsesc from "jsesc"
import BookingFlowItem from "@/components/BookingFlowItem"

export default {
  name: "BookingFlow",
  components: {
    Loading,
    QuantitySelection,
    DateSelection,
    BookingFlowItem
  },
  data() {
    return {
      selectedDate: null,
      selectedQuantity: 0,
      gettingAvailableTimeSlots: false,
      allAvailableTimeSlots: null,
      interval: 60, // in minutes
      selectedSlots: [],
      selectedItems: [],
      addingToCart: false
    }
  },
  computed: {
	isInIframe() {
		return this.$store.getters.isInIframe
	},
    location() {
      return this.$store.state.location
    },
    allAdditionalItems() {
      let additionalItems = []
      if(Array.isArray(this.items) && this.items.length > 0) {
        for(let itemIndex = 0; itemIndex < this.items.length; itemIndex++) {
          const item = this.items[itemIndex]
          if(item && Array.isArray(item.additionalItems)) {
            for(let addIndex = 0; addIndex < item.additionalItems.length; addIndex++) {
              const additionalItem = item.additionalItems[addIndex]
              if(additionalItems.some(i => i && i.uuid === additionalItem.uuid)) {
                continue
              }
              additionalItems.push(additionalItem)
            }
          }
        }
      }
      return additionalItems
    },
    selectedText() {
      let text = ''
      if(Array.isArray(this.selectedItems)) {
        const selectedSlotsInItems = this.selectedItems.filter(item => item && item.slot)
        for(let slotIndex = 0; slotIndex < selectedSlotsInItems.length; slotIndex++) {
          const slot = selectedSlotsInItems[slotIndex]

          text += slot.item.title + ' ' + slot.quantity + 'x ' + this.$store.getters.getItemCurrencyDisplay(slot.item.price) + ' - ' + slot.slot.label
          if(slotIndex < (selectedSlotsInItems.length - 1)) {
            text += ', '
          }
        }
      }
      return text
    },
    totalAmount() {
      let total = 0
      let currency = 'ISK'
      if(Array.isArray(this.selectedItems) && this.selectedItems.length > 0) {
        if(this.selectedItems[0] && this.selectedItems[0].item && this.selectedItems[0].item.price && this.selectedItems[0].item.price.currency_code) {
          currency = this.selectedItems[0].item.price.currency_code
        }
        total = this.selectedItems.reduce((sum, slot) => {
          if(slot && slot.item && slot.item.price && typeof slot.item.price.amount !== 'undefined') {
            return sum + (slot.item.price.amount * slot.quantity)
          }
          return sum
        }, 0)
      }
      if(total > 0) {
        return this.$store.getters.getItemCurrencyDisplay({ amount: total, currency_code: currency })
      }
      return null
    },
    hasSelectedItems() {
      return Array.isArray(this.selectedItems) && this.selectedItems.length > 0
    },
    selectedSlotsQuantity() {
      if(Array.isArray(this.selectedItems) && this.selectedItems.length > 0) {
        return this.selectedItems.reduce((sum, slot) => {
          if(slot.slot) {
            return sum + slot.quantity
          }
          return sum
        }, 0)
      }
      return 0
    },
    isMobile() {
      return false
    },
    items() {
      return this.$store.getters.items
    },
    isInitialized() {
      return this.$store.getters.isInitialized
    },
    sessionToken: {
      get() {
        return this.$store.state.sessionToken
      },
      set(value) {
        this.$store.state.sessionToken = value
      }
    },
    intervals() {
      const hours = []
      if(this.allAvailableTimeSlots && Object.keys(this.allAvailableTimeSlots).length > 0) {
        for(let key in this.allAvailableTimeSlots) {
          const slots = this.allAvailableTimeSlots[key]
          if(Array.isArray(slots)) {
            for(let slotIndex = 0; slotIndex < slots.length; slotIndex++) {
              const slot = slots[slotIndex]
              if(slot) {
                if(!hours.includes(slot.label)) {
                  hours.push(slot.label)
                }
              }
            }
          }
        }
      }
      return hours
    },
    inApp() {
      return this.$store.getters.inApp
    }
  },
  methods: {
    updateSelectedItems(payload) {
      if(payload) {
        const payloadIndex = this.selectedItems.findIndex(item => item && item.item && item.item.uuid === payload.item.uuid
          && ((payload.slot && item.slot && item.slot.timestampInMillis === payload.slot.timestampInMillis) || !payload.slot))

        if(payloadIndex >= 0) {
          if(payload.quantity <= 0) {
            this.selectedItems.splice(payloadIndex, 1)
          } else {
            this.selectedItems.splice(payloadIndex, 1, payload)
          }
        } else {
          this.selectedItems.push(payload)
        }
      }
    },
    itemAdditionalItems(key) {
      if(key) {
        const item = this.itemFromSku(key)
        if(item && Array.isArray(item.additionalItems)) {
          return item.additionalItems
        }
      }
      return []
    },
    decreaseQuantity(key, slot) {
      const index = this.selectedSlots.findIndex(s => s && s.item && s.item.sku === key && s.slot && s.slot.timestampInMillis === slot.timestampInMillis)
      if(index >= 0) {
        if(this.selectedSlots[index]) {
          if(this.selectedSlots[index].quantity > 1) {
            this.selectedSlots[index].quantity--
          } else {
            this.selectSlot(key, slot)
          }
        }
      }
    },
    increaseQuantity(key, slot) {
      const index = this.selectedSlots.findIndex(s => s && s.item && s.item.sku === key && s.slot && s.slot.timestampInMillis === slot.timestampInMillis)
      if(index >= 0) {
        if(this.selectedSlots[index]) {
          if(this.selectedSlots[index].quantity > 0) {
            if(this.selectedSlotsQuantity + 1 > this.selectedQuantity) {
              const errorMessage = this.$t('errors.maxQuantityReached')
              alert(errorMessage)
            } else {
              this.selectedSlots[index].quantity++
            }
          }
        }
      } else {
        if(this.selectedSlotsQuantity + 1 > this.selectedQuantity) {
          const errorMessage = this.$t('errors.maxQuantityReached')
          alert(errorMessage)
        } else {
          this.selectSlot(key, slot)
        }
      }
    },
    book() {
      //
    },
    async addToCart() {

      if(!this.hasSelectedItems) {
        return
      }

      this.addingToCart = true

      if(!this.sessionToken) {
        await this.fetchSessionToken()
      }

      const cartItems = []
      const dataLayerItems = []

      let languageOption = 'is'
      if(this.$i18n.locale === 'is' || this.$i18n.locale === 'en') {
        languageOption = this.$i18n.locale
      }

      for(let slotIndex = 0; slotIndex < this.selectedItems.length; slotIndex++) {
        const slot = this.selectedItems[slotIndex]
        let values = {}

        if(slot.slot) {
          values.timeSlots = {
            startsAtTime: slot.slot.timestampInMillis / 1000,
            endsAtTime: (slot.slot.timestampInMillis + slot.slot.paddingInMillis) / 1000,
            quantity: slot.quantity
          }
        }

        cartItems.push({
          item: slot.item.uuid,
          quantity: slot.quantity,
          uiValues: jsesc(values, { 'json': true })
        })

        let slotItemPrice = {
          amount: 0,
          currency_code: 'ISK'
        }

        if(typeof slot.item.price !== 'undefined' && slot.item.price !== null) {
          if (typeof slot.item.price.amount !== 'undefined' && slot.item.price.amount > 0) {
            slotItemPrice = slot.item.price
          }
        }
        if(typeof slot.item.sale_price !== 'undefined' && slot.item.sale_price !== null) {
          if(typeof slot.item.sale_price.amount !== 'undefined' && slot.item.sale_price.amount > 0) {
            slotItemPrice = slot.item.sale_price
          }
        }

        dataLayerItems.push({
          item_name: slot.item.title,
          item_id: slot.item.sku,
          price: slotItemPrice.amount,
          currency: slotItemPrice.currency_code,
          quantity: slot.quantity
        })
      }

      // Sending cart items to top level window for tracking
      try {
        // Clear the previous ecommerce object
        window.parent.postMessage(JSON.stringify({ ecommerce: null }), '*')

        // Send the Ecommerce items
        window.parent.postMessage(JSON.stringify({
          event: 'add_to_cart',
          ecommerce: {
            items: dataLayerItems
          }
        }), '*')
      } catch (e) {
        // Do nothing if failed
      }

      if(!this.inApp) {
        this.$store.dispatch('checkoutItems', {
          languageOption,
          cartItems,
          sessionToken: this.sessionToken
        }).then(order => {
          if(typeof order !== 'undefined' && order !== null && typeof order.uuid !== 'undefined' && order.uuid !== null) {
            // Only run if there is an actual response
            const channel = this.$store.state.channelUUID
            const organization = this.$store.state.organizationUUID
            const orderUUID = order.uuid

            try {
              // eslint-disable-next-line no-undef
              window.parent.postMessage('closeResizer', '*')
              window.parent.postMessage(JSON.stringify({ event: 'eventTracking', 'category': 'schedule', 'action': 'Continue to Checkout', 'label': 'schedule' }), '*')
            } catch (e) {
              // Do nothing
              console.log(e)
            }

            // eslint-disable-next-line no-unused-vars
            let checkoutURL = 'https://checkout.salescloud.is/?time=' + Date.now() + '&dark=' + this.$vuetify.theme.dark + '#' + organization + '/' + channel + '/' + orderUUID + '/checkout' + '?sessionToken=' + this.sessionToken.session_token + '&language=' + languageOption
			if(this.isInIframe) {
				document.location = checkoutURL
			}
			else {
				this.$store.commit('setCheckoutUrl', checkoutURL)
			}

          } else {
            this.errorMessage = this.$t('errors.couldNotAddToCart') + ' - ' + this.$t('errors.fullyBooked')
            alert(this.errorMessage)
            this.processing = false
          }
        }).catch(() => {
          this.errorMessage = this.$t('errors.couldNotAddToCart')
          alert(this.errorMessage)
          this.processing = false
        }).finally(() => {})
      }
    },
    isSelected(key, slot) {
      if(key && slot) {
        const fullItem = this.itemFromSku(key)
        if(fullItem) {
          if(Array.isArray(this.selectedSlots)) {
            const indexOf = this.selectedSlots.findIndex(s => s && s.item && s.item.uuid === fullItem.uuid && s.slot && s.slot.timestampInMillis === slot.timestampInMillis)
            return indexOf >= 0
          }
        }
      }
    },
    selectSlot(item, slot) {
      if(item && slot) {
        const fullItem = this.itemFromSku(item)
        if(fullItem) {
          if(Array.isArray(this.selectedSlots)) {
            const indexOf = this.selectedSlots.findIndex(s => s && s.item && s.item.uuid === fullItem.uuid && s.slot && s.slot.timestampInMillis === slot.timestampInMillis)
            if(indexOf < 0) {
              this.selectedSlots.push({ item: fullItem, slot, quantity: 1 })
            } else {
              this.selectedSlots.splice(indexOf, 1)
            }
          }
        }
      }
    },
    slotsInHour(key, hour) {
      if(key && hour && this.allAvailableTimeSlots && Object.keys(this.allAvailableTimeSlots).length > 0) {
        const slots = this.allAvailableTimeSlots[key]
        if(Array.isArray(slots) && slots.length > 0) {
          return slots.filter(slot => slot && slot.label === hour)
        }
      }
      return []
    },
    itemFromSku(slotItem) {
      if(Array.isArray(this.items) && this.items.length > 0) {
        const foundItem = this.items.find(item => item && item.sku === slotItem)
        if(foundItem) {
          return foundItem
        }
      }
      return null
    },
    itemPrice(slotItem, selectedSlot) {
      const item = this.itemFromSku(slotItem)
      if(item) {
        let quantity = 0
        const slot = this.selectedSlots.find(s => s && s.item && s.item.sku === slotItem && s.slot && s.slot.timestampInMillis === selectedSlot.timestampInMillis)
        if(slot) {
          quantity = slot.quantity
        }
        if(typeof item.price !== 'undefined' && item.price !== null) {
          return (quantity ? quantity + 'x ' : '') + this.$store.getters.getItemCurrencyDisplay(item.price)
        }
      }
      return null
    },
    itemByKey(slotItem) {
      const item = this.itemFromSku(slotItem)
      if(item) {
        return item
      }
      return null
    },
    itemTitle(slotItem) {
      const item = this.itemFromSku(slotItem)
      if(item) {
        return item.title
      }
      return ''
    },
    selectQuantity(value) {
      this.selectedQuantity = value
    },
    selectDate(value) {
      this.selectedDate = value
    },
    getAllAvailableTimeSlots() {
      this.gettingAvailableTimeSlots = true
      const payload = {}
      payload.items = this.items
      payload.quantity = this.selectedQuantity
      payload.startTimeInMillis = new Date(this.selectedDate).setUTCHours(0, 0, 0, 0)
      payload.endTimeInMillis = new Date(this.selectedDate).setUTCHours(23, 59, 59, 999)

      this.$store.dispatch('getAvailableTimeSlotsForMultipleItems', payload).then(result => {
        this.allAvailableTimeSlots = result
      }).finally(() => {
        this.gettingAvailableTimeSlots = false
      })
    },
    fetchSessionToken() {
      const graphOptions = {
        headers: {
          'Content-Type': 'application/json'
        }
      };

      let url = 'https://graph.salescloud.is?';

      const graphBody = {
        query: 'mutation { newAnonymousSession { session_token, ttl } }'
      };

      return axios.post(url, graphBody, graphOptions).then((response) => {
        this.sessionToken = response.data.data.newAnonymousSession
        this.saveSessionToken(this.sessionToken)
        return response.data.data.newAnonymousSession
      }).catch(() => {
        return null
      })
    },
    saveSessionToken(sessionToken) {
      localStorage.setItem('sessionToken', JSON.stringify(sessionToken));
    },
    getSessionToken() {

      let sessionToken = null;

      try {
        sessionToken = JSON.parse(localStorage.getItem('sessionToken'));
      }
      catch (e) {
        localStorage.removeItem('sessionToken');
      }

      this.sessionToken = sessionToken
    }
  },
  watch: {
    selectedDate(value) {
      if(value !== null && this.selectedQuantity > 0) {
        this.getAllAvailableTimeSlots()
      } else {
        this.allAvailableTimeSlots = []
      }
    },
    selectedQuantity(value) {
      if(value > 0 && this.selectedDate !== null) {
        this.getAllAvailableTimeSlots()
      }
    }
  },
  mounted() {
    this.fetchSessionToken()
  }
}
</script>

<style scoped>

</style>
