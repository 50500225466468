<template>
	<v-card class="d-flex flex flex-column">
		<v-img v-if="showImages && categoryImage !== ''" max-height="200px" :src="categoryImage" class="grey lighten-1" min-height="400"></v-img>
		<v-card-title>{{ categoryName }}</v-card-title>
		<v-card-subtitle v-if="category.subtitle">{{ category.subtitle }}</v-card-subtitle>
		<v-card-text v-if="description" v-html="description"></v-card-text>
		<v-divider></v-divider>
		<v-card-text class="px-2 pt-0">
			<div class="text-subtitle-2 text-overline pt-2 pl-2 pr-2">{{ $t('selectService') }}</div>
			<v-list class="py-0">
				<template v-for="(item, index) in categoryItems">
					<CategoryListItemProduct :key="item.uuid" :item="item" @click="selectItem" :selected-items="selectedItems" v-model="selectedItem" />
					<v-divider :key="'d-' + item.uuid" v-if="index < categoryItems.length - 1"></v-divider>
				</template>
			</v-list>
		</v-card-text>
		<v-divider v-if="selectedItem || (selectedItems && selectedItems.length > 0)"></v-divider>
		<v-card-text v-if="selectedItem">
			<ItemListItem :key="selectedItem.uuid" :item="selectedItem" :inCard="true" :inCategory="category" />
		</v-card-text>
		<v-card-text v-if="selectedItems && selectedItems.length > 0">
			<ItemListMultipleSelectedList :selected-items="selectedItems"></ItemListMultipleSelectedList>
		</v-card-text>
		<v-card-actions v-if="showCardActions">
			<v-btn block>View item</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import CategoryListItemProduct from "@/components/CategoryListItemProduct";
import ItemListItem from "@/components/ItemListItem";
import ItemListMultipleSelectedList from "@/components/ItemListMultipleSelectedList";
export default {
	name: "CategoryListItem",
	components: {ItemListMultipleSelectedList, ItemListItem, CategoryListItemProduct},
	props: {
		category: Object
	},
	data() {
		return {
			selectedItem: null,
			categoryItems: null,
			selectedItems: []
		}
	},
	computed: {
    showImages() {
      return this.$store.state.showImages
    },
		selectMultipleItems() {
			return this.$store.getters.selectMultipleItems
		},
		showCardActions() {
			return false
		},
		categoryImage() {
			if(this.category && this.category.image !== null && this.category.image !== undefined && this.category.image.file !== null && this.category.image.file !== undefined) {
				return this.category.image.file.sources.large
			} else {
				return ''
			}
		},
    description() {

      if(typeof this.category !== 'undefined' && this.category !== null) {
        // Check if key exists in locale  and if so, return its value
        const key = 'category:description:' + this.category.uuid
        if(this.$te(key)) {
          return this.$t(key)
        }

        // If key does not exist we at least want to return the description value of the category
        const { description } = this.category
        if(typeof description !== 'undefined' && description !== null) {
          return description
        }
      }

      return null
    },
    categoryName() {
      if(typeof this.category !== 'undefined' && this.category !== null) {
        const key = 'category:name:' + this.category.uuid
        if(this.$te(key)) {
          return this.$t(key)
        }
        return this.category.name
      }
      return null
    },
	},
	methods: {
		selectItem(item) {
			if(this.selectMultipleItems) {
				if(this.selectedItems.includes(item)) {
					this.selectedItems.splice(this.selectedItems.findIndex(i => i.uuid === item.uuid), 1)
				} else {
					this.selectedItems.push(item)
				}
			} else {
				this.selectedItem = null
				this.selectedItem = item
			}
		}
	},
	mounted() {
		this.categoryItems = this.$store.getters.categoryItems(this.category)

		this.categoryItems = this.categoryItems.sort((i1, i2) => {

			if(i2.dates === null || i2.dates === undefined || i2.dates.length === 0) {
				return 0
			}

			if(i1.dates === null || i1.dates === undefined || i1.dates.length === 0) {
				return 0
			}

			const lowestI1 = Math.min(i1.dates)
			const lowestI2 = Math.min(i2.dates)

			return lowestI1 - lowestI2
		})
	}
}
</script>

<style scoped>

</style>
