<template>
  <v-container
    fluid
    class="py-2"
  >
    <v-row
      dense
      align="center"
    >
      <v-col
        :cols="belowWidthThreshold ? '12' : '3'"
        style="font-size: 17px; font-weight: 700;"
      >
        <span v-if="index === 0">
          {{ itemDateDate }}
        </span>
      </v-col>
      <v-col
        :cols="belowWidthThreshold ? (belowSecondThresholdWidth ? '12' : '4') : '2'"
        style="font-size: 17px; font-weight: 500;"
      >
        {{ itemDateTime }}
      </v-col>
      <v-col
        :cols="belowWidthThreshold ? (belowSecondThresholdWidth ? '12' : '8') : '7'"
      >
        <SimplifiedQuantitySelection
          no-padding
          :selected-quantity="quantity"
          :can-decrement="canDecrement"
          :can-increment="canIncrement"
          :grow="belowWidthThreshold"
          @decreaseQuantity="decreaseQuantity"
          @increaseQuantity="increaseQuantity"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SimplifiedQuantitySelection from "@/components/common/SimplifiedQuantitySelection"

export default {
  name: "ActivityDate",
  components: {
    SimplifiedQuantitySelection
  },
  props: {
    itemDate: {
      type: Number,
      default: null
    },
    index: {
      type: Number,
      default: null
    },
    selectedActivities: {
      type: Array,
      default: null
    },
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      thresholdWidth: 440,
      secondThresholdWidth: 350
    }
  },
  computed: {
    quantity() {
      const index = this.selectedActivities.findIndex(activity => activity && activity.uuid === this.item.uuid && activity.selectedDate === this.itemDate)
      if(index >= 0) {
        return this.selectedActivities[index].quantity
      }
      return 0
    },
    canIncrement() {
      if(typeof this.item !== 'undefined' && this.item !== null) {
        if(typeof this.item.maximumOrderQuantity !== 'undefined' && this.item.maximumOrderQuantity !== null && this.item.maximumOrderQuantity > 0) {
          return this.quantity < this.item.maximumOrderQuantity
        }
      }
      return true
    },
    canDecrement() {
      return this.quantity > 0
    },
    itemDateTime() {
      if(typeof this.itemDate !== 'undefined' && this.itemDate !== null) {
        const toDate = new Date(this.itemDate * 1000)
        return toDate.toISOString().substring(11, 16)
      }
      return ''
    },
    itemDateDate() {
      if(typeof this.itemDate !== 'undefined' && this.itemDate !== null) {
        const toDate = new Date(this.itemDate * 1000)
        const options = {
          month: 'short',
          day: 'numeric'
        }
        return new Intl.DateTimeFormat(this.$i18n.locale, options).format(toDate)
      }
      return ''
    },
    belowWidthThreshold() {
      return this.$vuetify.breakpoint.width < this.thresholdWidth
    },
    belowSecondThresholdWidth() {
      return this.$vuetify.breakpoint.width < this.secondThresholdWidth
    }
  },
  methods: {
    decreaseQuantity() {
      this.$emit('decreaseQuantity', this.itemDate)
    },
    increaseQuantity() {
      this.$emit('increaseQuantity', this.itemDate)
    }
  }
}
</script>

<style scoped>

</style>