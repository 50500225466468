var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"flat":""}},[(!_vm.inApp && _vm.showImages)?_c('v-img',{staticClass:"grey",attrs:{"src":_vm.itemImage}}):_vm._e(),_c('v-card-title',{staticClass:"text-break",staticStyle:{"font-weight":"900"}},[_vm._v(_vm._s(_vm.itemTitle))]),(_vm.description)?_c('v-card-text',{staticClass:"pb-0",domProps:{"innerHTML":_vm._s(_vm.description)}}):_vm._e(),_c('v-spacer',{staticClass:"fill-height"}),(_vm.quantityOptions.length > 1)?_c('div',[(
					!_vm.hasVariationsAffectingMeasure &&
						((_vm.hideExtraInfo && _vm.quantityOptions.length > 1) ||
							!_vm.hideExtraInfo)
				)?_c('v-card-text',[_c('v-select',{staticClass:"mt-0 mb-0",attrs:{"hide-details":"","dense":"","outlined":"","label":_vm.cartText,"items":_vm.quantityOptions,"item-text":"text","item-value":"key","no-data-text":_vm.$t('errors.fullyBooked')},model:{value:(_vm.quantity),callback:function ($$v) {_vm.quantity=$$v},expression:"quantity"}})],1):_vm._e()],1):_vm._e(),_vm._l((_vm.variationsByAttributes),function(attribute){return [_c('v-card-text',{key:attribute.uuid,staticClass:"pt-0 pb-0"},[_c('div',{key:attribute.uuid,staticClass:"mb-5"},[_c('div',{staticClass:"text-h6 font-weight-bold mb-3"},[_vm._v(" "+_vm._s(_vm.$t("attribute:name:" + attribute.uuid))+" ")]),_vm._l((_vm.attributesByVariation(
							attribute
						)),function(variation,index){return [_c('v-row',{key:variation.label},[_c('v-col',{staticClass:"py-0 align-center align-self-center",attrs:{"cols":"6"}},[_c('div',{staticClass:"text-subtitle-2 align-self-center",staticStyle:{"font-weight":"500","font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.$t("variation:label:" + variation.uuid))+" ")]),(
										_vm.organizationIsPerlaNordursins &&
											variation.price
									)?_c('div',{staticClass:"text--secondary font-italic"},[_vm._v(" "+_vm._s(_vm.currencyDisplay(variation.price))+" "+_vm._s(_vm.$t("perTicket"))+" ")]):_vm._e()]),_c('v-col',{staticClass:"text-right py-0 align-self-center justify-end",attrs:{"cols":"6"}},[(false)?_c('div',{staticClass:"green--text text-subtitle-2"},[_vm._v(" "+_vm._s(_vm.variationDisplayPrice(variation))+" ")]):_vm._e(),_c('div',{staticClass:"d-flex mt-2 text-right align-self-center justify-end"},[_c('v-btn',{staticClass:"mr-3",staticStyle:{"height":"32px !important","width":"32px !important","min-width":"32px"},attrs:{"depressed":"","disabled":!_vm.canDecrementVariation(variation),"dark":_vm.canDecrementVariation(variation),"color":"grey lighten-2"},on:{"click":function($event){return _vm.decrementVariationQuantity(variation)}}},[_c('v-icon',{attrs:{"color":"primary","dark":""}},[_vm._v(" mdi-minus ")])],1),_c('div',{staticClass:"align-self-center",staticStyle:{"font-size":"20px"}},[_vm._v(" "+_vm._s(_vm.variationQuantityByVariation(variation))+" ")]),_c('v-btn',{staticClass:"ml-3",staticStyle:{"height":"32px !important","width":"32px !important","min-width":"32px"},attrs:{"depressed":"","disabled":!_vm.canIncrementVariation(variation),"dark":_vm.canIncrementVariation(variation),"color":"grey lighten-2"},on:{"click":function($event){return _vm.incrementVariationQuantity(variation)}}},[_c('v-icon',{attrs:{"color":"primary","dark":""}},[_vm._v("mdi-plus")])],1)],1)]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[(index < _vm.attributesByVariation(attribute).length - 1)?_c('v-divider',{staticClass:"mt-4 mb-4",staticStyle:{"margin-top":"5px !important","margin-bottom":"5px !important"}}):_vm._e()],1)],1)]})],2)]),_c('v-divider',{key:attribute.uuid + ':divider'})]}),(
				_vm.showAvailability &&
					_vm.cartQuantity > 0 &&
					!_vm.hasPredefinedDates &&
					_vm.item.isAllDayEvent !== true
			)?_c('Schedule',{staticClass:"pl-5 pr-5 pt-2",attrs:{"item":_vm.item,"cartQuantity":_vm.cartQuantity,"requiredSlots":_vm.requiredSlots,"missingSlots":_vm.missingSlots,"maximumGroupedQuantity":_vm.maximumGroupedQuantity,"inCategory":_vm.inCategory},on:{"timeSlotsSelected":_vm.timeSlotsSelected,"hasSlots":_vm.updateHasSlots,"dayClick":_vm.dayClick}}):_vm._e(),(_vm.hasPredefinedDates)?_c('v-card-text',[(_vm.sellingNotStarted)?_c('div',[_c('v-alert',{attrs:{"type":"info"}},[_vm._v(" "+_vm._s(_vm.$t('eventSellingStartsAt', {date: _vm.formatDateAndTimeFromMillis(_vm.item.startSellTimeInMillis) }))+" ")])],1):(_vm.sellingHasEnded)?_c('div',[_c('v-alert',{attrs:{"type":"warning"}},[_vm._v(" "+_vm._s(_vm.$t('eventSellingEnded'))+" ")])],1):_c('v-select',{attrs:{"hide-details":"","dense":"","label":_vm.$t('selectDate'),"outlined":"","items":_vm.predefinedDates,"item-value":"key","item-text":"label"},model:{value:(_vm.selectedPredefinedDate),callback:function ($$v) {_vm.selectedPredefinedDate=$$v},expression:"selectedPredefinedDate"}})],1):_vm._e(),(false)?_c('v-card-text',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasError),expression:"hasError"}]},[_c('v-alert',{attrs:{"type":"error","prominent":""}},[_vm._v(_vm._s(_vm.errorMessage))])],1):_vm._e(),(
				_vm.showUpsell &&
					((_vm.showAvailability && _vm.cartQuantity > 0) ||
						_vm.hasPredefinedDates ||
						_vm.item.isAllDayEvent === true) &&
					_vm.hasSlots &&
					_vm.selectedTimeSlots.length > 0
			)?_c('v-divider'):_vm._e(),(
				_vm.showUpsell &&
					((_vm.showAvailability && _vm.cartQuantity > 0) ||
						_vm.hasPredefinedDates ||
						_vm.item.isAllDayEvent === true) &&
					_vm.hasSlots &&
					_vm.selectedTimeSlots.length > 0
			)?_c('v-card-text',{staticClass:"px-0 pt-1"},[(_vm.organizationIsSmarabio)?_c('AdditionalItems',{attrs:{"items":_vm.bookingUpsellItems}}):_c('Upsell',{attrs:{"items":_vm.bookingUpsellItems,"event-quantity":_vm.quantity,"event-item":_vm.item},on:{"updateSelectedAdditionalItems":_vm.updateSelectedAdditionalItems}})],1):_vm._e(),(
				_vm.showUpsell &&
					((_vm.showAvailability && _vm.cartQuantity > 0) ||
						_vm.hasPredefinedDates ||
						_vm.item.isAllDayEvent === true) &&
					_vm.hasSlots &&
					_vm.selectedTimeSlots.length > 0
			)?_c('v-divider'):_vm._e(),(_vm.totalPrice.amount > 0 && !_vm.organizationShowsAdditionalItemsAsAdditionalItems)?_c('v-card-text',{staticClass:"text-center"},[_c('p',{staticClass:"font-weight-bold ma-0"},[_vm._v(_vm._s(_vm.$t("Order summary"))+":")]),_c('p',{staticClass:"ma-0"},[_vm._v(" "+_vm._s(!_vm.selectedDate && _vm.itemHasNoDatesButHasStartDate ? _vm.$t("validFrom") : "")+" "+_vm._s(_vm.selectedDate && _vm.formattedSelectedDate !== "" ? _vm.formattedSelectedDate : "")+" "+_vm._s(_vm.cartQuantity)+" "+_vm._s(_vm.buttonUnitOfMeasureText)+" | "+_vm._s(_vm.$t("Total"))+" "+_vm._s(_vm.currencyDisplay(_vm.totalPrice))+" ")]),(_vm.isSmarabioAfmaelis)?_c('p',{staticClass:"ma-0"},[_vm._v(" "+_vm._s(_vm.$t("confirmationFee"))+": "+_vm._s(_vm.currencyDisplay({ amount: _vm.smarabioAfmaelisConfirmationFee, currency_code: "ISK" }))+" ")]):_vm._e(),(_vm.coupon && _vm.cartQuantity > 0)?_c('div',{staticClass:"pt-2"},[_c('div',{staticClass:"text-uppercase green--text",staticStyle:{"font-weight":"700"}},[_vm._v(" "+_vm._s(_vm.$t("appliedCoupon", { coupon: _vm.coupon.code }))+" ")]),_c('div',{staticClass:"text-lowercase caption"},[_vm._v(" ("+_vm._s(_vm.$t("discountShownOnNextPage"))+") ")])]):_vm._e()]):_vm._e(),(
				_vm.showUpsell &&
					((_vm.showAvailability && _vm.cartQuantity > 0) ||
						_vm.hasPredefinedDates ||
						_vm.item.isAllDayEvent === true) &&
					_vm.hasSlots &&
					_vm.selectedTimeSlots.length > 0 &&
					_vm.requiredVariationsNotYetSelected.length > 0
			)?_c('v-divider'):_vm._e(),(
				_vm.showUpsell &&
					((_vm.showAvailability && _vm.cartQuantity > 0) ||
						_vm.hasPredefinedDates ||
						_vm.item.isAllDayEvent === true) &&
					_vm.hasSlots &&
					_vm.selectedTimeSlots.length > 0 &&
					_vm.requiredVariationsNotYetSelected.length > 0
			)?_c('v-card-text',[_c('div',{staticStyle:{"font-weight":"700"}},[_vm._v(" "+_vm._s(_vm.$t('You must make a selection for following items to be able to continue'))+" ")]),_vm._l((_vm.requiredVariationsNotYetSelected),function(notSelected,index){return [_c('div',{key:index},[_vm._v(" - "+_vm._s(_vm.getAttributeName(notSelected.attribute))+" "+_vm._s(_vm.$t('in'))+" "+_vm._s(_vm.getItemTitle(notSelected.item))+" ")])]})],2):_vm._e(),_c('v-card-actions',[(
					!_vm.showAvailability &&
						!_vm.hasPredefinedDates &&
						_vm.item.isAllDayEvent !== true
				)?_c('v-btn',{class:_vm.textButtonColor,attrs:{"large":"","block":"","disabled":_vm.processing || _vm.cartQuantity < 1 || !_vm.canSell,"loading":_vm.processing,"color":_vm.positivePrimaryColor},on:{"click":_vm.checkAvailability}},[_vm._v(_vm._s(_vm.$t("button.check_availability")))]):(_vm.showAvailability || _vm.hasPredefinedDates)?_c('v-btn',{class:_vm.textButtonColor,attrs:{"large":"","block":"","disabled":_vm.processing || _vm.cartQuantity < 1 || !_vm.hasSlots || !_vm.canSell || (_vm.organizationIsSmarabio && _vm.requiredVariationsNotYetSelected.length > 0),"loading":_vm.processing,"color":_vm.positivePrimaryColor},on:{"click":_vm.bookItem}},[_vm._v(" "+_vm._s(_vm.$t("button.book"))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-arrow-right ")])],1):(_vm.item.isAllDayEvent === true)?_c('v-btn',{class:_vm.textButtonColor,attrs:{"large":"","block":"","disabled":_vm.processing || _vm.cartQuantity < 1 || !_vm.hasSlots || !_vm.canSell,"loading":_vm.processing,"color":_vm.positivePrimaryColor},on:{"click":_vm.bookItem}},[_vm._v(_vm._s(_vm.$t("button.book")))]):_vm._e()],1),(_vm.showUpsellDialog)?_c('v-dialog',{model:{value:(_vm.showUpsellDialog),callback:function ($$v) {_vm.showUpsellDialog=$$v},expression:"showUpsellDialog"}},[_c('v-card',[_c('Upsell',{attrs:{"items":_vm.bookingUpsellItems,"event-quantity":_vm.quantity},on:{"updateSelectedAdditionalItems":_vm.updateSelectedAdditionalItems}}),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"error"}},[_vm._v(_vm._s(_vm.$t("button.noThanks")))]),_c('v-btn',{attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.$t("button.yesPlease")))])],1)],1)],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }