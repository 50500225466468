import { render, staticRenderFns } from "./SingleTimeslotSelection.vue?vue&type=template&id=127e439e&scoped=true"
import script from "./SingleTimeslotSelection.vue?vue&type=script&lang=js"
export * from "./SingleTimeslotSelection.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "127e439e",
  null
  
)

export default component.exports