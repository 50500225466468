<template>
  <div>
		<v-date-picker
			v-if="false"
			full-width
			no-title
			:show-current="false"
			style="border: 1px solid rgba(0,0,0,0.1); border-radius: 10px;"
			:max="maxDateForVDP"
			:min="minDateForVDP"
			@change="selectDateFromVDP"
		/>
    <vc-calendar
      is-expanded
      title-position="left"
      :disabled-dates="disabledDates"
      :min-date="minDate"
      :max-date="maxDate"
      :attributes="attributes"
      :value="currentlySelectedDate"
      :locale="locale"
      :timezone="locationTimezone"
      @dayclick="selectDate"
    />
  </div>
</template>

<script>
export default {
  name: "DateSelection",
  props: {
    selectedDate: {
      type: Number,
      default: new Date()
    },
    items: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    organization() {
		return this.$store.state.organization
    },
    location() {
		return this.$store.state.location
    },
    organizationTimezone() {
		if(typeof this.organization === 'undefined' || this.organization === null) {
			return 'UTC'
		}
		if(typeof this.organization.defaultTimezone === 'undefined' || this.organization.defaultTimezone === null) {
			return 'UTC'
		}
		return this.organization.defaultTimezone
    },
    locationTimezone() {
		if(typeof this.location === 'undefined' || this.location === null) {
			return this.organizationTimezone
		}
		if(typeof this.location.timezone === 'undefined' || this.location.timezone === null) {
			return this.organizationTimezone
		}
		return this.location.timezone
    },
    locale() {
      return this.$i18n.locale
    },
    currentlySelectedDate() {
      return new Date(this.selectedDate).setUTCHours(0,0,0,0)
    },
    systemTimeInMillis() {
      return this.$store.state.systemTimeInMillis
    },
    disabledDates() {
      let disabledDates = []

      if(Array.isArray(this.unavailableDatesInMillis) && this.unavailableDatesInMillis.length > 0) {
        for(let dateIndex = 0; dateIndex < this.unavailableDatesInMillis.length; dateIndex++) {
          const unavailableDateInMillis = this.unavailableDatesInMillis[dateIndex]

          disabledDates.push(new Date(unavailableDateInMillis))
        }
      }

      return disabledDates
    },
    // Returns non-duplicated unavailable dates for all items as an array of time in milliseconds
    unavailableDatesInMillis() {
      const unavailableDates = []

      if(Array.isArray(this.items) && this.items.length > 0) {
        for(let itemIndex = 0; itemIndex < this.items.length; itemIndex++) {
          const item = this.items[itemIndex]

          if(Array.isArray(item.unavailableDates) && item.unavailableDates.length > 0) {
            for(let dateIndex = 0; dateIndex < item.unavailableDates.length; dateIndex++) {
              const unavailableDate = item.unavailableDates[dateIndex]

              if(typeof unavailableDate !== 'undefined' && unavailableDate !== null) {
                try {
                  const splitDate = unavailableDate.split(/[.\-/]/gi)
                  if(splitDate.length !== 3) {
                    continue
                  }

                  const date = Number(splitDate[0])
                  const month = Number(splitDate[1])
                  const year = Number(splitDate[2])

                  if(!isNaN(date) && !isNaN(month) && !isNaN(year)) {
                    const dateTimestamp = new Date(0).setUTCFullYear(year, month - 1, date)
                    if(!unavailableDates.includes(dateTimestamp)) {
                      unavailableDates.push(dateTimestamp)
                    }
                  }
                } catch (e) {
                  // Do de nada
                }
              }
            }
          }
        }
      }

      return unavailableDates
    },
    attributes() {
      return [
        {
          key: 'selected',
          highlight: 'blue',
          dates: this.selectedDate
        }
      ]
    },
    minDate() {

      let minTimeInMillis = this.systemTimeInMillis

      if(Array.isArray(this.items) && this.items.length > 0) {
        let itemsDates = []
        for(let itemIndex = 0; itemIndex < this.items.length; itemIndex++) {
          const item = this.items[itemIndex]
          if(Array.isArray(item.dates) && item.dates.length > 0) {
            const itemFutureDates = item.dates.filter(date => date && date >= Math.floor(new Date().getTime() / 1000))
            if(itemFutureDates.length > 0) {
				itemsDates = itemsDates.concat(...itemFutureDates)
                continue
            }
          }
          // Check if startSellTimeInMillis value exists
          if(typeof item.startSellTimeInMillis !== 'undefined' && item.startSellTimeInMillis > 0) {
            // Since we are checking all items we want to make sure that we display first available date for ALL items
            if(this.systemTimeInMillis < item.startSellTimeInMillis || minTimeInMillis < item.startSellTimeInMillis) {
              minTimeInMillis = item.startSellTimeInMillis
            }
          }

          // Check if startDate value exists
          if(typeof item.startDate !== undefined && item.startDate > 0) {
            if(this.systemTimeInMillis < item.startDate || minTimeInMillis < item.startDate) {
              minTimeInMillis = item.startDate
            }
          }
        }

		if(itemsDates.length > 0) {
			minTimeInMillis = Math.min(...itemsDates) * 1000
		}
      }

      return new Date(minTimeInMillis * 1)
    },
    maxDate() {
      let maxDate = null

      if(Array.isArray(this.items) && this.items.length > 0) {
        let itemsDates = []
        for(let itemIndex = 0; itemIndex < this.items.length; itemIndex++) {
          const item = this.items[itemIndex]
          if(Array.isArray(item.dates) && item.dates.length > 0) {
            const itemFutureDates = item.dates.filter(date => date && date >= Math.floor(new Date().getTime() / 1000))
            if(itemFutureDates.length > 0) {
				itemsDates = itemsDates.concat(...itemFutureDates)
                continue
            }
          }

          // If item has set max ahead booking in days we want to listen to that over end sell date and end date
          // UNLESS the numbers conflict

          let maxTime = 0
          if(this.maxAheadBookingInDays > 0) {
            const days = this.maxAheadBookingInDays
            maxTime = this.systemTimeInMillis + (86400000 * days)
          }

          if(typeof item.endSellDateInMillis !== 'undefined' && item.endSellDateInMillis > 0) {
            // If endSellDate is set to March 5th 2025 but max ahead bookings is set to 365 and the year is 2025
            // we do not want max ahead bookings to take priority since it may cause problems in legacy
            if(maxTime > item.endSellDateInMillis) {
              maxTime = item.endSellDateInMillis
            }
          }

          if(typeof item.endDate !== undefined && item.endDate > 0) {
            if(maxTime > item.endDate) {
              maxTime = item.endDate
            }
          }

          if(maxTime > 0) {
            maxDate = new Date(maxTime)
          } else {
            maxDate = new Date()
          }
        }

        // If item has dates we want to listen to only those dates
        if(itemsDates.length > 0) {
            maxDate = new Date(Math.max(...itemsDates) * 1000)
        }
      }

      return maxDate
    },
	minDateForVDP() {
		return null
	},
	maxDateForVDP() {
		return null
	},
    maxAheadBookingInDays() {
      let maxAheadBookingInDays = 0

      if(Array.isArray(this.items) && this.items.length > 0) {
        for(let itemIndex = 0; itemIndex < this.items.length; itemIndex++) {
          const item = this.items[itemIndex]
          if(typeof item.maxAheadBookingInDays !== 'undefined' && item.maxAheadBookingInDays !== null && item.maxAheadBookingInDays > maxAheadBookingInDays) {
            maxAheadBookingInDays = item.maxAheadBookingInDays
          }
        }
      }

      return maxAheadBookingInDays
    },
    itemsDates() {
		let itemsDates = []
		if(Array.isArray(this.items) && this.items.length > 0) {
			for(let itemIndex = 0; itemIndex < this.items.length; itemIndex++) {
				const item = this.items[itemIndex]
				if (Array.isArray(item.dates) && item.dates.length > 0) {
					const itemFutureDates = item.dates.filter(date => date && date >= Math.floor(new Date().getTime() / 1000))
					if (itemFutureDates.length > 0) {
						itemsDates = itemsDates.concat(...itemFutureDates)
					}
				}
			}
		}
		return itemsDates
    }
  },
	methods: {
		selectDateFromVDP(value) {
			console.log('value', value)
		},
		selectDate(value) {
			if(this.disabled === true) {
				return
			}

			if(typeof value === 'undefined' || value === null) {
				return
			}

			if(typeof value.date === 'undefined' || value.date === null) {
				return
			}

			const parsedDate = value.id.split('-')
			const dateUTCTimestamp = new Date().setUTCFullYear(Number(parsedDate[0]), Number(parsedDate[1]) - 1, Number(parsedDate[2]))
			const minDateMillis = this.minDate ? this.minDate.setUTCHours(0,0,0,0) : 0
			const maxDateMillis = this.maxDate ? this.maxDate.setUTCHours(23, 59, 59, 999) : 0

			if(dateUTCTimestamp < minDateMillis) {
				this.$emit('selectDate', null)
				return
			}

			if(dateUTCTimestamp > maxDateMillis) {
				this.$emit('selectDate', null)
				return
			}

			if(this.unavailableDatesInMillis.includes(dateUTCTimestamp)) {
				this.$emit('selectDate', null)
				return
			}

			this.$emit('selectDate', dateUTCTimestamp)
    }
  }
}
</script>

<style scoped>

</style>