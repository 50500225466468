<template>
  <div>
    <ItemCategory
      v-for="category in itemCategories"
      :key="category.uuid"
      :category="category"
      :items="items"
    />
    <div v-if="itemsInNoCategory.length > 0">
      <v-card @click="showMore = !showMore" outlined>
        <div class="d-flex flex-row pa-2">
          <div>
            {{ $t('More options') }}
          </div>
          <v-spacer />
          <div>
            <v-icon :style="showMore ? 'transform: rotate(180deg);' : ''">
              mdi-chevron-down
            </v-icon>
          </div>
        </div>
      </v-card>
      <div v-if="showMore" class="pt-3">
        <template v-for="item in itemsInNoCategory">
          <AdditionalItem
            :key="item.uuid"
            :item="item"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import ItemCategory from "@/components/item/ItemCategory";
import AdditionalItem from "@/components/item/AdditionalItem";

export default {
  name: "AdditionalItems",
  components: {
    AdditionalItem,
    ItemCategory
  },
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showMore: false
    }
  },
  computed: {
    itemsInNoCategory() {
      if(!Array.isArray(this.items)) {
        return []
      }
      return this.items.filter(item => item && (!Array.isArray(item.categories) || item.categories.length <= 0))
    },
    itemCategories() {
      return this.$store.getters.getItemCategories(this.items)
    }
  }
}
</script>

<style scoped>

</style>