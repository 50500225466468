<template>
	<div>
		<v-list-item>
			<v-list-item-avatar v-if="false" tile size="72"><v-img :src="itemImage" contain style="background-color: #FFF; border-radius: 4px;"></v-img></v-list-item-avatar>
			<v-list-item-content>
				<v-list-item-title style="font-weight: 500;">
					{{ item.title }}
				</v-list-item-title>
				<v-list-item-subtitle v-html="itemDescription">
				</v-list-item-subtitle>
				<v-list-item-subtitle>
					<span style="font-size: 18px; font-weight: 500;">{{ quantity > 0 ? `${quantity} x` : '' }} {{ itemDisplayPrice }}</span>
					<span v-if="itemHasSalePrice" class="text-decoration-line-through">{{ itemBaseDisplayPrice }}</span>
					<span v-if="!whitelistedAdditionalItems.includes(item.uuid)">{{ ` (${itemTotalDisplayPrice})` }}</span>
					<span v-if="item && whitelistedAdditionalItems.includes(item.uuid) && false">
						{{ $t('per guest') }}
					</span>
				</v-list-item-subtitle>
			</v-list-item-content>
			<v-list-item-action v-if="upsellItemsAsSwitch">
				<v-switch color="success" inset @change="setItemValue"></v-switch>
			</v-list-item-action>
		</v-list-item>
		<v-list-item class="d-flex" v-if="!upsellItemsAsSwitch">
			<v-btn :disabled="!canDecrement" @click="decrementItemQuantity" :dark="canDecrement" color="red" class="mr-3" small fab><v-icon dark>mdi-minus</v-icon></v-btn>
			<v-select solo hide-details dense outlined :label="$t('optionalAmount')" :items="itemQuantityOptions" v-model="quantity"/>
			<v-btn :disabled="!canIncrement" @click="incrementItemQuantity" :dark="canIncrement" color="green" small class="ml-3" fab><v-icon dark>mdi-plus</v-icon></v-btn>
		</v-list-item>
	</div>
</template>

<script>
export default {
	name: "UpsellItem",
	props: {
		item: {
			type: Object,
			default: () => ({})
		},
		eventQuantity: {
			type: Number,
			default: 0
		},
		eventItem: {
			type: Object,
			default: null
		}
	},
	data() {
		return {
			itemQuantity: 0,
			whitelistedAdditionalItems: [
				'2741e9e8-8fea-4a8a-8647-1358716d661f', // Mr. Green Test Tilboð
				'3d8554e6-475f-4f02-921c-6c0ac93ba556', // Minigarðurinn Fjölskyldutilboð með golfi
			]
		}
	},
	mounted() {
		if(this.item && this.item.defaultQuantity) {
			this.itemQuantity = this.item.defaultQuantity
		}
		if((!this.item || (this.item && !this.whitelistedAdditionalItems.includes(this.item.uuid)) && this.upsellItemsAsSwitch === true && this.eventQuantity > 0)) {
			this.itemQuantity = this.eventQuantity
		}
	},
	computed: {
		offers() {
			return this.$store.getters.offers({ eventQuantity: this.eventQuantity, additionalItem: this.item })
		},
		upsellItemsAsSwitch() {
			return this.$store.getters.upsellItemsAsSwitch
		},
		itemHasSalePrice() {
			return this.item && this.item.sale_price && this.item.sale_price.amount && this.item.price && this.item.sale_price.amount < this.item.price.amount
		},
		itemDisplayPrice() {
			if(this.item) {
				if(this.item.sale_price && this.item.sale_price.amount && this.item.sale_price.amount < this.item.price.amount) {
					return this.$store.getters.getItemCurrencyDisplay(this.item.sale_price)
				}
				if(this.item.price && this.item.price.amount !== undefined && this.item.price.amount !== null) {
					return this.$store.getters.getItemCurrencyDisplay(this.item.price)
				}
			}
			return '0 kr.'
		},
		itemTotalDisplayPrice() {
			if(typeof this.item !== 'undefined' && this.item !== null) {
				let amount = 0
				let currency_code = 'ISK'
				if(this.item.sale_price && this.item.sale_price.amount && this.item.sale_price.amount < this.item.price.amount) {
					amount = this.item.sale_price.amount
					currency_code = this.item.sale_price.currency_code
				}
				if(this.item.price && this.item.price.amount !== undefined && this.item.price.amount !== null) {
					amount = this.item.price.amount
					currency_code = this.item.price.currency_code
				}
				amount = amount * this.itemQuantity
				return this.$store.getters.getItemCurrencyDisplay({ amount, currency_code })
			}
			return '0 kr.'
		},
		itemBaseDisplayPrice() {
			if(this.item !== null && this.item !== undefined) {
				return this.$store.getters.getItemCurrencyDisplay(this.item.price)
			}
			return '0 kr.'
		},
		quantity: {
			get() {
				return this.itemQuantity
			},
			set(quantity) {
				this.itemQuantity = quantity
			}
		},
		itemImage() {
			if(this.item && Array.isArray(this.item.images)) {
				const firstImage = this.item.images[0]
				if(firstImage.file && firstImage.file.sources) {
					return firstImage.file.sources.large
				}
			}
			return ''
		},
		itemDescription() {
			if(this.item && this.item.description && this.item.description.value) {
				return this.item.description.value
			}
			return ''
		},
		canDecrement() {
			return this.quantity > 0
		},
		canIncrement() {
			return this.quantity < this.itemMaxQuantity
		},
		itemMinQuantity() {
			if(this.item.minimumOrderQuantity !== null && this.item.minimumOrderQuantity >= 0 && this.item.minimumOrderQuantity !== undefined) {
				return this.item.minimumOrderQuantity
			}
			return 1
		},
		itemMaxQuantity() {
			if(typeof this.offers !== 'undefined' && this.offers !== null) {
				if(typeof this.offers.maxQuantity !== 'undefined' && this.offers.maxQuantity !== null) {
					return this.offers.maxQuantity
				}
			}
			if(this.item.maximumOrderQuantity !== null && this.item.maximumOrderQuantity >= 0 && this.item.minimumOrderQuantity !== undefined) {
				return this.item.maximumOrderQuantity
			}
			return 99
		},
		itemQuantityOptions() {
			return Array.from(Array(this.itemMaxQuantity + 1), (_,i) => { return i })
		}
	},
	methods: {
		decrementItemQuantity() {
			this.quantity = this.quantity - 1
		},
		incrementItemQuantity() {
			this.quantity = this.quantity + 1
		},
		setItemValue(status) {
      try {
        const item = JSON.parse(JSON.stringify(this.item))
        if(status === true) {
          item.quantity = this.itemQuantity
          this.$emit('updateAdditionalItem', item)
        }
        if(status === false) {
          item.quantity = 0
          this.$emit('updateAdditionalItem', item)
        }
      } catch (e) {
        // Do nothing
      }
		}
	},
	watch: {
		itemQuantity(value) {
			if(this.upsellItemsAsSwitch === false) {
        try {
          const item = JSON.parse(JSON.stringify(this.item))
          item.quantity = value
          this.$emit('updateAdditionalItem', item)
        } catch (e) {
          // Do nothing
        }
			}
		},
		eventQuantity(currentValue, previousValue) {
			if(currentValue !== previousValue && this.upsellItemsAsSwitch === true) {
				this.itemQuantity = currentValue
			}
		}
	}
}
</script>

<style scoped>

</style>
