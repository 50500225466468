<template>
	<v-card flat>
		<v-img v-if="!inApp && showImages" class="grey" :src="itemImage" />
		<v-card-title class="text-break" style="font-weight: 900;">{{
			itemTitle
		}}</v-card-title>
		<v-card-text
			class="pb-0"
			v-if="description"
			v-html="description"
		></v-card-text>
		<v-spacer class="fill-height"></v-spacer>
		<div v-if="quantityOptions.length > 1">
			<v-card-text
				v-if="
					!hasVariationsAffectingMeasure &&
						((hideExtraInfo && quantityOptions.length > 1) ||
							!hideExtraInfo)
				"
			>
				<v-select
					class="mt-0 mb-0"
					hide-details
					dense
					outlined
					:label="cartText"
					:items="quantityOptions"
					item-text="text"
					item-value="key"
					v-model="quantity"
					:no-data-text="$t('errors.fullyBooked')"
				/>
			</v-card-text>
		</div>
		<template v-for="attribute in variationsByAttributes">
			<v-card-text class="pt-0 pb-0" :key="attribute.uuid">
				<div class="mb-5" :key="attribute.uuid">
					<div class="text-h6 font-weight-bold mb-3">
						{{ $t("attribute:name:" + attribute.uuid) }}
					</div>
					<template
						v-for="(variation, index) in attributesByVariation(
							attribute
						)"
					>
						<v-row :key="variation.label">
							<v-col
								class="py-0 align-center align-self-center"
								cols="6"
							>
								<div
									class="text-subtitle-2 align-self-center"
									style="font-weight: 500; font-size: 14px;"
								>
									{{
										$t("variation:label:" + variation.uuid)
									}}
								</div>
								<div
									v-if="
										organizationIsPerlaNordursins &&
											variation.price
									"
									class="text--secondary font-italic"
								>
									{{ currencyDisplay(variation.price) }}
									{{ $t("perTicket") }}
								</div>
							</v-col>
							<v-col
								class="text-right py-0 align-self-center justify-end"
								cols="6"
							>
								<div
									v-if="false"
									class="green--text text-subtitle-2"
								>
									{{ variationDisplayPrice(variation) }}
								</div>
								<div
									class="d-flex mt-2 text-right align-self-center justify-end"
								>
									<v-btn
										depressed
										:disabled="!canDecrementVariation(variation)"
										@click="decrementVariationQuantity(variation)"
										:dark="canDecrementVariation(variation)"
										color="grey lighten-2"
										class="mr-3"
										style="height: 32px !important; width: 32px !important; min-width: 32px;"
                  >
                    <v-icon
                      color="primary"
                      dark
                    >
                      mdi-minus
                    </v-icon>
                  </v-btn>
									<div
										class="align-self-center"
										style="font-size: 20px;"
									>
										{{ variationQuantityByVariation(variation) }}
									</div>
									<v-btn
										depressed
										:disabled="!canIncrementVariation(variation)"
										@click="incrementVariationQuantity(variation)"
										:dark="canIncrementVariation(variation)"
										color="grey lighten-2"
										class="ml-3"
										style="height: 32px !important; width: 32px !important; min-width: 32px;"
										><v-icon color="primary" dark
											>mdi-plus</v-icon
										></v-btn
									>
								</div>
							</v-col>
							<v-col class="py-0" cols="12">
								<v-divider
									style="margin-top: 5px !important; margin-bottom: 5px !important;"
									v-if="index < attributesByVariation(attribute).length - 1"
									class="mt-4 mb-4"
								/>
							</v-col>
						</v-row>
					</template>
				</div>
			</v-card-text>
			<v-divider :key="attribute.uuid + ':divider'" />
		</template>
		<Schedule
			class="pl-5 pr-5 pt-2"
			v-if="
				showAvailability &&
					cartQuantity > 0 &&
					!hasPredefinedDates &&
					item.isAllDayEvent !== true
			"
			:item="item"
			:cartQuantity="cartQuantity"
			:requiredSlots="requiredSlots"
			:missingSlots="missingSlots"
			:maximumGroupedQuantity="maximumGroupedQuantity"
			@timeSlotsSelected="timeSlotsSelected"
			@hasSlots="updateHasSlots"
			@dayClick="dayClick"
			:inCategory="inCategory"
		/>
		<v-card-text v-if="hasPredefinedDates">
			<div v-if="sellingNotStarted">
				<v-alert type="info">
					{{ $t('eventSellingStartsAt', {date: formatDateAndTimeFromMillis(item.startSellTimeInMillis) }) }}
				</v-alert>
			</div>
			<div v-else-if="sellingHasEnded">
				<v-alert type="warning">
					{{ $t('eventSellingEnded') }}
				</v-alert>
			</div>
			<v-select
				v-else
				hide-details
				dense
				:label="$t('selectDate')"
				outlined
				:items="predefinedDates"
				item-value="key"
				item-text="label"
				v-model="selectedPredefinedDate"
			/>
		</v-card-text>
		<v-card-text v-show="hasError" v-if="false">
			<v-alert type="error" prominent>{{ errorMessage }}</v-alert>
		</v-card-text>
		<v-divider
			v-if="
				showUpsell &&
					((showAvailability && cartQuantity > 0) ||
						hasPredefinedDates ||
						item.isAllDayEvent === true) &&
					hasSlots &&
					selectedTimeSlots.length > 0
			"
		></v-divider>
		<v-card-text
			v-if="
				showUpsell &&
					((showAvailability && cartQuantity > 0) ||
						hasPredefinedDates ||
						item.isAllDayEvent === true) &&
					hasSlots &&
					selectedTimeSlots.length > 0
			"
			class="px-0 pt-1"
		>
      <AdditionalItems
        v-if="organizationIsSmarabio"
        :items="bookingUpsellItems"
      />
			<Upsell
					v-else
					:items="bookingUpsellItems"
					@updateSelectedAdditionalItems="updateSelectedAdditionalItems"
					:event-quantity="quantity"
					:event-item="item"
			/>
		</v-card-text>
		<v-divider
			v-if="
				showUpsell &&
					((showAvailability && cartQuantity > 0) ||
						hasPredefinedDates ||
						item.isAllDayEvent === true) &&
					hasSlots &&
					selectedTimeSlots.length > 0
			"
		></v-divider>
		<v-card-text class="text-center" v-if="totalPrice.amount > 0 && !organizationShowsAdditionalItemsAsAdditionalItems">
			<p class="font-weight-bold ma-0">{{ $t("Order summary") }}:</p>
			<p class="ma-0">
				{{
					!selectedDate && itemHasNoDatesButHasStartDate
						? $t("validFrom")
						: ""
				}}
				{{
					selectedDate && formattedSelectedDate !== ""
						? formattedSelectedDate
						: ""
				}}
				{{ cartQuantity }} {{ buttonUnitOfMeasureText }} |
				{{ $t("Total") }} {{ currencyDisplay(totalPrice) }}
			</p>
			<p class="ma-0" v-if="isSmarabioAfmaelis">
				{{ $t("confirmationFee") }}:
				{{
					currencyDisplay({
						amount: smarabioAfmaelisConfirmationFee,
						currency_code: "ISK"
					})
				}}
			</p>
			<div v-if="coupon && cartQuantity > 0" class="pt-2">
				<div
					class="text-uppercase green--text"
					style="font-weight: 700;"
				>
					{{ $t("appliedCoupon", { coupon: coupon.code }) }}
				</div>
				<div class="text-lowercase caption">
					({{ $t("discountShownOnNextPage") }})
				</div>
			</div>
		</v-card-text>
    <v-divider v-if="
				showUpsell &&
					((showAvailability && cartQuantity > 0) ||
						hasPredefinedDates ||
						item.isAllDayEvent === true) &&
					hasSlots &&
					selectedTimeSlots.length > 0 &&
					requiredVariationsNotYetSelected.length > 0
			" />
    <v-card-text v-if="
				showUpsell &&
					((showAvailability && cartQuantity > 0) ||
						hasPredefinedDates ||
						item.isAllDayEvent === true) &&
					hasSlots &&
					selectedTimeSlots.length > 0 &&
					requiredVariationsNotYetSelected.length > 0
			">
      <div style="font-weight: 700;">
        {{ $t('You must make a selection for following items to be able to continue') }}
      </div>
      <template v-for="(notSelected, index) in requiredVariationsNotYetSelected">
        <div :key="index">
          - {{ getAttributeName(notSelected.attribute) }} {{ $t('in') }} {{ getItemTitle(notSelected.item) }}
        </div>
      </template>
    </v-card-text>
		<v-card-actions>
			<v-btn
				v-if="
					!showAvailability &&
						!hasPredefinedDates &&
						item.isAllDayEvent !== true
				"
				large
				block
				:class="textButtonColor"
				:disabled="processing || cartQuantity < 1 || !canSell"
				:loading="processing"
				@click="checkAvailability"
				:color="positivePrimaryColor"
				>{{ $t("button.check_availability") }}</v-btn
			>
			<v-btn
				v-else-if="showAvailability || hasPredefinedDates"
				large
				block
				:class="textButtonColor"
				:disabled="
					processing || cartQuantity < 1 || !hasSlots || !canSell || (organizationIsSmarabio && requiredVariationsNotYetSelected.length > 0)
				"
				:loading="processing"
				@click="bookItem"
				:color="positivePrimaryColor"
			>
				{{ $t("button.book") }}
				<v-icon right>
					mdi-arrow-right
				</v-icon>
			</v-btn>
			<v-btn
				v-else-if="item.isAllDayEvent === true"
				large
				block
				:class="textButtonColor"
				:disabled="
					processing || cartQuantity < 1 || !hasSlots || !canSell
				"
				:loading="processing"
				@click="bookItem"
				:color="positivePrimaryColor"
				>{{ $t("button.book") }}</v-btn
			>
		</v-card-actions>
		<v-dialog v-model="showUpsellDialog" v-if="showUpsellDialog">
			<v-card>
				<Upsell
					:items="bookingUpsellItems"
					@updateSelectedAdditionalItems="
						updateSelectedAdditionalItems
					"
					:event-quantity="quantity"
				></Upsell>
				<v-card-actions>
					<v-btn color="error">{{ $t("button.noThanks") }}</v-btn>
					<v-btn color="primary">{{ $t("button.yesPlease") }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-card>
</template>

<script>
import axios from "axios";
import Schedule from "./Schedule";
import jsesc from "jsesc";
import Upsell from "@/components/Upsell";
import AdditionalItems from "@/components/item/AdditionalItems";

/**
 * @property defaultQuantity
 */
export default {
	name: "ItemListItem",
	props: ["item", "inCard", "inCategory"],
	components: {
    AdditionalItems,
		Upsell,
		Schedule
	},
	data: () => ({
		quantity: 1,
		addingToCart: false,
		processing: false,
		selectedVariation: null,
		showAvailability: false,
		selectedDate: null,
		selectedTimeSlots: [],
		selectedPredefinedDate: null,
		hasError: false,
		errorMessage: null,
		hasSlots: false,
		showUpsell: false,
		selectedAdditionalItems: [],
		showUpsellDialog: false,
		predefinedDates: [],
		bookingCount: 0,
    selectedVariations: []
	}),
	watch: {
		quantity(currentAmount, previousAmount) {
			if (currentAmount !== previousAmount) {
				this.hasError = false;
				this.errorMessage = null;
			}
		},
		selectedPredefinedDate(newDateInMillis) {
			const startTimeInMillis = newDateInMillis;
			let endTimeInMillis = newDateInMillis;
			if (
				typeof this.item !== "undefined" &&
				this.item !== null &&
				typeof this.item.durationInMinutes !== "undefined" &&
				this.item.durationInMinutes !== null
			) {
				const durationInMilliseconds =
					this.item.durationInMinutes * 60 * 1000;
				endTimeInMillis = endTimeInMillis + durationInMilliseconds;
			}
			this.selectedTimeSlots = [
				{
					startTimeInMillis: startTimeInMillis,
					endTimeInMillis: endTimeInMillis
				}
			];
			this.hasSlots = true;
			this.getBookingCountByItem();
		},
		item() {
			this.getPredefinedDates();
		}
	},
	mounted() {
		if (this.item.isAllDayEvent) {
			this.hasSlots = true;
		}

		if (this.quantityOptions && this.quantityOptions.length > 0) {
			this.quantity = this.quantityOptions[0].key;
		}

		if (this.item) {
			if (this.item.defaultQuantity) {
				this.quantity = this.item.defaultQuantity;
			}
			if (this.itemHasNoDatesButHasStartDate) {
				// this.selectedDate = new Date(this.item.startDate)
			}
		}

		if (this.hasVariationsAffectingMeasure) {
			this.quantity = 0;
		}

		if (
			this.selectedPredefinedDate === null &&
			this.predefinedDates.length > 0
		) {
			this.selectedPredefinedDate = this.predefinedDates[0].key;
			this.getBookingCountByItem();
		}

		if (this.bookingItemHasUpsellItems) {
			this.showUpsell = true;
		}

		this.getPredefinedDates();
	},
	computed: {
		systemTimeInMillis() {
			return this.$store.state.systemTimeInMillis
		},
		sellingNotStarted() {
			if(!this.item || !this.item.startSellTimeInMillis) {
				return false
			}
			return this.item.startSellTimeInMillis > Date.now()
		},
		sellingHasEnded() {
			if(!this.item || !this.item.endSellTimeInMillis) {
				return false
			}
			return this.item.endSellTimeInMillis < Date.now()
		},
		isInIframe() {
			return this.$store.getters.isInIframe
		},
		coupon() {
			return this.$store.state.coupon;
		},
		organizationIsPerlaNordursins() {
			return this.$store.getters.organizationIsPerlaNordursins;
		},
		isSmarabioAfmaelis() {
			return this.$store.getters.isSmarabioAfmaelis(this.item);
		},
		smarabioAfmaelisConfirmationFee() {
			return this.$store.getters.smarabioAfmaelisConfirmationFee(
				this.quantity,
				this.item
			);
		},
		itemHasNoDatesButHasStartDate() {
			// if(this.isSmarabioAfmaelis) {
			// 	return false
			// }
			// return typeof this.item !== 'undefined' && this.item !== null && !Array.isArray(this.item.dates) && typeof this.item.startDate !== 'undefined' && this.item.startDate > 0
			return false;
		},
		hideExtraInfo() {
			return this.$store.getters.hideExtraInfo;
		},
		showImages() {
			return this.$store.state.showImages;
		},
		disabled() {
			if (
				this.item.availability !== null &&
				this.item.availability !== undefined &&
				this.item.availability.current < 1
			) {
				return this.item.availability.current < 1;
			}

			return false;
		},
		canSell() {
			const timestamp = Date.now();
			if (
				this.item !== null &&
				this.item !== undefined &&
				this.item.uuid === "a4005945-be3a-4397-b606-4e21ef56d55a" &&
				timestamp < 1625194800000
			) {
				return false;
			}
			return true;
		},
		inApp() {
			return this.$store.getters.inApp;
		},
		hasPredefinedDates() {
			if (typeof this.item !== "undefined" && this.item !== null) {
				if (
					Array.isArray(this.item.dates) &&
					this.item.dates.length > 0
				) {
					return true;
				}
			}

			return false;
		},
		totalPrice() {
			const totalPrice = JSON.parse(JSON.stringify(this.item.price));

			totalPrice.amount = this.getDiscountedAmount(totalPrice.amount);

			// if (Array.isArray(this.item.variations)) {
			// 	for (let i = 0; i < this.item.variations.length; i++) {
			// 		const variation = this.item.variations[i];
			// 		const quantity =
			// 			variation.value !== undefined ? variation.value : 0;
			// 		totalPrice.amount =
			// 			totalPrice.amount +
			// 			this.getDiscountedAmount(variation.price.amount) *
			// 				quantity;
			// 	}
			// }
      if(this.hasVariationsAffectingMeasure && Array.isArray(this.selectedVariations)) {
        for(let variationIndex = 0; variationIndex < this.selectedVariations.length; variationIndex++) {
          const variation = this.selectedVariations[variationIndex]
          if(typeof variation === 'undefined' || variation === null) {
            continue
          }
          if(typeof variation.price === 'undefined' || variation.price === null) {
            continue
          }
          if(isNaN(Number(variation.price.amount))) {
            continue
          }

          totalPrice.amount += Number(variation.price.amount)
        }
      }

			if (Array.isArray(this.selectedAdditionalItems)) {
				for (
					let index = 0;
					index < this.selectedAdditionalItems.length;
					index++
				) {
					const item = this.selectedAdditionalItems[index];
					const quantity = item.quantity;
					let amount = 0;
					if (
						typeof item.price !== "undefined" &&
						item.price !== null
					) {
						if (
							typeof item.price.amount !== "undefined" &&
							item.price.amount !== null
						) {
							amount = item.price.amount;
							if (
								typeof item.sale_price !== "undefined" &&
								item.sale_price !== null
							) {
								if (
									typeof item.sale_price.amount !==
										"undefined" &&
									item.sale_price.amount !== null &&
									item.sale_price.amount < item.price.amount
								) {
									amount = item.sale_price.amount;
								}
							}
						}
					}

					totalPrice.amount +=
						this.getDiscountedAmount(amount) * quantity;
				}
			}

      if(Array.isArray(this.selectedAdditionalItemsInStore)) {
        for(let itemIndex = 0; itemIndex < this.selectedAdditionalItemsInStore.length; itemIndex++) {
          const item = this.selectedAdditionalItemsInStore[itemIndex]
          if(!item) {
            continue
          }
          const itemCurrentPrice = this.$store.getters.getItemCurrentPrice(item, item.quantity, item.selectedVariations)
          totalPrice.amount = Number(totalPrice.amount) + Number(itemCurrentPrice.amount)
        }
      }

			totalPrice.amount =
				this.quantity > 0
					? totalPrice.amount * this.quantity
					: totalPrice.amount;

			return totalPrice;
		},
		variationsByAttributes() {
			const attributes = {};

			if (
				typeof this.item !== "undefined" &&
				this.item !== null &&
				Array.isArray(this.item.variations)
			) {
				for (let i = 0; i < this.item.variations.length; i++) {
					const attribute = this.item.variations[i].attribute;
					if(typeof attribute === 'undefined' || attribute === null) {
						continue
					}
					attributes[attribute.uuid] = attribute;
				}

				const keys = Object.keys(attributes);

				const attributesArray = [];

				for (let i in keys) {
					const key = keys[i];
					attributesArray.push(attributes[key]);
				}
			}

			return attributes;
		},
		cartQuantity() {
			if (this.hasVariationsAffectingMeasure) {
        if(!Array.isArray(this.selectedVariations)) {
          return 0
        }
				return this.selectedVariations.length;
			}

			return this.quantity;
		},
		variationQuantity() {
			let quantity = 0;

			if (this.hasVariationsAffectingMeasure) {
				for (let i in this.item.variations) {
					const variation = this.item.variations[i];

					if (
						variation.value !== null &&
						variation.value !== undefined
					) {
						quantity += variation.value;
					}
				}
			}

			return quantity;
		},
		hasVariationsAffectingMeasure() {
			return (
				typeof this.item !== "undefined" &&
				this.item !== null &&
				Array.isArray(this.item.variations) &&
				this.item.variations.length > 0
			);
		},
		unitOfMeasure() {
			let unitOfMeasure = "people";

			if (
				this.item.unitOfMeasure !== null &&
				this.item.unitOfMeasure !== undefined
			) {
				unitOfMeasure = this.item.unitOfMeasure;
			}

			return unitOfMeasure;
		},
		unitOfMeasureText() {
			return this.$t(`amountsUnitOfMeasure.${this.unitOfMeasure}`);
		},
		buttonUnitOfMeasureText() {
			if (this.cartQuantity === 1) {
				return this.$t(`unitsOfMeasure.${this.unitOfMeasure}`);
			}
			return this.$t(`pluralUnitsOfMeasure.${this.unitOfMeasure}`);
		},
		cartText() {
			return (
				this.$t("amount") +
				" " +
				this.$t("amountsUnitOfMeasure." + this.unitOfMeasure)
			);
		},
		maximumOrderQuantity() {
			let maximum = 20;

			if (
				this.item.maximumOrderQuantity !== null &&
				this.item.maximumOrderQuantity !== undefined
			) {
				maximum = this.item.maximumOrderQuantity;
			}

			return maximum;
		},
		minimumOrderQuantity() {
			let minimum = 2;

			if (
				this.item.minimumOrderQuantity !== null &&
				this.item.minimumOrderQuantity !== undefined
			) {
				minimum = this.item.minimumOrderQuantity;
			}

			return minimum;
		},
		maximumGroupedQuantity() {
			let maximumGroupedQuantity = 4;

			if (
				this.item.maximumGroupedQuantity !== null &&
				this.item.maximumGroupedQuantity !== undefined
			) {
				maximumGroupedQuantity = this.item.maximumGroupedQuantity;
			}

			return maximumGroupedQuantity;
		},
		itemImage() {
			if (this.item && this.item.images && this.item.images.length) {
				return this.item.images[0].file.sources.large;
			} else {
				return "";
			}
		},
		formattedSelectedDate() {
			if (this.isSmarabioAfmaelis) {
				return "";
			}
			const shortedMonths = [
				"Jan",
				"Feb",
				"Mar",
				"Apr",
				"May",
				"June",
				"July",
				"Aug",
				"Sept",
				"Okt",
				"Nov",
				"Dec"
			];
			if (
				this.selectedDate !== null &&
				typeof this.selectedDate !== "undefined"
			) {
				const date = "00" + new Date(this.selectedDate).getUTCDate();
				const month = new Date(this.selectedDate).getUTCMonth();
				return (
					date.substr(date.length - 2, 2) +
					". " +
					this.$t(`shortedMonths.${shortedMonths[month]}`) +
					" | "
				);
			}
			return "";
		},
		sessionToken: {
			get() {
				return this.$store.state.sessionToken;
			},
			set(value) {
				this.$store.state.sessionToken = value;
			}
		},
		quantityOptions() {
			const options = [];

			for (
				let i = this.minimumOrderQuantity;
				i <= this.maximumOrderQuantity;
				i++
			) {
				if (this.hasPredefinedDates) {
					if (
						typeof this.item.maximumGroupedQuantity !== "undefined"
					) {
						if (
							i >
							this.item.maximumGroupedQuantity - this.bookingCount
						) {
							continue;
						}
					} else if (this.item.availability.current < i) {
						// Only if item does not have maximumGroupedQuantity defined do we check item's availability
						continue;
					}
				}

				options.push({
					key: i,
					text: i
				});
			}

			return options;
		},
		description() {
			if (typeof this.item !== "undefined" && this.item !== null) {
				// Check if key exists in locale  and if so, return its value
				const key = "item:description:value:" + this.item.uuid;
				if (this.$te(key)) {
					return this.$t(key);
				}

				// If key does not exist we at least want to return the description value of the item
				const { description } = this.item;
				if (
					typeof description !== "undefined" &&
					description !== null &&
					typeof description.value !== "undefined" &&
					description.value !== null
				) {
					return description.value;
				}
			}

			return null;
		},
		itemTitle() {
			if (typeof this.item !== "undefined" && this.item !== null) {
				const key = "item:title:" + this.item.uuid;
				if (this.$te(key)) {
					return this.$t(key);
				}
				return this.item.title;
			}
			return null;
		},
		channelUUID() {
			return this.$store.state.channelUUID;
		},
		requiredSlots() {
			if (!this.cartQuantity) {
				return 0;
			}
			if (!this.maximumGroupedQuantity) {
				return this.cartQuantity;
			}
			return Math.ceil(this.cartQuantity / this.maximumGroupedQuantity);
		},
		missingSlots() {
			return this.requiredSlots - this.selectedTimeSlots.length;
		},
		organizationUUID() {
			return this.$store.state.organizationUUID;
		},
		primaryColor() {
			return this.$store.getters.primaryColor;
		},
		positivePrimaryColor() {
			if (this.$store.getters.colorIsCompleteGrey(this.primaryColor)) {
				return "success";
			}
			return this.primaryColor;
		},
		textButtonColor() {
			if (this.colorIsLight(this.primaryColor)) {
				return "black--text";
			} else {
				return "white--text";
			}
		},
		organizationIsSmarabio() {
			return this.$store.getters.organizationIsSmarabio
		},
		organizationShowsAdditionalItemsAsAdditionalItems() {
			return this.$store.state.organizationUUID === '2baf8f91-c585-4092-a716-15724a98b76f' || this.$store.state.organizationUUID === '32036a02-fd37-4044-bbb1-e55970e4531f'
		},
		categoryIsSmarabioNamskeid() {
			return this.$store.getters.categoryIsNamskeid;
		},
		itemIsSmarabioNamskeid() {
			return this.$store.getters.itemIsNamskeid(this.item);
		},
		bookingItemHasUpsellItems() {
			// booking item has additional items

			if (
				typeof this.item !== "undefined" &&
				this.item !== null &&
				Array.isArray(this.item.additionalItems) &&
				this.item.additionalItems.length > 0
			) {
				return true;
			}

			return false;
			// return !!this.bookingUpsellItems
		},
		bookingUpsellItems() {
			if (typeof this.item !== "undefined" && this.item !== null) {
				if (
					Array.isArray(this.item.additionalItems) &&
					this.item.additionalItems.length > 0
				) {
					return this.item.additionalItems;
				}
			}
			return [];
		},
    selectedAdditionalItemsInStore() {
      return this.$store.state.selectedAdditionalItems
    },
    additionalItemsRequiredAttributes() {
      if(!Array.isArray(this.bookingUpsellItems)) {
        return []
      }

      const requiredAttributes = []
      for(let i = 0; i < this.bookingUpsellItems.length; i++) {
        const item = this.bookingUpsellItems[i]
        if(!item) {
          continue
        }
        if(!Array.isArray(item.variations)) {
          continue
        }

        for(let variationIndex = 0; variationIndex < item.variations.length; variationIndex++) {
          const variation = item.variations[variationIndex]
          if(!variation) {
            continue
          }
          if(!variation.attribute) {
            continue
          }
          if(!variation.attribute.required) {
            continue
          }
          if(requiredAttributes.some(a => a && a.uuid === variation.attribute.uuid)) {
            continue
          }

          requiredAttributes.push({ attribute: variation.attribute, item })
        }
      }

      return requiredAttributes
    },
    selectedAdditionalItemsRequiredAttributesSelected() {
      if(!Array.isArray(this.bookingUpsellItems)) {
        return []
      }

      const requiredAttributes = []
      for(let i = 0; i < this.selectedAdditionalItemsInStore.length; i++) {
        const item = this.selectedAdditionalItemsInStore[i]
        if(!item) {
          continue
        }
        if(!Array.isArray(item.variations)) {
          continue
        }

        for(let variationIndex = 0; variationIndex < item.variations.length; variationIndex++) {
          const variation = item.variations[variationIndex]
          if(!variation) {
            continue
          }
          if(!variation.attribute) {
            continue
          }
          if(!variation.attribute.required) {
            continue
          }
          if(requiredAttributes.some(a => a && a.uuid === variation.attribute.uuid)) {
            continue
          }

          requiredAttributes.push({ attribute: variation.attribute, item })
        }
      }

      return requiredAttributes
    },
    requiredVariationsNotYetSelected() {
      const notSelected = []
      for(let i = 0; i < this.additionalItemsRequiredAttributes.length; i++) {
        const required = this.additionalItemsRequiredAttributes[i]
        if(!required || !required.attribute) {
          continue
        }
        if(this.selectedAdditionalItemsRequiredAttributesSelected.some(a => a && a.attribute && a.attribute.uuid === required.attribute.uuid)) {
          continue
        }
        if(notSelected.some(a => a && a.attribute && a.attribute.uuid === required.attribute.uuid)) {
          continue
        }

        notSelected.push(required)
      }
      return notSelected
    }
	},
	methods: {
		formatDateAndTimeFromMillis(timeInMillis) {
			if(!timeInMillis) {
				return this.$t('later')
			}
			const date = new Date(timeInMillis)

			return date.toLocaleDateString() + ' ' + date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
		},
		variationPrice(variation) {
			if (typeof variation !== "undefined" && variation !== null) {
				if (
					typeof variation.price !== "undefined" &&
					variation.price !== null
				) {
					if (typeof variation.price.amount !== "undefined") {
						const price = JSON.parse(
							JSON.stringify(variation.price)
						);
						price.amount = this.getDiscountedAmount(price.amount);
						return price;
					}
				}
			}
			return null;
		},
		getDiscountedAmount(amount) {
			let calculatedAmount = amount;
			let percentOff = 0;
			if (typeof this.coupon !== "undefined" && this.coupon !== null) {
				if (
					typeof this.coupon.percentOff !== "undefined" &&
					this.coupon.percentOff !== null
				) {
					percentOff = this.coupon.percentOff;
				}
			}

			calculatedAmount = Math.ceil(
				calculatedAmount * ((100 - percentOff) / 100)
			);

			return calculatedAmount;
		},
		getPredefinedDates() {
			if (Array.isArray(this.item.dates) && this.item.dates.length > 0) {
				let categoryUUID = undefined;
				let maximumGroupedQuantity = undefined;

				if (
					this.inCategory !== null &&
					typeof this.inCategory !== "undefined" &&
					this.inCategory.uuid !== null &&
					typeof this.inCategory.uuid !== "undefined"
				) {
					categoryUUID = this.inCategory.uuid;
				}
				if (
					this.item.maximumGroupedQuantity !== null &&
					typeof this.item.maximumGroupedQuantity !== "undefined" &&
					this.item.maximumGroupedQuantity > 0
				) {
					maximumGroupedQuantity = this.item.maximumGroupedQuantity;
				} else {
					maximumGroupedQuantity = 99999;
				}

				this.$store
					.dispatch("getObjectsFromPredefinedDates", {
						dates: this.item.dates,
						itemUUID: this.item.uuid,
						maximumGroupedQuantity: maximumGroupedQuantity,
						categoryUUID: categoryUUID
					})
					.then(res => {
						const predefinedDates = [];
						res.forEach(date => {
							if (date.availableCount < this.quantity) {
								date.disabled = true;
								date.label =
									date.label + " " + this.$t("soldOut");
							}
							predefinedDates.push(date);
						});
						this.predefinedDates = predefinedDates;
					});
			}
		},
		getBookingCountByItem() {
			if (
				Array.isArray(this.selectedTimeSlots) &&
				this.selectedTimeSlots.length > 0
			) {
				if (this.selectedTimeSlots.length === 1) {
					const firstSlot = this.selectedTimeSlots[0];
					this.$store
						.dispatch("bookingCountsByItem", {
							item: this.item,
							startTimeInMillis: firstSlot.startTimeInMillis,
							endTimeInMillis: firstSlot.endTimeInMillis
						})
						.then(result => {
							this.bookingCount = result;
						});
				}
			}
		},
    variationQuantityByVariation(variation) {
      if(typeof this.selectedVariations === 'undefined' || this.selectedVariations === null) {
        return 0
      }
      return this.selectedVariations.filter(v => v && variation && v.uuid === variation.uuid).length
    },
		canIncrementVariation(variation) {
			return (
				this.cartQuantity < this.item.maximumOrderQuantity &&
				this.variationQuantityByVariation(variation) < this.item.maximumOrderQuantity
			);
		},
		canDecrementVariation(variation) {
			return this.variationQuantityByVariation(variation) > 0;
		},
		updateHasSlots(value) {
			this.hasSlots = value;
		},
		dayClick(e) {
			const parsedDate = e.id.split('-');
			this.selectedDate = new Date().setUTCFullYear(Number(parsedDate[0]), Number(parsedDate[1]) - 1, Number(parsedDate[2]));
		},
		indexOfVariation(variation) {
			for (let i = 0; i < this.item.variations.length; i++) {
				const variationInList = this.item.variations[i];
				if (variationInList.uuid === variation.uuid) {
					return i;
				}
			}
			return 0;
		},
		attributesByVariation(attribute) {
			if (
				this.item.variations === null ||
				this.item.variations === undefined
			) {
				return [];
			}

			return this.item.variations.filter(
				variation => variation.attribute.uuid === attribute.uuid
			);
		},
		decrementVariationQuantity(variation) {
			const variationIndex = this.selectedVariations.findIndex(v => v && variation && v.uuid === variation.uuid)
      if(variationIndex >= 0) {
        this.selectedVariations.splice(variationIndex, 1)
      }
		},
		incrementVariationQuantity(variation) {
      this.selectedVariations.push(variation)
		},
		variationQuantityOptions(variation) {
			const options = [];

			const remainingQuantity =
				this.maximumOrderQuantity - this.cartQuantity;

			for (let i = 0; i <= this.maximumOrderQuantity; i++) {
				let isBlocked = false;

				if (remainingQuantity < i && variation.value < i) {
					isBlocked = true;
				}

				options.push({
					key: i,
					text: i,
					disabled: isBlocked
				});
			}

			return options;
		},
		timeSlotsSelected(timeSlots) {
			if (this.hasError) {
				this.hasError = false;
				this.errorMessage = null;
			}
			this.selectedTimeSlots = timeSlots;
		},
		checkAvailability() {
			this.processing = true;
			this.fetchSessionToken().then(() => {
				this.showAvailability = true;
				this.processing = false;
			});

			window.parent.postMessage(
				JSON.stringify({
					event: "eventTracking",
					category: "schedule",
					action: "Checked Availability",
					label: "schedule"
				}),
				"*"
			);
		},
		colorIsLight(color) {
			return this.$store.getters.colorIsLight(color);
		},
		async bookItem() {
			this.processing = true;

			if (this.item.isAllDayEvent === true) {
				if (this.item.startDate && this.item.endDate) {
					let startsAtTime = this.item.startDate;
					let endsAtTime = this.item.endDate;
					if (startsAtTime.toString().length === 10) {
						startsAtTime = startsAtTime * 1000;
					}
					if (endsAtTime.toString().length === 10) {
						endsAtTime = endsAtTime * 1000;
					}
					this.selectedTimeSlots.push({
						startTimeInMillis: startsAtTime,
						endTimeInMillis: endsAtTime
					});
				}
				// if(this.bookingItemHasUpsellItems) {
				// 	this.showUpsell = true
				// 	this.processing = false
				// 	return
				// }

				this.addToCart();
				return;
			}

			if (!this.hasSlots) {
				this.hasError = true;
				this.errorMessage = this.$t("errors.fullyBooked");
				alert(this.errorMessage);
				this.processing = false;
				return;
			}

			if (
				!this.selectedTimeSlots ||
				(!Array.isArray(this.item.dates) &&
					this.requiredSlots !== this.selectedTimeSlots.length)
			) {
				this.hasError = true;
				this.errorMessage = this.$tc(
					"errors.missingSelection",
					this.requiredSlots,
					{ timeAmount: this.missingSlots }
				);
				alert(this.errorMessage);
				this.processing = false;
				return;
			}

			if (
				Array.isArray(this.selectedTimeSlots) &&
				this.selectedTimeSlots.length > 0
			) {
				for (
					let slotIndex = 0;
					slotIndex < this.selectedTimeSlots.length;
					slotIndex++
				) {
					const slot = this.selectedTimeSlots[slotIndex];

					const bookingCount = await this.$store
						.dispatch("bookingCountsByItem", {
							item: this.item,
							startTimeInMillis: slot.startTimeInMillis,
							endTimeInMillis: slot.endTimeInMillis
						})
						.then(result => {
							return result;
						});

					if (bookingCount && bookingCount < 1) {
						this.errorMessage = this.$t("errors.fullyBooked");
						alert(this.errorMessage);
						this.processing = false;
						return;
					}
				}
			}

			if (this.cartQuantity < 1) {
				this.errorMessage = this.$t("errors.invalidQuantity");
				alert(this.errorMessage);
				this.processing = false;
				return;
			}

			// if(this.bookingItemHasUpsellItems) {
			// 	this.showUpsell = true
			// 	this.processing = false
			// 	return
			// }
			this.addToCart();
		},
		getSessionToken() {
			let sessionToken = null;

			try {
				sessionToken = JSON.parse(localStorage.getItem("sessionToken"));
			} catch (e) {
				localStorage.removeItem("sessionToken");
			}

			this.sessionToken = sessionToken;
		},
		saveSessionToken(sessionToken) {
			localStorage.setItem("sessionToken", JSON.stringify(sessionToken));
		},
		fetchSessionToken() {
			const graphOptions = {
				headers: {
					"Content-Type": "application/json"
				}
			};

			let url = "https://graph.salescloud.is?";

			const graphBody = {
				query: "mutation { newAnonymousSession { session_token, ttl } }"
			};

			return axios
				.post(url, graphBody, graphOptions)
				.then(response => {
					this.sessionToken = response.data.data.newAnonymousSession;
					this.saveSessionToken(this.sessionToken);
					return response.data.data.newAnonymousSession;
				})
				.catch(() => {
					return null;
				});
		},
		addToCart() {
			if (typeof this.item !== "undefined" && this.item !== null) {
				this.addingToCart = true;

				this.fetchSessionToken().then(sessionToken => {
					const cartItems = [];
					const dataLayerItems = [];

					let languageOption = "is";
					if (
						this.$i18n.locale === "is" ||
						this.$i18n.locale === "en"
					) {
						languageOption = this.$i18n.locale;
					}

					let values = {};

					if (
						typeof this.coupon !== "undefined" &&
						this.coupon !== null
					) {
						values.coupon = this.coupon.code;
					}

					if (
						this.organizationIsSmarabio &&
						this.categoryIsSmarabioNamskeid &&
						this.itemIsSmarabioNamskeid
					) {
						if (this.quantity > 1) {
							values.amount = this.$store.getters.smarabioDiscountedPrice(
								this.item
							);
						}
					}

					// If we want to send changed price into checkout
					// if(this.isSmarabioAfmaelis) {
					// 	values.amount = this.smarabioAfmaelisConfirmationFee
					// }

					const maxGrouped = this.item.maximumGroupedQuantity;
					let quantityLeft = this.quantity.valueOf();

					values.timeSlots = this.selectedTimeSlots.map(timeSlot => {
						let q = 0;

						if (
							maxGrouped !== null &&
							maxGrouped !== undefined &&
							quantityLeft > maxGrouped
						) {
							q = maxGrouped.valueOf();
							quantityLeft = quantityLeft - maxGrouped;
						} else {
							q = quantityLeft.valueOf();
						}

						return {
							startsAtTime: timeSlot.startTimeInMillis / 1000,
							endsAtTime: Math.floor(
								timeSlot.endTimeInMillis / 1000
							),
							quantity: q
						};
					});

					let itemPrice = {
						amount: 0,
						currency_code: "ISK"
					};

					if (
						typeof this.item.price !== "undefined" &&
						this.item.price !== null
					) {
						if (
							typeof this.item.price.amount !== "undefined" &&
							this.item.price.amount > 0
						) {
							itemPrice = this.item.price;
						}
					}
					if (
						typeof this.item.sale_price !== "undefined" &&
						this.item.sale_price !== null
					) {
						if (
							typeof this.item.sale_price.amount !==
								"undefined" &&
							this.item.sale_price.amount > 0
						) {
							itemPrice = this.item.sale_price;
						}
					}

					if (this.hasVariationsAffectingMeasure) {
						for (let i in this.selectedVariations) {
							const variation = this.selectedVariations[i]

              // Check if we have already added to
              if(cartItems.some(item => item && item.variations.includes(variation.uuid))) {
                continue
              }

              const variationQuantity = this.variationQuantityByVariation(variation)

              if(variationQuantity < 1) {
                continue
              }

              let variationPrice = itemPrice;

							if (typeof variation.price !== "undefined" && variation.price !== null) {
								if (typeof variation.price.amount !== "undefined" && variation.price.amount > 0) {
									variationPrice = variation.price;
								}
							}

							cartItems.push({
								item: this.item.uuid,
								quantity: variationQuantity,
								variations: [variation.uuid],
								uiValues: jsesc(values, { json: true })
							});

							const dataLayerItem = {};
							dataLayerItem.item_name = this.item.title;
							dataLayerItem.item_id = this.item.sku;
							dataLayerItem.item_variant = variation.label;
							dataLayerItem.quantity = variation.value;

							if (variationPrice) {
								dataLayerItem.price = variationPrice.amount;
								dataLayerItem.currency =
									variationPrice.currency_code;
							}

							dataLayerItems.push(dataLayerItem);
						}
					} else {
						dataLayerItems.push({
							item_name: this.item.title,
							item_id: this.item.sku,
							price: itemPrice.amount,
							currency: itemPrice.currency_code,
							quantity: this.cartQuantity
						});

						cartItems.push({
							item: this.item.uuid,
							quantity: this.cartQuantity,
							uiValues: jsesc(values, { json: true })
						});
					}

					if (Array.isArray(this.selectedAdditionalItems) && this.selectedAdditionalItems.length > 0) {
						for (
							let i = 0;
							i < this.selectedAdditionalItems.length;
							i++
						) {
							const selectedAdditionalItem = this
								.selectedAdditionalItems[i];

							let selectedAdditionalItemPrice = {
								amount: 0,
								currency_code: "ISK"
							};

							if (
								typeof selectedAdditionalItem.price !==
									"undefined" &&
								selectedAdditionalItem.price !== null
							) {
								if (
									typeof selectedAdditionalItem.price
										.amount !== "undefined" &&
									selectedAdditionalItem.price.amount > 0
								) {
									selectedAdditionalItemPrice =
										selectedAdditionalItem.price;
									if (
										typeof selectedAdditionalItem.sale_price !==
											"undefined" &&
										selectedAdditionalItem.sale_price !==
											null
									) {
										if (
											typeof selectedAdditionalItem
												.sale_price.amount !==
												"undefined" &&
											selectedAdditionalItem.sale_price
												.amount > 0 &&
											selectedAdditionalItem.sale_price
												.amount <
												selectedAdditionalItem.price
													.amount
										) {
											selectedAdditionalItemPrice =
												selectedAdditionalItem.sale_price;
										}
									}
								}
							}

							if (
								selectedAdditionalItem.type === "giftcard" ||
								selectedAdditionalItem.type === "coupon"
							) {
								for (
									let i = 0;
									i < selectedAdditionalItem.quantity;
									i++
								) {
									cartItems.push({
										item: selectedAdditionalItem.uuid,
										quantity: 1
									});

									dataLayerItems.push({
										item_name: selectedAdditionalItem.title,
										item_id: selectedAdditionalItem.sku,
										price:
											selectedAdditionalItemPrice.amount,
										currency:
											selectedAdditionalItemPrice.currency_code,
										quantity: 1
									});
								}
							} else {
								dataLayerItems.push({
									item_name: selectedAdditionalItem.title,
									item_id: selectedAdditionalItem.sku,
									price: selectedAdditionalItemPrice.amount,
									currency:
										selectedAdditionalItemPrice.currency_code,
									quantity: selectedAdditionalItem.quantity
								});

								cartItems.push({
									item: selectedAdditionalItem.uuid,
									quantity: selectedAdditionalItem.quantity
								});
							}
						}
					}

          if(Array.isArray(this.selectedAdditionalItemsInStore) && this.selectedAdditionalItemsInStore.length > 0) {
            for(let additionalItemIndex = 0; additionalItemIndex < this.selectedAdditionalItemsInStore.length; additionalItemIndex++) {
              const additionalItem = this.selectedAdditionalItemsInStore[additionalItemIndex]
              if(!additionalItem) {
                continue
              }

              const cartItem = {
                item: additionalItem.uuid,
                quantity: additionalItem.quantity
              }

              if(Array.isArray(additionalItem.selectedVariations)) {
                cartItem.variations = additionalItem.selectedVariations.map(v => v.uuid)
              }

              cartItems.push(cartItem)
            }
          }

					// Sending cart items to top level window for tracking
					try {
						// Clear the previous ecommerce object
						window.parent.postMessage(
							JSON.stringify({ ecommerce: null }),
							"*"
						);

						// Send the Ecommerce items
						window.parent.postMessage(
							JSON.stringify({
								event: "add_to_cart",
								ecommerce: {
									items: dataLayerItems
								}
							}),
							"*"
						);
					} catch (e) {
						// Do nothing if failed
					}

					if (!this.inApp) {
						this.$store
							.dispatch("checkoutItems", {
								languageOption,
								values,
								cartItems,
								sessionToken,
								couponCode: this.coupon
									? this.coupon.code
									: null
							})
							.then(order => {
								if (
									typeof order !== "undefined" &&
									order !== null &&
									typeof order.uuid !== "undefined" &&
									order.uuid !== null
								) {
									// Only run if there is an actual response
									const channel = this.channelUUID;
									const orderUUID = order.uuid;

									try {
										// eslint-disable-next-line no-undef
										window.parent.postMessage(
											"closeResizer",
											"*"
										);
										window.parent.postMessage(
											JSON.stringify({
												event: "eventTracking",
												category: "schedule",
												action: "Continue to Checkout",
												label: "schedule"
											}),
											"*"
										);
									} catch (e) {
										// Do nothing
									}

									// eslint-disable-next-line no-unused-vars
									let checkoutURL =
										"https://checkout.salescloud.is/?time=" +
										Date.now() +
										"&dark=" +
										this.$vuetify.theme.dark +
										"&l=" +
										this.$store.state.locationUUID +
										"#" +
										this.organizationUUID +
										"/" +
										channel +
										"/" +
										orderUUID +
										"/checkout" +
										"?sessionToken=" +
										this.sessionToken.session_token +
										"&language=" +
										languageOption;
									if (
										this.$store.state.couponCode &&
										this.$store.state.coupon
									) {
										checkoutURL +=
											"&coupon=" +
											this.$store.state.couponCode;
									}
									if(this.isInIframe) {
										document.location = checkoutURL;
									}
									else {
										this.$store.commit('setCheckoutUrl', checkoutURL)
										setTimeout(() => {
											this.processing = false;
										}, 500)
									}
								} else {
									this.errorMessage =
										this.$t("errors.couldNotAddToCart") +
										" - " +
										this.$t("errors.fullyBooked");
									alert(this.errorMessage);
									this.processing = false;
								}
							})
							.catch(() => {
								this.errorMessage = this.$t(
									"errors.couldNotAddToCart"
								);
								alert(this.errorMessage);
								this.processing = false;
							})
							.finally(() => {});
					} else {
						/*eslint-disable no-undef*/
						SalesCloud.addLineItemToCurrentOrder(
							SalesCloud.getCurrentOrderUUID(),
							JSON.stringify({
								label: this.item.title,
								itemUUID: this.item.uuid,
								quantity: this.cartQuantity,
								amount: this.item.price.amount,
								currencyCode: this.item.price.currencyCode,
								data: values
							})
						);
					}
				});
			}
		},
		updateSelectedAdditionalItems(items) {
			this.selectedAdditionalItems = items;
		},
		currencyDisplay(price) {
			return this.$store.getters.getItemCurrencyDisplay(price);
		},
		variationDisplayPrice(variation) {
			if (typeof variation !== "undefined" && variation !== null) {
				if (
					typeof variation.price !== "undefined" &&
					variation.price !== null
				) {
					return this.$store.getters.getItemCurrencyDisplay(
						variation.price
					);
				}
			}
			return "";
		},
    getAttributeName(attribute) {
      return this.$store.getters.getAttributeName(attribute)
    },
    getItemTitle(item) {
      return this.$store.getters.getItemTitle(item)
    }
	}
};
</script>
