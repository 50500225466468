import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import i18n from "@/i18n";
import moment from 'moment'
import currencyFormatter from "currency-formatter"

import { DateTime, Settings } from "luxon"

// modules
import hardCode from "@/store/hardCode";
import order from "@/store/order";

Vue.use(Vuex)

/**
 * @property {String} primary_color
 * @property {String} accent_color
 * @property {Object} itemsList
 * @property {Object} price
 * @property {String} uuid
 */

const getUnixTimestamp = (timestamp) => {
	if(typeof timestamp === 'undefined' || timestamp === null) {
		return 0
	}
	// Remove dots in case stored as 1234567890.100
	const parsedTimestamp = timestamp.toString().replace(/\./g, '')

	return Number(parsedTimestamp.padEnd(13, '0'))
}

export default new Vuex.Store({
  state: {
    graphUrl: 'https://graph.salescloud.is',
		// graphUrl: 'http://localhost:8080',
    scheduleObject: null,
    organizationUUID: null,
	googleTracker: null,
	checkoutUrl: null,
    locationUUID: null,
		bookingUUID: null,
		organization: null,
		location: null,
		channel: null,
		channelUUID: null,
		categories: null,
		items: null,
		systemTime: null,
		systemTimeInMillis: null,
		gettingAvailableTimeSlots: false,
		reservingSlot: false,
		releasingSlot: false,
		sessionToken: null,
		selectedTimeSlots: [],
		itemUUIDsFilter: [],
		canCancelBooking: false,
		canChangeBooking: false,
		showScheduleAsCategories: false,
		categoryFilter: [],
		selectMultipleItems: false,
		showImages: true,
		booking: null,
		defaultLanguage: 'is',
		hideExtraInfo: false,
		translations: [],
		upsellItemsAsSwitch: false,
        showAllItemAvailability: false,
        mode: '0',
		mainButtonsRounded: false,
        mainButtonsColor: null,
        mainButtonsCapitalized: false,
        backgroundColor: null,
        textColor: null,
        primaryColor: null,
        minimalFooter: false,
        widgetUUID: null,
        darkTheme: false,
        hideItemPrice: false,
        widgetHasInitialized: true,
        useSlotPrices: false,
        activities: [],
        firstScreenThresholdWidth: 440,
        secondScreenThresholdWidth: 390,
        showBlockedSlots: true,
        showSoldOutSlots: true,
        couponCode: null,
        coupon: null,
        previewValues: null,
        activitiesCategoryUUID: null,
        selectedAdditionalItems: []
	},
	mutations: {
		setCheckoutUrl(state, url) {
			state.checkoutUrl = url
		},
        setActivitiesCategoryUUID(state, uuid) {
			state.activitiesCategoryUUID = uuid
        },
        setPreviewValues(state, values) {
			state.previewValues = values
        },
        setCouponCode(state, couponCode) {
			state.couponCode = couponCode
        },
		setCoupon(state, coupon) {
			state.coupon = coupon
		},
        setShowBlockedSlots(state, status) {
			state.showBlockedSlots = status
        },
		setShowSoldOutSlots(state, status) {
			state.showSoldOutSlots = status
		},
        setActivities(state, activities) {
			if(Array.isArray(activities)) {
				for(let index = 0; index < activities.length; index++) {
					if(Array.isArray(activities[index].dates)) {
						activities[index].dates = activities[index].dates.sort((a,b) => a - b)
					}
				}
			}
			state.activities = activities
        },
        setUseSlotPrices(state, status) {
			state.useSlotPrices = status
        },
        widgetHasInitialized(state, status) {
			state.widgetHasInitialized = status
        },
        setHideItemPrice(state, status) {
            state.hideItemPrice = status
        },
        setDarkTheme(state, status) {
			state.darkTheme = status
        },
        setWidgetUUID(state, uuid) {
			state.widgetUUID = uuid
        },
		setMinimalFooter(state, status) {
			state.minimalFooter = status
		},
        setPrimaryColor(state, color) {
			state.primaryColor = color
        },
        setMainButtonsColor(state, color) {
			state.mainButtonsColor = color
        },
		setMainButtonsCapitalized(state, status) {
			state.mainButtonsCapitalized = status
		},
		setBackgroundColor(state, color) {
			state.backgroundColor = color
		},
		setTextColor(state, color) {
			state.textColor = color
		},
		setMainButtonsRounded(state, status) {
			state.mainButtonsRounded = status
		},
		setMode(state, mode) {
			state.mode = mode
		},
		setShowAllItemAvailability(state, status) {
			state.showAllItemAvailability = status
		},
		setTranslations(state, translations) {
			const keys = Object.keys(translations)
			for(let i = 0; i < keys.length; i++) {
				i18n.mergeLocaleMessage(keys[i], translations[keys[i]])
			}
		},
		setHideExtraInfo(state, status) {
			state.hideExtraInfo = status
		},
		setDefaultLanguage(state, language) {
			if(language !== null && language !== undefined) {
				state.defaultLanguage = language
			}
		},
		setSelectMultipleItems(state, status) {
			state.selectMultipleItems = status
		},
		setCategoryFilter(state, categories) {
			state.categoryFilter = categories
		},
		setShowScheduleAsCategories(state, status) {
			state.showScheduleAsCategories = status
		},
		setCanCancelBooking(state, status) {
			state.canCancelBooking = status
		},
		setCanChangeBooking(state, status) {
			state.canChangeBooking = status
		},
		updateSystemTime(state, systemTime) {
			state.systemTimeInMillis = systemTime
		},
		updateSessionToken(state, sessionToken) {
			state.sessionToken = sessionToken
		},
		addToSelectedTimeSlots(state, slot) {
			state.selectedTimeSlots.push(slot)
		},
		removeFromSelectedTimeSlots(state, slot) {
			if(state.selectedTimeSlots.length < 2) {
				state.selectedTimeSlots = []
			} else {
				let index = state.selectedTimeSlots.findIndex(s => s.startTimeInMillis === slot.startTimeInMillis)
				state.selectedTimeSlots = state.selectedTimeSlots.slice(index, 1)
			}
		},
		updateReservingSlot(state, status) {
			state.reservingSlot = status
		},
		updateReleasingSlot(state, status) {
			state.releasingSlot = status
		},
		updateGettingAvailableTimeSlots(state, status) {
			state.gettingAvailableTimeSlots = status
		},
		updateOrganizationUUID(state, organizationUUID) {
			state.organizationUUID = organizationUUID
		},
		updateChannelUUID(state, channelUUID) {
			state.channelUUID = channelUUID
		},
		updateOrganization(state, organization) {
			state.organization = organization
		},
		updateLocationUUID(state, locationUUID) {
			state.locationUUID = locationUUID
		},
		updateBookingUUID(state, bookingUUID) {
			state.bookingUUID = bookingUUID
		},
		updateBooking(state, booking) {
			state.booking = booking
		},
		updateChannel(state, channel) {
			state.channel = channel
		},
		updateCategories(state, categories) {
			state.categories = categories
		},
		updateGoogleTracker(state, tracker) {
			state.googleTracker = tracker
		},
		updateItems(state, items) {
			if(!Array.isArray(items)) {
				state.items = []
				return
			}

			const nowInMillis = Date.now()
			const stateItems = []

			for(let i = 0; i < items.length; i++) {
				const item = items[i]
				if(typeof item === 'undefined' || item === null) {
					continue
				}

				if(item.title && item.title.toLowerCase().includes('borðabókun') && (!item.price || !item.price.amount || item.price.amount === 0)) {
					continue
				}

				if(typeof item.endDate !== 'undefined' && item.endDate !== null && item.endDate > 0 && item.endDate < nowInMillis) {
					continue
				}

				// Filter out possible null values that might cause fault in code
				if (Array.isArray(item.dates) && item.dates.length > 0) {
					item.dates = item.dates.filter(notNull => notNull)
				}

				if(Array.isArray(item.dates) && item.dates.length > 0) {
					let hasFutureDate = false

					for (let i in item.dates) {
						const dateInMillis = item.dates[i] * 1000
						if (dateInMillis > nowInMillis) {
							hasFutureDate = true
						}
						else {
							delete item.dates[i]
						}
					}

					if (!hasFutureDate) {
						continue
					}
				}


				if(Array.isArray(item.variations)) {
					for(let n = 0; n < item.variations.length; n++) {
						item.variations[n].value = 0
					}

					item.variations.sort((a, b) => {
						return a.sorting_weight - b.sorting_weight
					})
				}

				// Making sure not to allow inactive additional items to be shown
				if(Array.isArray(item.additionalItems)) {
					item.additionalItems = item.additionalItems.filter(item => item && item.status === true)
				}

				stateItems.push(item)
			}

			state.items = stateItems
		},
		updateLocation(state, location) {
			state.location = location
		},
		updateItemUUIDsFilter(state, itemUUIDsArray) {
			state.itemUUIDsFilter = itemUUIDsArray
		},
		setShowImages(state, value) {
			state.showImages = value
		},
		setUpsellItemsAsSwitch(state, status) {
			state.upsellItemsAsSwitch = status
		},
		setScheduleObject(state, obj) {
			state.scheduleObject = obj
		},
		setMetaPixelCode(state, code) {
			state.metaPixelCode = code
		},
		updateSelectedAdditionalItems(state, items) {
			state.selectedAdditionalItems = items
		}
	},
	actions: {
        setCoupon: ({ state, commit }, coupon) => {
			if(!coupon) {
				return null
			}

			// Store the coupon
            commit('setCouponCode', coupon)

            const body = {
				query: 'query Coupon($code: String!, $organization: String!) {\n' +
					'   coupon(code: $code, organization: $organization) {\n' +
					'       uuid\n' +
					'       label\n' +
					'       description\n' +
					'       code\n' +
					'       percentOff\n' +
					'   }\n' +
					'}',
                variables: {
					code: coupon,
                    organization: state.organizationUUID
                }
            }

			return axios.post(state.graphUrl, body).then(result => {
				if(result && result.data && result.data.data && result.data.data.coupon) {
					commit('setCoupon', result.data.data.coupon)
					return result.data.data.coupon
				}
				return null
			}).catch(() => {
				return null
			})
        },
		bookingCountsByItem: ({ state }, { item, startTimeInMillis, endTimeInMillis }) => {
			if(!item) {
				return null
			}

			const body = {
				query: 'query {\n' +
					'   bookingsCountByItem(organization: "' + state.organizationUUID + '", item: "' + item.uuid + '", startTimeInMillis: ' + startTimeInMillis + ', endTimeInMillis: ' + endTimeInMillis + ', location: "' + state.locationUUID + '")\n' +
					'}'
			}

			return axios.post(state.graphUrl, body).then(result => {
				if(result && result.data && result.data.data) {
					return result.data.data.bookingsCountByItem
				}
				return 0
			})
		},
		checkoutItems: ({state}, payload) => {
			const language = i18n.locale ? i18n.locale : 'is'
			const query = {
				query: 'mutation addMultipleItemsToCart($cartItems: [CartItem], $language: String, $couponCode: String) {\n' +
					'    addMultipleItemsToCart(sessionToken: "' + state.sessionToken.session_token + '", channel: "' + state.channelUUID + '", location: "' + state.locationUUID + '", items: $cartItems, language: $language, couponCode: $couponCode) {\n' +
					'     uuid\n' +
					'  }\n' +
					'}',
				variables: {
					cartItems: payload.cartItems,
					language,
					couponCode: payload.couponCode
				}
			}

			return axios.post(state.graphUrl, query).then(response => {
				return response.data.data.addMultipleItemsToCart
			}).catch(() => {
				return null
			})
		},
		getAvailableTimeSlots: ({state, commit}, payload) => {

			commit('updateGettingAvailableTimeSlots', true)

			let intervalBetweenSlotsInMinutes = null
			if(typeof state.scheduleObject !== 'undefined' && state.scheduleObject !== null) {
				if(typeof state.scheduleObject.intervalBetweenSlotsInMinutes === 'number' && state.scheduleObject.intervalBetweenSlotsInMinutes > 0) {
					intervalBetweenSlotsInMinutes = state.scheduleObject.intervalBetweenSlotsInMinutes
				}
			}

			const query = {
				query: 'query GetAvailableTimeSlots($sessionToken: String!, $organization: String!, $channel: String!, $startTimeInMillis: Float!, $endTimeInMillis: Float!, $item: String, $category: String, $location: String, $interval: Int) {\n' +
					'   availableTimeSlots(sessionToken: $sessionToken, organization: $organization, channel: $channel, startTimeInMillis: $startTimeInMillis, endTimeInMillis: $endTimeInMillis, item: $item, category: $category, location: $location, interval: $interval) {\n' +
					'       label\n' +
					'       timestampInMillis\n' +
					'       paddingInMillis\n' +
					'       reservedForCurrentSession\n' +
					'       availableCount\n' +
					'       bookingCount\n' +
					'       reservationCount\n' +
					'       isBlocked\n' +
					'   }\n' +
					'}',
				variables: {
					sessionToken: state.sessionToken.session_token,
					organization: state.organizationUUID,
					channel: state.channelUUID,
					startTimeInMillis: payload.startTimeInMillis,
					endTimeInMillis: payload.endTimeInMillis,
					item: payload.itemUUID,
					location: state.locationUUID,
					category: (payload.inCategory && payload.inCategory.uuid) ? payload.inCategory.uuid : null,
					interval: intervalBetweenSlotsInMinutes
				}
			}

			return axios.post(state.graphUrl, query)
				.finally(() => {
					commit('updateGettingAvailableTimeSlots', false)
				})
		},
		getAvailableTimeSlotsForMultipleItems: ({ state }, payload) => {
			if(!payload) {
				return null
			}

			if(!Array.isArray(payload.items) || payload.items.length <= 0) {
				return null
			}

			let queryBody = ''

			for(let itemIndex = 0; itemIndex < payload.items.length; itemIndex++) {
				const item = payload.items[itemIndex]
				// const alias = { index: itemIndex, uuid: item.uuid }
				queryBody +=  item.sku + ': availableTimeSlots(sessionToken: "' + state.sessionToken.session_token + '", item: "' + item.uuid + '", organization: "' + state.organizationUUID + '", location: "' + state.locationUUID + '", channel: "' + state.channelUUID + '", startTimeInMillis: ' + payload.startTimeInMillis + ', endTimeInMillis: ' + payload.endTimeInMillis + (payload.category ? ', category: "' + payload.category.uuid + '"' : '') + (payload.interval ? ', interval: ' + payload.interval : '' ) + ((payload.quantity && payload.quantity > 0) ? ', quantity: ' + payload.quantity : '') + ') {\n' +
					'     label\n' +
					'     timestampInMillis\n' +
					'     paddingInMillis\n' +
					'     reservedForCurrentSession\n' +
					'     availableCount\n' +
					'     bookingCount\n' +
					'     reservationCount\n' +
					'     isBlocked\n' +
					'     price{\n' +
					'           amount\n' +
					'           currency_code' +
					'     }\n' +
					'}\n'
			}

			const body = {
				query: 'query {\n' +
					queryBody +
					'}'
			}

			return axios.post(state.graphUrl, body).then(result => {
				if(result && result.data && result.data.data) {
					return result.data.data
				}
			})
		},
		getBookingsCountByItem: ({state}, {organization, item, startTimeInMillis, location, category}) => {
			let query = {}

			if(organization === null || typeof organization === 'undefined') {
				organization = state.organizationUUID
			}

			if(location === null || typeof location === 'undefined') {
				location = state.locationUUID
			}

			if (category !== null && typeof category !== 'undefined') {
				query = {
					query: 'query bookingsCountByItem {\n' +
						'  bookingsCountByItem(\n' +
						'    organization: "' + organization + '", \n' +
						'    item: "' + item + '", \n' +
						'    startTimeInMillis:' + startTimeInMillis + ', \n' +
						'    location: "' + location + '",\n' +
						'    category: "' + category + '"\n' +
						'  )\n' +
						'}'
				}
			} else {
				query = {
					query: 'query bookingsCountByItem {\n' +
						'  bookingsCountByItem(\n' +
						'    organization: "' + organization + '", \n' +
						'    item: "' + item + '", \n' +
						'    startTimeInMillis:' + startTimeInMillis + ', \n' +
						'    location: "' + location + '",\n' +
						'  )\n' +
						'}'
				}
			}

			return axios.post(state.graphUrl, query).then(res => {
				return res.data.data.bookingsCountByItem
			})
		},
		getObjectsFromPredefinedDates: async ({state, dispatch}, {dates, itemUUID, maximumGroupedQuantity, categoryUUID}) => {
			const now = Date.now()
			const parsedDates = []

			for(let i = 0; i < dates.length; i++) {
				if(dates[i] === null || typeof dates[i] === 'undefined') {
					continue
				}

				const dateInMillis = dates[i] * 1000

				if(dateInMillis < now) {
					continue
				}

				const payload = {
					item: itemUUID,
					startTimeInMillis: dateInMillis,
				}
				if(state.showScheduleAsCategories === true && categoryUUID !== null && typeof categoryUUID !== 'undefined') {
					payload.category = categoryUUID
				}

				const bookingCount = await dispatch('getBookingsCountByItem', payload).then(res => {
					return res
				})

				parsedDates.push({
					key: dateInMillis,
					label: moment.utc(dateInMillis).format('DD/MM/YYYY HH:mm'),
					availableCount: maximumGroupedQuantity - bookingCount,
					bookingCount: bookingCount
				})
			}
			return parsedDates
		},
		reserveAvailableTimeSlot: ({ state, commit }, payload) => {
			commit('updateReservingSlot', true)
			const query = {
				query: 'mutation ReserveAvailableTimeSlot($sessionToken: String!, $organization: String!, $item: String, $startTimeInMillis: Float!, $endTimeInMillis: Float!, $quantity: Int, $skipLock: Boolean) {\n' +
					'  reserveAvailableTimeSlot(sessionToken: $sessionToken, organization: $organization, item: $item, startTimeInMillis: $startTimeInMillis, endTimeInMillis: $endTimeInMillis, quantity: $quantity, skipLock: $skipLock)\n { uuid, success }' +
					'}',
				variables: {
					sessionToken: state.sessionToken ? state.sessionToken.session_token : '',
					organization: state.organizationUUID,
					item: payload.itemUUID,
					startTimeInMillis: payload.startTimeInMillis,
					endTimeInMillis: payload.endTimeInMillis,
					quantity: payload.quantity,
					skipLock: payload.skipLock
				}
			}

			return axios.post(state.graphUrl, query)
				.catch(() => {
					return { success: false }
				})
				.finally(() => {
					commit('updateReservingSlot', false)
				})
		},
		releaseAvailableTimeSlot: ({ state, commit }, payload) => {
			commit('updateReleasingSlot', true)

			const query = {
				query: 'mutation ReleaseAvailableTimeSlot($sessionToken: String!, $organization: String!, $item: String, $startTimeInMillis: Float!, $endTimeInMillis: Float!, $skipLock: Boolean) {\n' +
					'  releaseAvailableTimeSlot(sessionToken: $sessionToken, organization: $organization, item: $item, startTimeInMillis: $startTimeInMillis, endTimeInMillis: $endTimeInMillis, skipLock: $skipLock)\n' +
					'}',
				variables: {
					sessionToken: state.sessionToken ? state.sessionToken.session_token : '',
					organization: state.organizationUUID,
					item: payload.itemUUID,
					startTimeInMillis: payload.startTimeInMillis,
					endTimeInMillis: payload.endTimeInMillis,
					skipLock: payload.skipLock
				}
			}

			return axios.post(state.graphUrl, query)
				.catch(() => {
					return false
				}).finally(() => {
					commit('updateReleasingSlot', false)
				})
		},
		getInitialData: ({state, getters, commit}) => {

			const query = {
				query: 'query {\n' +
					'  channel(organization: "' + state.organizationUUID + '", uuid: "' + state.channelUUID + '") {\n' +
					'    label\n' +
					'    description\n' +
					'    ept\n' +
					'    created\n' +
					'    changed\n' +
					'    data\n' +
					'  }\n' +
					'  organization(uuid: "' + state.organizationUUID + '") {\n' +
					'    uuid\n' +
					'    label\n' +
					'    description\n' +
					'    email\n' +
					'    phone\n' +
					'    accent_color\n' +
					'    primary_color\n' +
					'    defaultTimezone\n' +
					'    logo {\n' +
					'      title\n' +
					'      alt\n' +
					'      file {\n' +
					'        sources {\n' +
					'          original\n' +
					'          thumbnail\n' +
					'          large\n' +
					'        }\n' +
					'      }\n' +
					'    }\n' +
					'  }\n' +
					'  location(organization: "' + state.organizationUUID + '", uuid: "' + state.locationUUID + '") {\n' +
					'    label\n' +
					'    description\n' +
					'    intervalBetweenSlotsInMillis\n' +
					'    timezone\n' +
					'    opening_hours{\n' +
					'      day\n' +
					'      starthours\n' +
					'      endhours\n' +
					'      comment\n' +
					'    }\n' +
					'  }\n' +
					'  itemsList(organization: "' + state.organizationUUID + '", sellOnline: true) {\n' +
					'    uuid\n' +
					'    title\n' +
					'    type\n' +
					'    sku\n' +
					'    sorting_weight\n' +
					'    unavailableDates\n' +
					'    isAllDayEvent\n' +
					'    images {\n' +
					'      file {\n' +
					'        sources {\n' +
					'          thumbnail\n' +
					'          original\n' +
					'          large\n' +
					'        }\n' +
					'      }\n' +
					'    }\n' +
					'    variations {\n' +
					'      uuid\n' +
					'      label\n' +
					'    }\n' +
					'    description {\n' +
					'      value\n' +
					'    }\n' +
					'    price {\n' +
					'      amount\n' +
					'      currency_code\n' +
					'    }\n' +
					'    dates\n' +
					'    location{\n' +
					'     label\n' +
					'    }\n' +
					'    minimumOrderQuantity\n' +
					'    maximumOrderQuantity\n' +
					'    maximumGroupedQuantity\n' +
					'    maxAheadBookingInDays\n' +
					'    defaultQuantity\n' +
					'    reservationMode\n' +
					'    unitOfMeasure\n' +
					'    startDate\n' +
					'    additionalItems{\n' +
					'       uuid\n' +
					'       title\n' +
					'       sku\n' +
					'       type\n' +
					'       status\n' +
					'       sorting_weight\n' +
					'       minimumOrderQuantity\n' +
					'       maximumOrderQuantity\n' +
					'       maximumGroupedQuantity' +
					'       defaultQuantity\n' +
					'       unavailableDates\n' +
					'       images{\n' +
					'         file{\n' +
					'           sources{\n' +
					'             large\n' +
					'           }\n' +
					'         }\n' +
					'       }\n' +
					'       price{\n' +
					'         amount\n' +
					'         currency_code\n' +
					'       }\n' +
					'       sale_price{\n' +
					'         amount\n' +
					'         currency_code\n' +
					'       }\n' +
					'       description{\n' +
					'         value\n' +
					'         summary\n' +
					'       }\n' +
					'       availability{\n' +
					'         current\n' +
					'       }\n' +
					'       mainCategory {\n' +
					'           uuid\n' +
					'           name\n' +
					'           description\n' +
					'           weight\n' +
					'           required\n' +
					'           single\n' +
					'           multiple\n' +
					'           maxVariations\n' +
					'           opening_hours {\n' +
					'               day\n' +
					'               starthours\n' +
					'               endhours\n' +
					'           }\n' +
					'       }\n' +
					'       categories {\n' +
					'           uuid\n' +
					'           name\n' +
					'           description\n' +
					'           weight\n' +
					'           required\n' +
					'           single\n' +
					'           multiple\n' +
					'           maxVariations\n' +
					'           opening_hours {\n' +
					'               day\n' +
					'               starthours\n' +
					'               endhours\n' +
					'           }\n' +
					'       }\n' +
					'       variations {\n' +
					'           uuid\n' +
					'           label\n' +
					'           price {\n' +
					'               amount\n' +
					'               currency_code\n' +
					'           }\n' +
					'           sorting_weight\n' +
					'           attribute {\n' +
					'               uuid\n' +
					'               name\n' +
					'               description\n' +
					'               weight\n' +
					'               required\n' +
					'               single\n' +
					'               multiple\n' +
					'               maxVariations\n' +
					'           }\n' +
					'       }\n' +
					'       default_variations {\n' +
					'           uuid\n' +
					'           label\n' +
					'           price {\n' +
					'               amount\n' +
					'               currency_code\n' +
					'           }\n' +
					'           sorting_weight\n' +
					'           attribute {\n' +
					'               uuid\n' +
					'               name\n' +
					'               description\n' +
					'               weight\n' +
					'               required\n' +
					'               single\n' +
					'               multiple\n' +
					'               maxVariations\n' +
					'           }\n' +
					'       }\n' +
					'    }\n' +
					'    availability {\n' +
					'      current\n' +
					'    }\n' +
					'    endDate\n' +
					'    startSellTimeInMillis\n' +
					'    endSellTimeInMillis\n' +
					'    durationInMinutes\n' +
					'    variations {\n' +
					'      label\n' +
					'      sorting_weight\n' +
					'      price {\n' +
					'       amount\n' +
					'       currency_code\n' +
					'      }\n' +
					'      attribute {\n' +
					'       uuid\n' +
					'       name\n' +
					'      }\n' +
					'    }\n' +
					'    categories {\n' +
					'      name\n' +
					'      description\n' +
					'      uuid\n' +
					'      weight\n' +
					'      single\n' +
					'      required\n' +
					'      administrative\n' +
					'      primary_color\n' +
					'      opening_hours{\n' +
					'         day\n' +
					'         starthours\n' +
					'         endhours\n' +
					'         comment\n' +
					'      }\n' +
					'      image{\n' +
					'         title\n' +
					'         alt\n' +
					'         file{\n' +
					'           sources{\n' +
					'             large\n' +
					'           }\n' +
					'         }\n' +
					'      }\n' +
					'    }\n' +
					'  }\n' +
					'  systemTimeInMillis\n' +
					"  organizationTranslationsI18nFormatted(organization: \"" + state.organizationUUID + "\") \n" +
					'}\n'
			}


			axios.post(state.graphUrl, query).then(response => {
				if(response.data) {
					commit('updateSystemTime', response.data.data.systemTimeInMillis)
					commit('updateItems', response.data.data.itemsList.filter(item => item.type === 'event'))
					commit('updateOrganization', response.data.data.organization)
					commit('updateChannel', response.data.data.channel)
					commit('updateLocation', response.data.data.location)
					commit('setTranslations', response.data.data.organizationTranslationsI18nFormatted)
					if(!state.previewValues) {
						commit('setSelectMultipleItems', getters.shouldShowMultipleSelect)
					}

					if(state.activitiesCategoryUUID) {
						commit('setActivities', response.data.data.itemsList.filter(item => item && Array.isArray(item.categories) && item.categories.some(category => category && category.uuid === state.activitiesCategoryUUID)))
					}
				}
			})
		},
		getBooking: ({state, commit}) => {
			if(state.bookingUUID === null || state.bookingUUID === undefined || state.bookingUUID === '') {
				return null
			}

			if(state.organizationUUID === null || state.organizationUUID === undefined || state.organizationUUID === '') {
				return null
			}

			const query = {
				query: 'query {\n' +
					'  booking(bookingUUID: "' + state.bookingUUID + '", organizationUUID: "' + state.organizationUUID + '") {\n' +
					'    created\n' +
					'    changed\n' +
					'    status\n' +
					'    description\n' +
					'    redeemedAt\n' +
					'    booking_id\n' +
					'    uuid\n' +
					'    startsAtTime\n' +
					'    endsAtTime\n' +
					'    customer {\n' +
					'      label\n' +
					'      ssn\n' +
					'      address {\n' +
					'        name_line\n' +
					'      }\n' +
					'      email\n' +
					'      phone\n' +
					'      status\n' +
					'      exclusive\n' +
					'    }\n' +
					'    co {\n' +
					'      address {\n' +
					'        name_line\n' +
					'      }\n' +
					'      label\n' +
					'      ssn\n' +
					'      email\n' +
					'      phone\n' +
					'      exclusive\n' +
					'    }\n' +
					'    item {\n' +
					'    	 uuid\n' +
					'    	 title\n' +
					'    	 type\n' +
					'    	 sku\n' +
					'    	 sorting_weight\n' +
					'    	 unavailableDates\n' +
					'    	 isAllDayEvent\n' +
					'    	 images {\n' +
					'    	   file {\n' +
					'    	     sources {\n' +
					'    	       thumbnail\n' +
					'    	       original\n' +
					'    	       large\n' +
					'    	     }\n' +
					'    	   }\n' +
					'    	 }\n' +
					'    	 variations {\n' +
					'    	   uuid\n' +
					'    	   label\n' +
					'    	 }\n' +
					'    	 description {\n' +
					'    	   value\n' +
					'    	 }\n' +
					'    	 price {\n' +
					'    	   amount\n' +
					'    	   currency_code\n' +
					'    	 }\n' +
					'    	 dates\n' +
					'    	 location{\n' +
					'    	  uuid\n' +
					'    	  label\n' +
					'    	 }\n' +
					'    	 minimumOrderQuantity\n' +
					'    	 maximumOrderQuantity\n' +
					'    	 maximumGroupedQuantity\n' +
					'    	 maxAheadBookingInDays\n' +
					'    	 defaultQuantity\n' +
					'    	 reservationMode\n' +
					'    	 unitOfMeasure\n' +
					'    	 startDate\n' +
					'		 }' +
					'    organization {\n' +
					'      primary_color\n' +
					'      uuid\n' +
					'    }\n' +
					'  }\n' +
					'  systemTimeInMillis\n' +
					'}\n'
			}

			return axios.post(state.graphUrl, query).then(response => {
				if(response.data) {
					commit('updateSystemTime', response.data.data.systemTimeInMillis)
					commit('updateBooking', response.data.data.booking)
					commit('updateLocationUUID', response.data.data.booking.item.location.uuid)
				}
			})
		},
		getBookingByID: ({state, commit}, payload) => {
			if (payload.bookingID === null || payload.bookingID === undefined || payload.bookingID === '') {
				return null
			}

			if (state.organizationUUID === null || state.organizationUUID === undefined || state.organizationUUID === '') {
				return null
			}

			if (payload.email === null || payload.email === undefined || payload.email === '') {
				return null
			}

			const query = {
				query: 'query {\n' +
					'  bookingByID(bookingID: "' + payload.bookingID + '", organizationUUID: "' + state.organizationUUID + '", email: "' + payload.email + '") {\n' +
					'    created\n' +
					'    changed\n' +
					'    status\n' +
					'    description\n' +
					'    redeemedAt\n' +
					'    booking_id\n' +
					'    uuid\n' +
					'    channel\n' +
					'    startsAtTime\n' +
					'    endsAtTime\n' +
					'    order\n' +
					'    customer {\n' +
					'      label\n' +
					'      ssn\n' +
					'      address {\n' +
					'        name_line\n' +
					'      }\n' +
					'      email\n' +
					'      phone\n' +
					'      status\n' +
					'      exclusive\n' +
					'    }\n' +
					'    co {\n' +
					'      address {\n' +
					'        name_line\n' +
					'      }\n' +
					'      label\n' +
					'      ssn\n' +
					'      email\n' +
					'      phone\n' +
					'      exclusive\n' +
					'    }\n' +
					'    item {\n' +
					'    	 uuid\n' +
					'    	 title\n' +
					'    	 type\n' +
					'    	 sku\n' +
					'    	 sorting_weight\n' +
					'    	 unavailableDates\n' +
					'    	 isAllDayEvent\n' +
					'    	 images {\n' +
					'    	   file {\n' +
					'    	     sources {\n' +
					'    	       thumbnail\n' +
					'    	       original\n' +
					'    	       large\n' +
					'    	     }\n' +
					'    	   }\n' +
					'    	 }\n' +
					'    	 variations {\n' +
					'    	   uuid\n' +
					'    	   label\n' +
					'    	 }\n' +
					'    	 description {\n' +
					'    	   value\n' +
					'    	 }\n' +
					'    	 price {\n' +
					'    	   amount\n' +
					'    	   currency_code\n' +
					'    	 }\n' +
					'    	 dates\n' +
					'    	 location{\n' +
					'    	  uuid\n' +
					'    	  label\n' +
					'    	 }\n' +
					'    	 minimumOrderQuantity\n' +
					'    	 maximumOrderQuantity\n' +
					'    	 maximumGroupedQuantity\n' +
					'    	 maxAheadBookingInDays\n' +
					'    	 defaultQuantity\n' +
					'    	 reservationMode\n' +
					'    	 unitOfMeasure\n' +
					'    	 startDate\n' +
					'		 }' +
					'    organization {\n' +
					'      primary_color\n' +
					'      uuid\n' +
					'    }\n' +
					'  }\n' +
					'  systemTimeInMillis\n' +
					'}\n'
			}
			return axios.post(state.graphUrl, query).then(response => {
				if (response.data && response.data.data && response.data.data.bookingByID) {
					commit('updateSystemTime', response.data.data.systemTimeInMillis)
					commit('updateBookingUUID', response.data.data.bookingByID.uuid)
					commit('updateBooking', response.data.data.bookingByID)
					commit('updateLocationUUID', response.data.data.bookingByID.item.location.uuid)
					if(response.data.data.bookingByID.channel) {
						commit('updateChannelUUID', response.data.data.bookingByID.channel)
					}
				} else {
					throw new Error('Could not find booking!')
				}
			}).catch(error => {
				throw new Error('Error getting BookingByID: ', error)
			})
		},
		rescheduleBooking: ({state}, payload) => {
			let booking = {
				uuid: state.bookingUUID,
				startsAtTime: payload.startsAtTime,
				endsAtTime: payload.endsAtTime,
				notifyCustomer: true
			}

			const query = {
				query: 'mutation rescheduleBooking($booking: RescheduleBooking!) {\n' +
					'  rescheduleBooking(booking: $booking, organizationUUID: "' + state.organizationUUID + '") {\n' +
					'    uuid\n' +
					'    startsAtTime\n' +
					'    endsAtTime\n' +
					'  }\n' +
					'}',
				variables: {
					"booking": booking
				}
			}

			return axios.post(state.graphUrl, query).then(response => {
				if(response.data.data !== null && response.data.data !== undefined) {
					return response.data.data
				}
			})
		},
		getWidgetData: ({ state, commit, getters }) => {
			commit('widgetHasInitialized', false)
			const body = {
				query: 'query {\n' +
					'   systemTimeInMillis\n' +
					'   scheduleWidget(uuid: "' + state.widgetUUID + '") {\n' +
					'       organization {\n' +
					'           uuid\n' +
					'           label\n' +
					'           description\n' +
					'           email\n' +
					'           phone\n' +
					'           accent_color\n' +
					'           primary_color\n' +
					'           defaultTimezone\n' +
					'           logo {\n' +
					'               title\n' +
					'               alt\n' +
					'               file {\n' +
					'                   sources {\n' +
					'                       original\n' +
					'                       thumbnail\n' +
					'                       large\n' +
					'                   }\n' +
					'               }\n' +
					'           }\n' +
					'       }\n' +
					'       channel {\n' +
					'           uuid\n' +
					'           label\n' +
					'           description\n' +
					'           ept\n' +
					'           data\n' +
					'       }\n' +
					'       location {\n' +
					'           uuid\n' +
					'           label\n' +
					'           description\n' +
					'           intervalBetweenSlotsInMillis\n' +
					'           timezone\n' +
					'			opening_hours{\n' +
					'				day\n' +
					'				starthours\n' +
					'				endhours\n' +
					'				comment\n' +
					'			}\n' +
					'       }\n' +
					'       items {\n' +
					'           uuid\n' +
					'           title\n' +
					'           type\n' +
					'           sku\n' +
					'           status\n' +
					'           sorting_weight\n' +
					'           unavailableDates\n' +
					'           isAllDayEvent\n' +
					'           images {\n' +
					'               file {\n' +
					'                   sources {\n' +
					'                       thumbnail\n' +
					'                       original\n' +
					'                       large\n' +
					'                   }\n' +
					'               }\n' +
					'           }\n' +
					'           variations {\n' +
					'               uuid\n' +
					'               label\n' +
					'               sorting_weight\n' +
					'               price {\n' +
					'                   amount\n' +
					'                   currency_code\n' +
					'               }\n' +
					'               attribute {\n' +
					'                   uuid\n' +
					'                   name\n' +
					'                   description\n' +
					'                   weight\n' +
					'                   required\n' +
					'                   single\n' +
					'                   multiple\n' +
					'                   maxVariations\n' +
					'               }\n' +
					'           }\n' +
					'           description {\n' +
					'               value\n' +
					'           }\n' +
					'           price {\n' +
					'               amount\n' +
					'               currency_code\n' +
					'           }\n' +
					'           dates\n' +
					'           location {\n' +
					'               uuid\n' +
					'               label\n' +
					'           }\n' +
					'           minimumOrderQuantity\n' +
					'           maximumOrderQuantity\n' +
					'           maximumGroupedQuantity\n' +
					'           maxAheadBookingInDays\n' +
					'           defaultQuantity\n' +
					'           reservationMode\n' +
					'           unitOfMeasure\n' +
					'           startDate\n' +
					'           startSellTimeInMillis\n' +
					'           endSellTimeInMillis\n' +
					'           additionalItems {\n' +
					'               uuid\n' +
					'               title\n' +
					'               sku\n' +
					'               status\n' +
					'               type\n' +
					'               sorting_weight\n' +
					'               minimumOrderQuantity\n' +
					'               maximumOrderQuantity\n' +
					'               defaultQuantity\n' +
					'               unavailableDates\n' +
					'               images {\n' +
					'                   file {\n' +
					'                       sources {\n' +
					'                           large\n' +
					'                       }\n' +
					'                   }\n' +
					'               }\n' +
					'               price {\n' +
					'                   amount\n' +
					'                   currency_code\n' +
					'               }\n' +
					'               sale_price {\n' +
					'                   amount\n' +
					'                   currency_code\n' +
					'               }\n' +
					'               description {\n' +
					'                   value\n' +
					'                   summary\n' +
					'               }\n' +
					'               availability {\n' +
					'                   current\n' +
					'               }\n' +
					'               mainCategory {\n' +
					'                   uuid\n' +
					'                   name\n' +
					'                   description\n' +
					'                   weight\n' +
					'                   required\n' +
					'                   single\n' +
					'                   multiple\n' +
					'                   maxVariations\n' +
					'                   opening_hours {\n' +
					'                       day\n' +
					'                       starthours\n' +
					'                       endhours\n' +
					'                   }\n' +
					'               }\n' +
					'               categories {\n' +
					'                   uuid\n' +
					'                   name\n' +
					'                   description\n' +
					'                   weight\n' +
					'                   required\n' +
					'                   single\n' +
					'                   multiple\n' +
					'                   maxVariations\n' +
					'                   opening_hours {\n' +
					'                       day\n' +
					'                       starthours\n' +
					'                       endhours\n' +
					'                   }\n' +
					'               }\n' +
					'               variations {\n' +
					'                   uuid\n' +
					'                   label\n' +
					'                   price {\n' +
					'                       amount\n' +
					'                       currency_code\n' +
					'                   }\n' +
					'                   sorting_weight\n' +
					'                   attribute {\n' +
					'                       uuid\n' +
					'                       name\n' +
					'                       description\n' +
					'                       weight\n' +
					'                       required\n' +
					'                       single\n' +
					'                       multiple\n' +
					'                       maxVariations\n' +
					'                   }\n' +
					'               }\n' +
					'               default_variations {\n' +
					'                   uuid\n' +
					'                   label\n' +
					'                   price {\n' +
					'                       amount\n' +
					'                       currency_code\n' +
					'                   }\n' +
					'                   sorting_weight\n' +
					'                   attribute {\n' +
					'                       uuid\n' +
					'                       name\n' +
					'                       description\n' +
					'                       weight\n' +
					'                       required\n' +
					'                       single\n' +
					'                       multiple\n' +
					'                       maxVariations\n' +
					'                   }\n' +
					'               }\n' +
					'           }\n' +
					'           availability {\n' +
					'               current\n' +
					'           }\n' +
					'           endDate\n' +
					'           durationInMinutes\n' +
					'           categories {\n' +
					'               uuid\n' +
					'               name\n' +
					'               description\n' +
					'               weight\n' +
					'               single\n' +
					'               required\n' +
					'               administrative\n' +
					'               primary_color\n' +
					'               opening_hours {\n' +
					'                   day\n' +
					'                   starthours\n' +
					'                   endhours\n' +
					'                   comment\n' +
					'               }\n' +
					'               image {\n' +
					'                   title\n' +
					'                   alt\n' +
					'                   file {\n' +
					'                       sources {\n' +
					'                           large\n' +
					'                       }\n' +
					'                   }\n' +
					'               }\n' +
					'           }\n' +
					'       }\n' +
					'       categories {\n' +
					'           uuid\n' +
					'           name\n' +
					'           description\n' +
					'           weight\n' +
					'           single\n' +
					'           required\n' +
					'           administrative\n' +
					'           primary_color\n' +
					'           opening_hours {\n' +
					'               day\n' +
					'               starthours\n' +
					'               endhours\n' +
					'               comment\n' +
					'           }\n' +
					'       }\n' +
					'       activitiesCategory {\n' +
					'           uuid\n' +
					'           name\n' +
					'           description\n' +
					'           weight\n' +
					'           single\n' +
					'           required\n' +
					'           administrative\n' +
					'           primary_color\n' +
					'           opening_hours {\n' +
					'               day\n' +
					'               starthours\n' +
					'               endhours\n' +
					'               comment\n' +
					'           }\n' +
					'       }\n' +
					'       activities {\n' +
					'           uuid\n' +
					'           title\n' +
					'           type\n' +
					'           sku\n' +
					'           sorting_weight\n' +
					'           unavailableDates\n' +
					'           isAllDayEvent\n' +
					'           images {\n' +
					'               file {\n' +
					'                   sources {\n' +
					'                       thumbnail\n' +
					'                       original\n' +
					'                       large\n' +
					'                   }\n' +
					'               }\n' +
					'           }\n' +
					'           variations {\n' +
					'               uuid\n' +
					'               label\n' +
					'               sorting_weight\n' +
					'               price {\n' +
					'                   amount\n' +
					'                   currency_code\n' +
					'               }\n' +
					'               attribute {\n' +
					'                   uuid\n' +
					'                   name\n' +
					'               }\n' +
					'           }\n' +
					'           description {\n' +
					'               value\n' +
					'           }\n' +
					'           price {\n' +
					'               amount\n' +
					'               currency_code\n' +
					'           }\n' +
					'           dates\n' +
					'           location {\n' +
					'               uuid\n' +
					'               label\n' +
					'           }\n' +
					'           minimumOrderQuantity\n' +
					'           maximumOrderQuantity\n' +
					'           maximumGroupedQuantity\n' +
					'           maxAheadBookingInDays\n' +
					'           defaultQuantity\n' +
					'           reservationMode\n' +
					'           unitOfMeasure\n' +
					'           startDate\n' +
					'           startSellTimeInMillis\n' +
					'           endSellTimeInMillis\n' +
					'           additionalItems {\n' +
					'               uuid\n' +
					'               title\n' +
					'               sku\n' +
					'               type\n' +
					'               sorting_weight\n' +
					'               minimumOrderQuantity\n' +
					'               maximumOrderQuantity\n' +
					'               defaultQuantity\n' +
					'               unavailableDates\n' +
					'               images {\n' +
					'                   file {\n' +
					'                       sources {\n' +
					'                           large\n' +
					'                       }\n' +
					'                   }\n' +
					'               }\n' +
					'               price {\n' +
					'                   amount\n' +
					'                   currency_code\n' +
					'               }\n' +
					'               sale_price {\n' +
					'                   amount\n' +
					'                   currency_code\n' +
					'               }\n' +
					'               description {\n' +
					'                   value\n' +
					'                   summary\n' +
					'               }\n' +
					'               availability {\n' +
					'                   current\n' +
					'               }\n' +
					'           }\n' +
					'           availability {\n' +
					'               current\n' +
					'           }\n' +
					'           endDate\n' +
					'           durationInMinutes\n' +
					'           categories {\n' +
					'               uuid\n' +
					'               name\n' +
					'               description\n' +
					'               weight\n' +
					'               single\n' +
					'               required\n' +
					'               administrative\n' +
					'               primary_color\n' +
					'               opening_hours {\n' +
					'                   day\n' +
					'                   starthours\n' +
					'                   endhours\n' +
					'                   comment\n' +
					'               }\n' +
					'               image {\n' +
					'                   title\n' +
					'                   alt\n' +
					'                   file {\n' +
					'                       sources {\n' +
					'                           large\n' +
					'                       }\n' +
					'                   }\n' +
					'               }\n' +
					'           }\n' +
					'       }\n' +
					'       darkTheme\n' +
					'       showImages\n' +
					'       booking\n' +
					'       defaultLanguage\n' +
					'       hideExtraInfo\n' +
					'       showUpsellItemsAsSwitch\n' +
					'       mode\n' +
					'       mainButtonsRounded\n' +
					'       mainButtonsCapitalized\n' +
					'       mainButtonsColor\n' +
					'       backgroundColor\n' +
					'       textColor\n' +
					'       primaryColor\n' +
					'       minimalFooter\n' +
					'       hideItemPrice\n' +
					'       useSlotPrices\n' +
					'       showAsCategories\n' +
					'       intervalBetweenSlotsInMinutes\n' +
					'		metaPixelCode\n' +
					'   }\n' +
					'}'
			}
			return axios.post(state.graphUrl, body).then(result => {
				if(result && result.data && result.data.data) {

					commit('updateSystemTime', result.data.data.systemTimeInMillis)

					if(result.data.data.scheduleWidget) {
						commit('setScheduleObject', result.data.data.scheduleWidget)
						const {
							organization, channel, location, items, categories,
							darkTheme, showImages, booking, defaultLanguage, hideExtraInfo, showUpsellItemsAsSwitch,
							mode, mainButtonsRounded, mainButtonsCapitalized, mainButtonsColor, backgroundColor,
							textColor, primaryColor, minimalFooter, hideItemPrice, useSlotPrices, activities, showAsCategories,
							metaPixelCode, allowSelectionOfMultipleItems
						} = result.data.data.scheduleWidget

						if(organization) {
							commit('updateOrganizationUUID', organization.uuid)
							commit('updateOrganization', organization)
						}

						if(channel) {
							commit('updateChannelUUID', channel.uuid)
							commit('updateChannel', channel)
						}

						if(location) {
							commit('updateLocationUUID', location.uuid)
							commit('updateLocation', location)
						}

						commit('updateItems', items)
						commit('updateCategories', categories)
						if(Array.isArray(categories) && categories.length > 0) {
							commit('setCategoryFilter', categories.filter(c => c && c.uuid).map(c => c && c.uuid))
						}

						// Config
						commit('setDarkTheme', darkTheme)
						commit('setShowImages', showImages)
						commit('updateBookingUUID', booking)

						commit('setHideExtraInfo', hideExtraInfo)
						commit('setUpsellItemsAsSwitch', showUpsellItemsAsSwitch)
						commit('setMode', mode)
						commit('setMainButtonsRounded', mainButtonsRounded)
						commit('setMainButtonsCapitalized', mainButtonsCapitalized)
						commit('setMainButtonsColor', mainButtonsColor)
						commit('setBackgroundColor', backgroundColor)
						commit('setTextColor', textColor)
						commit('setPrimaryColor', primaryColor)
						commit('setMinimalFooter', minimalFooter)
						commit('setHideItemPrice', hideItemPrice)
						commit('setUseSlotPrices', useSlotPrices)
						commit('setShowScheduleAsCategories', showAsCategories)

						commit('setSelectMultipleItems', Boolean(allowSelectionOfMultipleItems) || getters.shouldShowMultipleSelect)

						commit('setActivities', activities)
						commit('setMetaPixelCode', metaPixelCode)

						const secondaryBody = {
							query: 'query {\n' +
								'   organizationTranslationsI18nFormatted(organization: "' + state.organizationUUID + '") \n' +
								'}'
						}

						return axios.post(state.graphUrl, secondaryBody).then(secondaryResult => {
							if(secondaryResult && secondaryResult.data && secondaryResult.data.data) {
								commit('setTranslations', secondaryResult.data.data.organizationTranslationsI18nFormatted)
							}
							commit('setDefaultLanguage', defaultLanguage)
							commit('widgetHasInitialized', true)
							return result.data.data.scheduleWidget
						})
					}
				}
				return null
			})
		},
		setPreviewValues: ({ commit, dispatch, getters }, values) => {
			commit('setPreviewValues', values)
			commit('setScheduleObject', values)
			const {
				organization, channel, categories, backgroundColor, activitiesCategory, darkTheme, defaultLanguage,
				hideExtraInfo, hideItemPrice, items, location, mainButtonsCapitalized, mainButtonsColor, mainButtonsRounded,
				minimalFooter, mode, primaryColor, showImages, showUpsellItemsAsSwitch, textColor, useSlotPrices, showAsCategories,
				allowSelectionOfMultipleItems
			} = values

			/* PRIMARY CONFIGS */
			commit('updateOrganizationUUID', organization)
			commit('updateChannelUUID', channel)
			commit('updateLocationUUID', location)
			commit('setMode', mode)
			commit('setCategoryFilter', categories)
			commit('updateItemUUIDsFilter', items)
			commit('setActivitiesCategoryUUID', activitiesCategory)

			/* SECONDARY CONFIGS*/
			commit('setDarkTheme', darkTheme)
			commit('setMainButtonsRounded', mainButtonsRounded)
			commit('setMainButtonsCapitalized', mainButtonsCapitalized)
			commit('setMinimalFooter', minimalFooter)
			commit('setHideItemPrice', hideItemPrice)
			commit('setShowScheduleAsCategories', showAsCategories)
			commit('setDefaultLanguage', defaultLanguage)
			commit('setHideExtraInfo', hideExtraInfo)
			commit('setUpsellItemsAsSwitch', showUpsellItemsAsSwitch)
			commit('setShowImages', showImages)
			commit('setUseSlotPrices', useSlotPrices)
			commit('setSelectMultipleItems', Boolean(allowSelectionOfMultipleItems) || getters.shouldShowMultipleSelect)
			/* COLORS */
			commit('setMainButtonsColor', mainButtonsColor)
			commit('setBackgroundColor', backgroundColor)
			commit('setTextColor', textColor)
			commit('setPrimaryColor', primaryColor)

			if(organization) {
				dispatch('getInitialData').then(() => {})
			}
		}
	},
	getters: {
		getTimezone: (state) => {
			if(state.location && state.location.timezone) {
				return state.location.timezone;
			}
			if(state.organization && state.organization.defaultTimezone) {
				return state.organization.defaultTimezone;
			}

			// Assumes UTC if no other set time zone
			return 'UTC';
		},
		getMinimumSelectableDateForCalendar: (state) => (selectedItems) => {
			console.log(state, selectedItems)
		},
		getMaximumSelectableDateForCalendar: (state) => (selectedItems) => {
			console.log(state, selectedItems)
		},
		getUnixTimestamp: () => (timestamp) => {
			return getUnixTimestamp(timestamp)
		},
		formatDateFromTimestampAndTimezone: () => (timestamp, timezone) => {
			Settings.defaultZone = 'UTC'
			const unixTimestamp = getUnixTimestamp(timestamp)
			let tz = 'UTC'
			if(typeof timezone !== 'undefined' && timezone !== null) {
				tz = timezone
			}

			return DateTime.fromMillis(unixTimestamp, { zone: tz })
		},
        offers: (state) => ({ eventQuantity, additionalItem }) => {
			if(state.organizationUUID !== '32036a02-fd37-4044-bbb1-e55970e4531f' && state.organizationUUID !== '2baf8f91-c585-4092-a716-15724a98b76f') {
				return null
			}
			if(typeof additionalItem === 'undefined' || additionalItem === null) {
				return null
			}
			const whitelistedAdditionalItems = [
				'2741e9e8-8fea-4a8a-8647-1358716d661f', // Mr. Green Test Tilboð
				'3d8554e6-475f-4f02-921c-6c0ac93ba556', // Minigarðurinn Fjölskyldutilboð með golfi
			]
            if(!whitelistedAdditionalItems.includes(additionalItem.uuid)) {
				return null
            }

			let minQuantity = 0
            let maxQuantity = 0
            if(eventQuantity / 4 >= 1) {
				maxQuantity = Math.floor(eventQuantity/4)
            }

			return {
				minQuantity,
				maxQuantity,
				text: i18n.t('Each offer is for 4 people.')
			}
        },
		logoSrc: (state) => {

			if(
				state.organization !== null &&
				state.organization !== undefined &&
				state.organization.logo !== null &&
				state.organization.logo !== undefined &&
				state.organization.logo.file !== null &&
				state.organization.logo.file !== undefined &&
				state.organization.logo.file.sources !== null &&
				state.organization.logo.file.sources !== undefined &&
				state.organization.logo.file.sources.original) {
					return state.organization.logo.file.sources.original
				}

			return null
		},
		isInIframe: () => {
			try {
				return window.self !== window.top;
			} catch (e) {
				return true;
			}
		},
		organizationShouldShowAgeRestrictions: (state) => {
			const organizationUUIDList = [
				'06a69a1f-8164-422e-b7d8-3753e04f6464' // Hvammsvík
			]

			if(state.organization) {
				return organizationUUIDList.includes(state.organization.uuid)
			}
			return false
		},
		translateByType: () => (typeString, object) => {

			if(typeString === null || typeString === undefined || object === null || object === undefined) {
				return ''
			}

			const arr = typeString.split('.')

			let string = ''
			arr.forEach(str => {
				string += str + ':'
			})

			if(i18n.te(string + object.uuid)) {
				return i18n.t(string + object.uuid)
			} else {
				if(arr.length === 1) {
					return object
				} else if(arr.length === 2) {
					return object[arr[1]]
				} else if(arr.length === 3) {
					return object[arr[1]][arr[2]]
				} else if(arr.length === 4) {
					return object[arr[1]][arr[2]][arr[3]]
				}
				return 'Check translation error.'
			}
		},
		monthShortString: () => (monthNumber) => {
			const strings = [
				'Jan.',
				'Feb.',
				'Mars',
				'Apr.',
				'May',
				'June',
				'July',
				'Aug.',
				'Sep.',
				'Oct.',
				'Nov.',
				'Dec.',
			]
			return strings[monthNumber]
		},
		inApp: () => {
			/*eslint-disable no-undef*/
			if(typeof SalesCloud !== "undefined" && SalesCloud !== null) {
				return true
			}

			return false
		},
		itemHasCustomPrice: () => (payload) => {
			if(payload.uuid === 'cb8755d2-e3b6-463e-9798-79883c7af94a' || payload.uuid === '2f0a3564-4620-45b6-a811-9fbe05fefa35') {
				const currentDay = new Date(payload.selectedTimeSlots[0].startsAtTime * 1000).getDay()
				if(currentDay === 0 || currentDay === 5 || currentDay === 6) {
					return 1250
				} else {
					return 1250
				}
			} else {
				return null
			}
		},
		colorIsCompleteGrey: () => (colour) => {
			if(colour === null || typeof colour === 'undefined') {
				return false
			}
			colour = colour.replace(/#(\S)/g, '')
			const charArr = colour.split('')
			let oddChar = charArr[0]
			let evenChar = charArr[1]
			for(let i = 2; i < charArr.length; i++) {
				if((i % 2 === 0 && charArr[i] === oddChar) || (i % 2 !== 0 && charArr[i] === evenChar)) {
					continue;
				}
				return false
			}
			return true
		},
		hexToLuma: () => (colour) => {
			if(!colour) { return 0 }
			if(colour.includes('rgb(') || colour.includes('rgba(')) {
				const rgb   = colour.replace(/rgb|a|\(|\)/gi, '').split(',')
				const r     = parseInt(rgb[0]);
				const g     = parseInt(rgb[1]);
				const b     = parseInt(rgb[2]);

				return [
					0.299 * r,
					0.587 * g,
					0.114 * b
				].reduce((a, b) => a + b) / 255;
			} else {
				const hex   = colour.replace(/#/, '')
				const r     = parseInt(hex.substr(0, 2), 16);
				const g     = parseInt(hex.substr(2, 2), 16);
				const b     = parseInt(hex.substr(4, 2), 16);

				return [
					0.299 * r,
					0.587 * g,
					0.114 * b
				].reduce((a, b) => a + b) / 255;
			}
		},
		colorIsLight: (state, getters) => (colour) => {
			return ((1 - getters.hexToLuma(colour)) < 0.4)
		},
		isInitialized: (state) => {
			return !!(state.organizationUUID && state.channelUUID && Array.isArray(state.items)) && state.widgetHasInitialized
		},
		primaryColor: (state) => {
			if(state.organization === null) {
				return '#2277dd'
			} else {
				return state.organization.primary_color
			}
		},
		accentColor: (state) => {
			if(state.organization === null) {
				return '#2277dd'
			} else {
				return state.organization.accent_color
			}
		},
		items: state => {
			let items = state.items
			if(Array.isArray(state.itemUUIDsFilter) && state.itemUUIDsFilter.length) {
				items = items.filter(item => item && state.itemUUIDsFilter.some(uuid => uuid === item.uuid))
			}
			if(Array.isArray(state.categoryFilter) && state.categoryFilter.length > 0) {
				items = items.filter(item => item && Array.isArray(item.categories) && item.categories.some(c => c && state.categoryFilter.includes(c.uuid)))
			}
			return items
		},
		canCancelBooking: (state) => {
			return state.canCancelBooking
		},
		canChangeBooking: (state) => {
			return state.canChangeBooking
		},
		itemCategories: (state, getters) => {
			const items = getters.items
			let categories = []

			for(let i = 0; i < items.length; i++) {
				let c = items[i].categories
				if(c && Array.isArray(c) && c.length > 0) {
					for(let j = 0; j < c.length; j++) {
						if (!c[j].administrative && !categories.find(x => x.uuid === c[j].uuid)) {
							if (state.categoryFilter.length > 0 && !state.categoryFilter.includes(c[j].uuid)) {
								continue
							}
							categories.push(c[j])
						}
					}
				}
			}

			return categories
		},
		showScheduleAsCategories: (state) => {
			return state.showScheduleAsCategories
		},
		categoryItems: (state, getters) => (category) => {
			const categoryUUID = category.uuid
			return getters.items.filter(item => item.categories && item.categories.some(category => category.uuid === categoryUUID)).sort((a,b) => a.sorting_weight - b.sorting_weight)
		},
		unitOfMeasureText: () => (unitOfMeasure) => {
			switch (unitOfMeasure) {
				case 'piece':
					return i18n.t('unitsOfMeasure.piece')
				case 'units':
					return i18n.t('unitsOfMeasure.unit')
				case 'milliliters':
					return i18n.t('unitsOfMeasure.milliliter')
				case 'liters':
					return i18n.t('unitsOfMeasure.liter')
				case 'grams':
					return i18n.t('unitsOfMeasure.gram')
				case 'kilograms':
					return i18n.t('unitsOfMeasure.kilograms')
				case 'hours':
					return i18n.t('unitsOfMeasure.hour')
				case 'minutes':
					return i18n.t('unitsOfMeasure.minute')
				case 'days':
					return i18n.t('unitsOfMeasure.day')
				case 'people':
					return i18n.t('unitsOfMeasure.person')
				case 'compartment':
					return i18n.t('unitsOfMeasure.compartment')
				case 'stall':
					return i18n.t('unitsOfMeasure.stall')
				case 'pieces':
					return i18n.t('unitsOfMeasure.piece')
				case 'table':
					return i18n.t('unitsOfMeasure.table')
				case 'board':
					return i18n.t('unitsOfMeasure.board')
				case 'room':
					return i18n.t('unitsOfMeasure.room')
				case 'h':
					return unitOfMeasure
				default:
					return unitOfMeasure
			}
		},
		getItemCurrencyDisplay: () => (priceObject) => {
			if(!priceObject) {
				return ''
			}
			let amount = priceObject.amount
			const currencyCode = priceObject.currency_code || 'ISK'

			if(typeof amount === 'undefined' || amount === null) {
				amount = 0
			}

			let decimalDigits = 0

			const foundCurrency = currencyFormatter.findCurrency(currencyCode)

			if(foundCurrency && foundCurrency.decimalDigits > 0) {
				decimalDigits = foundCurrency.decimalDigits
			}

			amount = amount / Math.pow(10, decimalDigits)
			return currencyFormatter.format(amount, { code: currencyCode })
		},
		organizationUUID: (state) => {
			return state.organizationUUID
		},
		locationUUID: (state) => {
			return state.locationUUID
		},
		channelUUID: (state) => {
			return state.channelUUID
		},
		organization: (state) => {
			return state.organization
		},
		location: (state) => {
			return state.location
		},
		channel: (state) => {
			return state.channel
		},
		categoryFilter: (state) => {
			return state.categoryFilter
		},
		selectMultipleItems: (state) => {
			return state.selectMultipleItems
		},
		graphUrl: (state) => {
			return state.graphUrl
		},
		defaultLanguage: (state) => {
			return state.defaultLanguage
		},
		hideExtraInfo: (state) => {
			return state.hideExtraInfo
		},
		upsellItemsAsSwitch: (state) => {
			return state.upsellItemsAsSwitch
		},
		metaPixelCode: (state) => {
			return state.metaPixelCode
		},
		getItemCategories: () => (items) => {
			if(!Array.isArray(items)) {
				return []
			}
			let categories = []

			for(let itemIndex = 0; itemIndex < items.length; itemIndex++) {
				const item = items[itemIndex]
				if(typeof item === 'undefined' || item === null) {
					continue
				}
				if(!Array.isArray(item.categories) || item.categories.length <= 0) {
					continue
				}

				for(let categoryIndex = 0; categoryIndex < item.categories.length; categoryIndex++) {
					const category = item.categories[categoryIndex]

					if(typeof category === 'undefined' || category === null) {
						continue
					}
					if(categories.some(c => c && c.uuid === category.uuid)) {
						continue
					}

					categories.push(category)
				}
			}

			try {
				categories = categories.sort((a, b) => a.weight - b.weight)
			} catch (e) {
				//
			}

			return categories
		},
		getItemsInCategory: () => (category, items) => {
			if(typeof category === 'undefined' || category === null) {
				return []
			}
			if(!Array.isArray(items)) {
				return []
			}
			return items.filter(item => item && Array.isArray(item.categories) && item.categories.some(c => c && c.uuid === category.uuid))
		},
		getItemCurrentPrice: () => (item, quantity = 1, variations) => {
			if(typeof item === 'undefined' || item === null) {
				return null
			}

			let amount = 0

			let currency_code = 'ISK'
			let variationsAmount = 0
			if(typeof item.price !== 'undefined' && item.price !== null) {
				if(typeof item.price.currency_code !== 'undefined' && item.price.currency_code !== null) {
					currency_code = item.price.currency_code
				}

				if(!isNaN(Number(item.price.amount))) {
					amount = Number(item.price.amount)

					if(typeof item.sale_price !== 'undefined' || item.sale_price !== null) {
						if(!isNaN(Number(item.sale_price.amount)) && Number(item.sale_price.amount) > 0) {
							if(Number(item.sale_price.amount) < amount) {
								amount = Number(item.sale_price.amount)
							}

						}
					}
				}
			}

			if(Array.isArray(variations)) {
				variationsAmount = variations.reduce((total, variation) => {
					if(typeof variation === 'undefined' || variation === null) {
						return total
					}
					if(typeof variation.price === 'undefined' || variation.price === null) {
						return total
					}
					if(isNaN(Number(variation.price.amount))) {
						return total
					}

					return total + Number(variation.price.amount)
				}, 0)
			}

			amount = amount + variationsAmount
			amount = amount * quantity

			return {
				amount,
				currency_code
			}
		},
		getVariationAttributes: () => (variations) => {
			if(!Array.isArray(variations)) {
				return []
			}

			const attributes = []

			for(let variationIndex = 0; variationIndex < variations.length; variationIndex++) {
				const variation = variations[variationIndex]

				if(typeof variation === 'undefined' || variation === null) {
					continue
				}

				if(typeof variation.attribute === 'undefined' || variation.attribute === null) {
					continue
				}

				if(attributes.some(attribute => attribute && attribute.uuid === variation.attribute.uuid)) {
					continue
				}

				attributes.push(variation.attribute)
			}

			return attributes
		},
		getVariationsInAttribute: () => (attribute, variations) => {
			if(typeof attribute === 'undefined' || attribute === null) {
				return []
			}
			if(!Array.isArray(variations)) {
				return []
			}

			return variations.filter(variation => variation && variation.attribute && variation.attribute.uuid === attribute.uuid)
		},
		getItemTitle: () => (item) => {
			if(typeof item === 'undefined' || item === null) {
				return ''
			}
			const key = 'item:title:' + item.uuid
			if(!i18n.te(key)) {
				return item.title
			}
			return i18n.t(key)
		},
		getAttributeName: () => (attribute) => {
			if(typeof attribute === 'undefined' || attribute === null) {
				return ''
			}
			const key = 'attribute:name:' + attribute.uuid
			if(!i18n.te(key)) {
				return attribute.name
			}
			return i18n.t(key)
		}
	},
	modules: {
		hardCode,
		order
	}
})
