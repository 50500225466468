<template>
  <v-container class="pa-0 px-md-4" :class="dynamicClasses">
    <LocaleChanger style="position: absolute; right: 12px; top: 12px; z-index: 5;"></LocaleChanger>
    <ItemsList v-if="isInitialized && Array.isArray(items) && items.length > 0 && !showAsCategories"/>
    <CategoriesList v-else-if="isInitialized && Array.isArray(items) && items.length > 0 && showAsCategories"></CategoriesList>
    <v-row v-else-if="isInitialized && Array.isArray(items) && items.length <= 0" class="px-6 py-8 my-0">
      <v-col cols="12" md="4" />
      <v-col cols="12" md="4">
        <v-alert color="error" rounded dark class="mb-0 text-center">
          <div class="pb-2">
            <v-icon large right>mdi-emoticon-sad-outline</v-icon>
          </div>
          {{ $t('noActiveEvents') }}
        </v-alert>
      </v-col>
      <v-col cols="12" md="4" />
    </v-row>
    <Loading v-else />
  </v-container>
</template>

<script>
// @ is an alias to /src
import ItemsList from '@/components/ItemsList'
import Loading from '@/components/Loading'
import CategoriesList from "@/components/CategoriesList";
import LocaleChanger from "@/components/LocaleChanger";
import { version } from '../../package.json'

export default {
  name: 'Home',
  components: {
    LocaleChanger,
    CategoriesList,
    ItemsList,
    Loading
  },
  computed: {
	logoSrc() {
		return this.$store.getters.logoSrc
	},
	dynamicClasses() {
		const classes = []

		if(!this.isInIframe) {
			classes.push('fill-height')
		}

		return classes
	},
	isInIframe() {
		return this.$store.getters.isInIframe
	},
    version() {
      return version
    },
    isInitialized() {
      return this.$store.getters.isInitialized
    },
    showAsCategories() {
      return this.$store.getters.showScheduleAsCategories
    },
    items() {
      return this.$store.state.items
    }
  },
}
</script>
