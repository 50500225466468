<template>
  <v-card>
    <v-container>
      <v-row>
        <v-col cols="12">
          <span style="font-weight: 300;">
            {{ $t('Add-ons') }}
          </span>
        </v-col>
        <template v-for="item in items">
          <v-col
              cols="12"
              class="pa-0"
              :key="item.uuid"
          >
            <ParticipantItem
                :item="item"
                :selected-items="selectedItems"
                v-on="$listeners"
                :disabled="disabled"
            />
          </v-col>
        </template>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import ParticipantItem from "./ParticipantItem"

export default {
  name: "ParticipantAdditionalItems",
  components: {
    ParticipantItem
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    selectedItems: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>
