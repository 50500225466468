/* eslint-disable no-unreachable */
<template>
	<div>
		<h3 v-if="!organizationIsBullseye">{{ $t("whenDoYouWantToCome") }}</h3>
		<div v-if="organizationIsBullseye" class="title">
			{{ $t("whichDayDoYouWantToCome") }}
		</div>
		<vc-calendar
			class="mt-3"
			title-position="left"
			:attributes="attributes"
			:min-date="minDate"
			:max-date="maxDate"
			:disabled-dates="disabledDates"
			is-expanded
			timezone="UTC"
			:value="selectedDate"
			@dayclick="dayClick"
		/>
		<div
			class="caption mt-5"
			v-if="maxAheadBookingInDays > 0 && !organizationIsBullseye"
		>
			{{
				$t("maxAheadDays", {
					maxAheadBookingInDays: maxAheadBookingInDays
				})
			}}
		</div>
		<div
			class="caption mt-5"
			v-else-if="maxAheadBookingInDays > 0 && organizationIsBullseye"
		>
			{{
				$t("maxAheadDaysBullseye", {
					maxAheadBookingInDays: maxAheadBookingInDays
				})
			}}
		</div>
		<div class="caption mt-5" v-else>
			{{ $t("maxBookableDate", { maxDateFormatted: maxDateFormatted }) }}
		</div>
		<div
			class="caption mt-3"
			v-if="cartQuantity && !hideExtraInfo && !organizationIsBullseye"
		>
			{{ cartQuantity }}
			{{ $tc(unitOfMeasure, this.maximumGroupedQuantity) }} -
			{{ selectedQuantity }} {{ $t("slotsSelected") }}
		</div>
		<div
			class="caption mt-3"
			v-if="cartQuantity && !hideExtraInfo && organizationIsBullseye"
		>
			{{ $t("possibleToChangeBookingWith24HoursNotice") }}
		</div>
		<v-divider class="mt-5 mb-5" />
		<v-alert type="error" v-if="false" v-show="errorAlert">{{
			errorAlert
		}}</v-alert>
		<div
			v-show="
				typeof selectedDate !== 'undefined' && selectedDate !== null
			"
		>
			<h3 v-if="!organizationIsBullseye">
				{{ $t("selectAvailableSlot") }}
			</h3>
			<div v-if="organizationIsBullseye" class="title">
				{{ $t("whenDoYouWantToStart") }}
			</div>
			<div class="subTitle-1" v-if="showTicketsPerSlot && !hideExtraInfo">
				{{ $t("eachSlotIsForUpTo") }} {{ maximumGroupedQuantity }}
				{{ $tc(unitOfMeasure, this.maximumGroupedQuantity) }}
			</div>
			<div v-if="slotGroups !== null && slotGroups.length > 0">
				<template v-for="(group, index) in slotGroups">
					<v-subheader
						v-if="
							slots[group].length > 0 && !organizationIsBullseye
						"
						:key="index + '-div'"
						class="text-uppercase px-0"
					>
						{{ $t("kl") }} {{ group }}
					</v-subheader>

					<v-row :key="'row-' + index" dense>
						<template v-for="(slot, index2) in slots[group]">
							<v-col
								:key="'slot-' + index2"
								cols="auto"
								class="pa-0"
							>
								<v-btn
									disabled
									v-if="slot.availableCount < 1"
									class="mr-4 mb-4"
									>{{ slot.text }} ({{
										$t("soldOut")
									}})</v-btn
								>
								<v-btn
									:loading="slot.processing"
									:disabled="slot.processing"
									v-else-if="slot.reservedForCurrentSession"
									class="mr-4 mb-4"
									color="green"
									:key="index2 + slot.text"
									@click="deselectSlot(slot)"
									>{{ slot.text }}</v-btn
								>
								<v-btn
									disabled
									:key="
										slot.text +
											'-' +
											index2 +
											'-' +
											'disabled'
									"
									v-else-if="slotIsDisabled(slot)"
									class="mr-4 mb-4"
									>{{ slot.text }} ({{ slot.availableCount }}
									{{ $t("remaining") }})</v-btn
								>
								<v-btn
									:loading="slot.processing"
									:disabled="slot.processing"
									class="mr-4 mb-4"
									:key="index2 + slot.text"
									v-else
									@click="selectSlot(slot)"
									>{{ slot.text }}</v-btn
								>
							</v-col>
						</template>
					</v-row>

					<div :key="group + '-err'" class="error--text">
						{{ errorMessage[group] }}
					</div>
				</template>
			</div>
			<div
				class="mt-2 mb-2"
				v-if="slotGroups !== null && slotGroups.length === 0"
			>
				<v-alert icon="mdi-lightbulb" prominent text type="info">{{
					$t("errors.selectedDateFullyBooked")
				}}</v-alert>
			</div>
			<div
				class="my-3"
				v-if="
					slotGroups === null &&
						selectedDate &&
						!gettingAvailableTimeSlots
				"
			>
				<v-alert icon="mdi-lightbulb" prominent text type="info">
					{{ $t("errors.selectedDateBookingClosed") }}
				</v-alert>
			</div>
			<div
				class="mt-3 mb-3"
				v-if="
					(slotGroups === null && !selectedDate) ||
						gettingAvailableTimeSlots
				"
			>
				<v-progress-linear
					indeterminate
					color="primary"
				></v-progress-linear>
			</div>
		</div>
		<div v-show="!selectedDate">
			<h4
				class="pb-3 text-center"
			>
				{{ $t("selectDateToSeeAvailability") }}
			</h4>
		</div>
	</div>
</template>

<script>
import moment from 'moment-timezone';

/**
 * @property timestampInMillis
 * @property startDate
 * @property endDate
 * @property unavailableDates
 * @property isBlocked
 * @property paddingInMillis
 */
export default {
	name: "Schedule",
	props: [
		"cartQuantity",
		"item",
		"requiredSlots",
		"maximumGroupedQuantity",
		"inCategory",
		"preselectedDate"
	],
	data() {
		return {
			deselecting: false,
			selecting: false,
			selectedDate: null,
			availableTimeSlots: null,
			errorMessage: [],
			errorAlert: null,
			selectedTimeSlots: []
		};
	},
	watch: {
		cartQuantity() {
      this.selectedTimeSlots = [];
      if(typeof this.selectedDate !== 'undefined' && this.selectedDate !== null) {
        this.getAvailableTimeSlots();
      }
		},
		selectedDate() {
			this.getAvailableTimeSlots();
		},
		slots() {
			if (
				this.slots &&
				Object.keys(this.slots).length === 0 &&
				this.slots.constructor === Object
			) {
				this.$emit("hasSlots", false);
			}
		}
	},
	computed: {
		timezone() {
			return this.$store.getters.getTimezone;
		},
		hideExtraInfo() {
			return this.$store.getters.hideExtraInfo;
		},
		showTicketsPerSlot() {
			return (
				this.cartQuantity >
				Math.floor(this.maximumGroupedQuantity * 0.9)
			);
		},
		organizationUUID() {
			return this.$store.state.organizationUUID;
		},
		unitOfMeasure() {
			let unitOfMeasure = "people";

			if (
				this.item.unitOfMeasure !== null &&
				this.item.unitOfMeasure !== undefined
			) {
				return this.item.unitOfMeasure;
			}

			return unitOfMeasure;
		},
		leftToSelect() {
			return this.cartQuantity - this.selectedQuantity;
		},
		selectedQuantity() {
			if (this.selectedTimeSlots && this.selectedTimeSlots.length) {
				return this.selectedTimeSlots.reduce((sum, slot) => {
					return sum + slot.quantity;
				}, 0);
			} else {
				return 0;
			}
		},
		disabledDates() {
			const disabledDates = [];

			if (
				this.item.unavailableDates !== null &&
				this.item.unavailableDates !== undefined &&
				this.item.unavailableDates.length > 0
			) {
				for (let i in this.item.unavailableDates) {
					const unavailableDate = this.item.unavailableDates[i];

					if (
						unavailableDate === null ||
						unavailableDate === undefined
					) {
						continue;
					}

					try {
						const splitDate = unavailableDate.split(".");
						const date = new Date();
						if (splitDate.length !== 3) {
							continue;
						}
						date.setUTCDate(splitDate[0]);
						date.setUTCMonth(splitDate[1] - 1);
						date.setUTCFullYear(splitDate[2].padStart(4, "20"));
						date.setUTCHours(0);
						date.setUTCMinutes(0);
						date.setUTCSeconds(0);
						date.setUTCMilliseconds(0);
						disabledDates.push(date);
					} catch (error) {
						// Do nothing
					}
				}
			}

			return disabledDates;
		},
		maxDateFormatted() {
			return this.maxDate.toISOString().substr(0, 10);
		},
		gettingAvailableTimeSlots() {
			return this.$store.state.gettingAvailableTimeSlots;
		},
		itemUUID() {
			return this.item.uuid;
		},
		attributes() {
			return [
				// {
				// 	key: 'today',
				// 	highlight: 'gray',
				// 	dates: new Date(this.systemTimeInMillis * 1)
				// },
				{
					key: "selected",
					highlight: "blue",
					dates: this.selectedDate
				}
			];
		},
		locationOpeningHours() {
			if (Array.isArray(this.$store.state.location.opening_hours)) {
				return this.$store.state.location.opening_hours;
			}
			return [];
		},
		dates() {
			if (
				this.item.dates !== null &&
				this.item.dates !== undefined &&
				this.item.dates.length > 0
			) {
				const dates = [];

				for (let i in this.item.dates) {
					dates.push({
						start: new Date(this.item.dates[i] * 1000),
						end: new Date(this.item.dates[i] + 86400 * 1000)
					});
				}

				return dates;
			}

			return [{ start: this.minDate, end: this.maxDate }];
		},
		minDate() {
			let minTimeInMillis = this.systemTimeInMillis;

			if (
				this.item.startDate !== null &&
				this.item.startDate !== undefined &&
				this.item.startDate > 0 &&
				this.systemTimeInMillis < this.item.startDate
			) {
				minTimeInMillis = this.item.startDate;
			}

			if (
				this.item.dates !== null &&
				this.item.dates !== undefined &&
				this.item.dates.length > 0
			) {
				for (let i in this.item.dates) {
					const dateInMillis = this.item.dates[i] * 1000;
					if (minTimeInMillis === null) {
						minTimeInMillis = dateInMillis;
					} else if (dateInMillis < minTimeInMillis) {
						minTimeInMillis = dateInMillis;
					}
				}

				return new Date(minTimeInMillis * 1);
			}

			return new Date(minTimeInMillis * 1);
		},
		reservationMode() {
			if (this.item.reservationMode !== null) {
				return this.item.reservationMode;
			}

			return "strict";
		},
		maxAheadBookingInDays() {
			let maxAheadBookingInDays = 0;

			if (
				this.item.maxAheadBookingInDays !== null &&
				this.item.maxAheadBookingInDays !== undefined
			) {
				maxAheadBookingInDays = this.item.maxAheadBookingInDays;
			}

			return maxAheadBookingInDays;
		},
		maxDate() {
			let maxDate = null;

			if (
				this.item.dates !== null &&
				this.item.dates !== undefined &&
				this.item.dates.length > 0
			) {
				for (let i in this.item.dates) {
					const dateInMillis = this.item.dates[i] * 1000;
					if (maxDate === null) {
						maxDate = dateInMillis;
					} else if (dateInMillis > maxDate) {
						maxDate = dateInMillis;
					}
				}

				return new Date(maxDate);
			}

			if (this.maxAheadBookingInDays > 0) {
				const days = this.maxAheadBookingInDays;
				const maxTime = this.systemTimeInMillis + 86400000 * days;
				maxDate = new Date(maxTime);
			} else if (
				this.item.endDate !== null &&
				this.item.endDate !== undefined
			) {
				maxDate = new Date(this.item.endDate);
			} else {
				maxDate = new Date();
			}

			return maxDate;
		},
		systemTimeInMillis() {
			return this.$store.state.systemTimeInMillis;
		},
		slotGroups() {
			if (this.slots === null) {
				return null;
			}

			return Object.keys(this.slots);
		},
		reservedSlots() {
			if (
				this.availableTimeSlots === null ||
				this.availableTimeSlots === undefined
			) {
				return [];
			}

			return this.availableTimeSlots.filter(timeSlot => {
				return timeSlot.reservedForCurrentSession;
			});
		},
		slots() {
			if (this.availableTimeSlots === null) {
				return null;
			}

			const slots = {};

			for (let i in this.availableTimeSlots) {
				const timeSlot = this.availableTimeSlots[i];

				if (!this.showSlot(timeSlot)) {
					continue;
				}

				if (timeSlot.isBlocked) {
					continue;
				}

				if (
					timeSlot.reservationCount > 0 &&
					this.reservationMode === "strict"
				) {
					continue;
				}

				const date = new Date(timeSlot.timestampInMillis);

				let slotGroupKey = date.getUTCHours();

				if (date.getDate() !== this.selectedDate) {
					slotGroupKey =
						slotGroupKey.toString().padStart(2, "0") +
						" - " +
						date.getDate() +
						". " +
						this.$store.getters.monthShortString(date.getMonth());
				}

				if (slots[slotGroupKey] === undefined) {
					slots[slotGroupKey] = [];
				}

				slots[slotGroupKey].push({
					key: date.getTime(),
					startTimeInMillis: timeSlot.timestampInMillis,
					endTimeInMillis:
						timeSlot.timestampInMillis + timeSlot.paddingInMillis,
					text: timeSlot.label,
					reservedForCurrentSession:
						timeSlot.reservedForCurrentSession,
					processing: timeSlot.processing,
					availableCount: timeSlot.availableCount,
					reservationCount: timeSlot.reservationCount
				});
			}
			return slots;
		},
		// Checking if user selected date is in fact disabled or not since selected date will update whether the date in the calendar is disabled or not
		selectedDateIsDisabled() {
			const selectedDate = new Date(this.selectedDate).setUTCHours(
				0,
				0,
				0,
				0
			);
			let isDisabled = false;

			this.disabledDates.forEach(d => {
				try {
					if (d.getTime() === selectedDate) {
						isDisabled = true;
					}
				} catch (e) {
					// Do nothing
				}
			});

			return isDisabled;
		},
		organizationIsBullseye() {
			return (
				this.organizationUUID === "bb78d411-9b7c-4a51-a134-e359cc1bc7f8"
			);
		},
		locationUUID() {
			return this.$store.state.locationUUID;
		}
	},
	methods: {
		showSlot(slot) {
			if (
				this.organizationUUID ===
					"32036a02-fd37-4044-bbb1-e55970e4531f" &&
				this.locationUUID !== "5aab8729-e20e-4b42-9df1-6f19dcae970b" &&
				slot.availableCount < this.item.maximumGroupedQuantity
			) {
				return false;
			}
			return true;
		},
		slotIsDisabled(slot) {
			return (
				slot.availableCount > 0 &&
				slot.availableCount < this.maximumGroupedQuantity &&
				slot.availableCount < this.leftToSelect
			);
		},
		availableWithCartQuantity(slot) {
			return slot.availableCount - this.cartQuantity;
		},
		getAvailableTimeSlots() {
			this.availableTimeSlots = null;

			const newDate = this.selectedDate;

			if (
				newDate > this.maxDate.setUTCHours(23, 59, 59, 999) ||
				newDate < this.minDate.setUTCHours(0, 0, 0, 0)
			) {
				// Do nothing?
				//return;
			}

			const weekDay = moment(newDate).tz(this.timezone).day().toString();
			const startOfDay = moment(newDate).tz(this.timezone).startOf("day");
			const endOfDay = moment(newDate).tz(this.timezone).endOf("day");

			const startTimeInMillis = startOfDay.valueOf();
			let endTimeInMillis = endOfDay.valueOf();

			const openingHoursToday = this.locationOpeningHours.find(
				o => typeof o !== "undefined" && o !== null && o.day === weekDay
			);

			if (
				typeof openingHoursToday !== "undefined" &&
				openingHoursToday !== null &&
				typeof openingHoursToday.endhours === "string" &&
				typeof openingHoursToday.starthours === "string"
			) {
				const startHours = openingHoursToday.starthours;
				let endHours = openingHoursToday.endhours;

				if (endHours === "2400") {
					endHours = "0000";
				}

				if (endHours.length === 3) {
					endHours = "0" + endHours;
				}

				endHours = endHours.padStart(4, "0");

				if (endHours < startHours) {
					// This means that the end hours is past midnight
					let hours;
					let minutes;
					if (endHours.includes(":")) { // Unsure where the hours are actually saved with ":"
						hours = endHours.split(":")[0];
						minutes = endHours.split(":")[1];
					} else if (endHours.length === 4) {
						hours = endHours.substring(0, 2);
						minutes = endHours.substring(2, 4);
					}
					if (
						typeof hours !== "undefined" &&
						typeof minutes !== "undefined"
					) {
						endTimeInMillis = this.$moment(newDate)
							.hours(hours)
							.minutes(minutes)
							.seconds(0)
							.milliseconds(0)
							.add(1, "day")
							.valueOf();
					}
				}
			}

			this.$store
				.dispatch("getAvailableTimeSlots", {
					itemUUID: this.itemUUID,
					startTimeInMillis: startTimeInMillis,
					endTimeInMillis: endTimeInMillis,
					inCategory: this.inCategory
				})
				.then(response => {
					let availableTimeSlots =
						response.data.data.availableTimeSlots;

					if (
						availableTimeSlots &&
						Array.isArray(availableTimeSlots)
					) {
						availableTimeSlots = availableTimeSlots.filter(
							slot => !slot.isBlocked
						);
						if (availableTimeSlots.length > 0) {
							this.$emit("hasSlots", true);
						} else {
							this.$emit("hasSlots", false);
						}
					}

					for (let i in availableTimeSlots) {
						availableTimeSlots[i].processing = false;
					}

					this.availableTimeSlots = availableTimeSlots;
				});
		},
		/**
		 * Takes a date object and retrieves the day, month and year and returns a
		 * string representation of it in the following format: "dd-mm-yyyy"
		 * @param {*} date 
		 */
		formatDate(date) {
			if(!date) {
				return '';
			}
			const reversedDateValues = date.toISOString().substring(0, 10).split('-').reverse();
			return reversedDateValues.join('-');
		},
		isDateInDisabledDates(date, disabledDatesArray) {
			const formattedDate = this.formatDate(date);
			return disabledDatesArray.some(d => this.formatDate(d) === formattedDate)
		},
		dayClick(e) {
			if (typeof e === 'undefined' || e === null) {
				return
			}

			if (typeof e.date === 'undefined' || e.date === null) {
				return
			}

			let qty = parseInt(this.requiredSlots);

			if (this.reservedSlots.length >= qty && qty > 0) {
				this.errorAlert = this.$t("errors.tooManySelected");
				alert(this.errorAlert);
				return;
			}

			const parsedDate = e.id.split('-');
			const dateUTCTimestamp = new Date().setUTCFullYear(Number(parsedDate[0]), Number(parsedDate[1]) - 1, Number(parsedDate[2]));

			const minDateMillis = this.minDate ? this.minDate.setUTCHours(0,0,0,0) : 0;
			const maxDateMillis = this.maxDate ? this.maxDate.setUTCHours(23, 59, 59, 999) : 0;

			if (dateUTCTimestamp < minDateMillis) {
				//console.log("Selected date is before starting/min date");
				return
			}

			if (dateUTCTimestamp > maxDateMillis) {
				//console.log("Selected date is after ending/max date");
				return
			}

			let formattedDate = new Date(dateUTCTimestamp)
			formattedDate.setHours(0, 0, 0, 0)

			if(this.isDateInDisabledDates(formattedDate, this.disabledDates)) {
				//console.log("Selected Date is disabled!")
				return
			}

			this.selectedDate = dateUTCTimestamp

			this.$emit("dayClick", e);
		},
		deselectSlot(slot) {
			this.availableTimeSlots.find(timeSlot => {
				if (timeSlot.timestampInMillis == slot.startTimeInMillis) {
					timeSlot.processing = true;
				}
			});

			this.$store
				.dispatch("releaseAvailableTimeSlot", {
					skipLock: this.reservationMode === "relaxed",
					itemUUID: this.itemUUID,
					startTimeInMillis: slot.startTimeInMillis,
					endTimeInMillis: slot.endTimeInMillis
				})
				.then(result => {
					if (result.data.data.releaseAvailableTimeSlot) {
						this.availableTimeSlots.find(timeSlot => {
							if (
								timeSlot.timestampInMillis ==
								slot.startTimeInMillis
							) {
								timeSlot.reservedForCurrentSession = false;
								timeSlot.processing = false;
								const index = this.selectedTimeSlots.findIndex(
									s => s.key === slot.key
								);
								this.selectedTimeSlots.splice(index, 1);
								this.$emit(
									"timeSlotsSelected",
									this.selectedTimeSlots
								);
							}
						});
					} else {
						this.errorMessage[slot.text.slice(0, 2)] = this.$t(
							"slot.deselectFailure"
						);
					}
				})
				.finally(() => {
					this.availableTimeSlots.find(timeSlot => {
						if (
							timeSlot.timestampInMillis == slot.startTimeInMillis
						) {
							timeSlot.processing = false;
						}
					});
				});
		},
		selectSlot(slot) {
			let qty = parseInt(this.requiredSlots);

			if (this.reservedSlots.length >= qty) {
				this.errorAlert = this.$t("errors.selectedSlots");
				alert(this.errorAlert);
				return;
			}

			this.availableTimeSlots.find(timeSlot => {
				if (timeSlot.timestampInMillis == slot.startTimeInMillis) {
					timeSlot.processing = true;
				}
			});

			let quantity =
				this.leftToSelect > slot.availableCount
					? slot.availableCount
					: this.leftToSelect;

			this.$store
				.dispatch("reserveAvailableTimeSlot", {
					skipLock: this.reservationMode === "relaxed",
					itemUUID: this.itemUUID,
					startTimeInMillis: slot.startTimeInMillis,
					endTimeInMillis: slot.endTimeInMillis,
					quantity: this.cartQuantity
				})
				.then(result => {
					if (result.data.data.reserveAvailableTimeSlot.success) {
						this.errorAlert = null;
						this.errorMessage[slot.text.slice(0, 2)] = null;
						slot.reservedForCurrentSession = true;
						this.availableTimeSlots.find(timeSlot => {
							if (
								timeSlot.timestampInMillis ==
								slot.startTimeInMillis
							) {
								timeSlot.reservedForCurrentSession = true;
								timeSlot.processing = false;
								slot.quantity = quantity;
								this.selectedTimeSlots.push(slot);
								this.$emit(
									"timeSlotsSelected",
									this.selectedTimeSlots
								);
							}
						});
					} else {
						this.errorAlert = this.$t(
							"errors.slotNoLongerAvailable"
						);
						alert(this.errorAlert);
					}
				})
				.finally(() => {
					this.availableTimeSlots.find(timeSlot => {
						if (
							timeSlot.timestampInMillis == slot.startTimeInMillis
						) {
							timeSlot.processing = false;
						}
					});
				});
		},
		isSelected(slot) {
			return this.availableTimeSlots.find(timeSlot => {
				if (
					timeSlot.timestampInMillis === slot.startTimeInMillis &&
					timeSlot.reservedForCurrentSession
				) {
					return true;
				}
			});
		}
	},
	updated() {
		if (this.slotGroups && this.slotGroups.length) {
			this.slotGroups.forEach(value => {
				if (this.errorMessage[value] === undefined) {
					this.errorMessage[value] = null;
				}
			});
		}
	}
};
</script>
